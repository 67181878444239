import axios, { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { MasterEndpointsEnum } from "../constants/master.endpoints";

// API-CLF-020
const getClassificationsApi = (): Promise<AxiosResponse> => {
  const url = MasterEndpointsEnum.GET_CLASSIFICATIONS;
  return api.get(url);
};

const getPresignedUrlApi = (): Promise<AxiosResponse> => {
  const url = MasterEndpointsEnum.GET_PRESIGNED_URL;
  return api.get(url);
};

const uploadImageToS3Api = ({
  url,
  imageUpload,
}: {
  url: string;
  imageUpload: File;
}) => {
  return axios.put(url, imageUpload, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "image/png",
    },
  });
};

const masterApi = {
  getClassificationsApi,
  getPresignedUrlApi,
  uploadImageToS3Api,
};

export default masterApi;
