import { memo, useEffect, useMemo, useState } from "react";

import { Button } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import { compile } from "path-to-regexp";
import { useHistory, useParams } from "react-router-dom";
import Scrollspy from "react-scrollspy";

import HintTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import { MessageError } from "@app/constants/messages.constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  getReportDetail,
  postEvaluations,
  ReportsDataDef,
  ReportsPathsEnum,
} from "../../reports";
import PreviewReportsPage1 from "./PreviewReportsPage1/PreviewReportsPage1";
import PreviewReportsPage2 from "./PreviewReportsPage2/PreviewReportsPage2";
import PreviewReportsPage3 from "./PreviewReportsPage3/PreviewReportsPage3";
import PreviewReportsPage4 from "./PreviewReportsPage4/PreviewReportsPage4";
import PreviewReportsPage5 from "./PreviewReportsPage5/PreviewReportsPage5";
import PreviewReportsPage6 from "./PreviewReportsPage6/PreviewReportsPage6";
import styles from "./PreviewReportsScreen.module.scss";

const PreviewReportsScreen = () => {
  const { id } = useParams<{ mode: string; id: string }>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const { reportDetail } = useAppSelector(
    (rootState: RootState) => rootState.reports
  );

  const dataReport = useMemo(() => {
    const dataInLocal: ReportsDataDef | null = localStorage.getItem(
      "dataReport"
    )
      ? JSON.parse(localStorage.getItem("dataReport") ?? "")
      : null;
    return dataInLocal ?? reportDetail;
  }, [reportDetail]);

  useEffect(() => {
    if (!localStorage.getItem("dataReport")) {
      dispatch(getReportDetail(id)).catch(() => {
        openNotification({ type: "warning", message: MessageError.ERRMSG_002 });
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (dataReport) {
      dispatch(
        postEvaluations({
          data: {
            design_plot_locations:
              dataReport?.workplace?.measurement_design
                ?.design_plot_locations ?? [],
            workplace_id: dataReport?.workplace?.id ?? -1,
          },
        })
      );
    }
  }, [dataReport, dispatch]);

  const printDocument = async () => {
    setIsLoading(true);
    const content = document.getElementById("preview-pdf");
    if (!content) return;
    const opt = {
      filename: `${dataReport?.office?.name ?? ""}_${
        dataReport?.workplace?.name ?? ""
      }_${dayjs().tz().locale(ja).format("YYYYMMDD")}.pdf`,
      image: { type: "jpeg" },
      html2canvas: { scale: 4, letterRendering: true, useCORS: true },
      jsPDF: { unit: "px", format: [595, 870] },
      pagebreak: {
        after: ["#page-1", "#page-2", "#page-3", "#page-4", "#page-5"],
        avoid: "img",
      },
    };
    await html2pdf().set(opt).from(content).save();
    setIsLoading(false);
  };

  return (
    <div className={cx(styles.root)}>
      {!dataReport && <LoadingSpinner />}
      <div
        className={cx(
          styles.appbar,
          "flex-align-center flex-space-between px-6"
        )}
      >
        <div className="flex-align-center text-white">
          <HintTooltip
            className="text-white heading-l mr-1 font-16"
            hintText={HintText.HINT_001}
            content="報告書プレビュー"
            colorIcon="text-white"
            placement="bottom"
          />
          <div className="body-s ml-3">
            最終更新：
            {dayjs(reportDetail?.updated_at)
              .tz()
              .format("YYYY/MM/DD HH:mm")}{" "}
            {dataReport?.updated_by}
          </div>
        </div>
        {!localStorage.getItem("dataReport") && (
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                history.push(
                  compile(ReportsPathsEnum.REPORTS_DETAILS)({
                    mode: "edit",
                    id,
                  })
                );
              }}
            >
              <div className="flex-align-center ">
                <span className="icon-edit font-20" />
                <div>編集</div>
              </div>
            </Button>
            <Button disabled={isLoading} onClick={printDocument}>
              <div className="flex-align-center">
                <span className="icon-download font-20" />
                <div>ダウンロード</div>
              </div>
            </Button>
          </div>
        )}
      </div>
      <div className={cx(styles.totalPage, "flex")}>
        <Scrollspy
          items={["page-1", "page-2", "page-3", "page-4", "page-5", "page-6"]}
          currentClassName="active"
          componentTag="span"
          offset={-200}
        >
          {[1, 2, 3, 4, 5, 6].map(num => (
            <span style={{ display: "none" }}>{num}</span>
          ))}
        </Scrollspy>
        <span> / 6</span>
      </div>

      <div id="preview-pdf">
        <PreviewReportsPage1 dataReport={dataReport} />
        <PreviewReportsPage2 dataReport={dataReport} />
        <PreviewReportsPage3 dataReport={dataReport} />
        <PreviewReportsPage4 dataReport={dataReport} />
        <PreviewReportsPage5 dataReport={dataReport} />
        <PreviewReportsPage6 dataReport={dataReport} />
      </div>

      <div />
    </div>
  );
};

export default memo(PreviewReportsScreen);
