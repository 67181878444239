export const messageErrorLogin =
  "ログイン出来ませんでした。正しい情報を入力してください";

export const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? "",
};

export const COGNITO_IDENTITY_SERVICE = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID ?? "",
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY ?? "",
};
