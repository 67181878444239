import { memo, useEffect, useMemo, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Typography,
  Row,
  DatePicker,
  Col,
  Table,
  Select,
  FormInstance,
  Spin,
} from "antd";
import { useWatch } from "antd/lib/form/Form";
import cx from "classnames";
import dayjs from "dayjs";
import { cloneDeep, omit, remove } from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import CustomTag from "@app/components/atoms/Tag/Tag";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import {
  DEVICE_TYPE,
  MeasurementResultPathsEnum,
} from "@app/features/measurement_results/measurement-results";
import {
  TAG_STYLE,
  getMeasurementResults,
  TAG_MEASUREMENT_STYLE,
  MeasurementResult,
  DesignPlotLocation,
} from "@app/features/measurers/measurers";
import {
  getListPlotLocationsByType,
  ICON_TYPE,
  ICON_TYPE_A,
  ICON_TYPE_B,
  ICON_TYPE_C,
  REPORTS_MODE,
  REPORTS_TYPE,
  TYPE_POINT,
  updateIsSortPlotLocations,
} from "@app/features/reports/reports";
import { formatTime } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./LinkingData.module.scss";
import ModalInputData from "./ModalInputData/ModalInputData";

const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const LinkingData = ({ form }: { form: FormInstance }) => {
  const { Column } = Table;
  const [isOpenInputData, setIsOpenInputData] = useState(false);
  const dispatch = useAppDispatch();
  const workplaceId = useWatch(["workplace", "id"]);
  const workplaceData = useWatch(["workplace"]);
  const listPlotLocations: DesignPlotLocation[] = useWatch([
    "workplace",
    "measurement_design",
    "design_plot_locations",
  ]);
  const LoadingSpinner = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
  );

  const {
    measurers: { measurementResults },
    reports: { reportDetail, isSortPlotLocations },
  } = useAppSelector((state: RootState) => state);

  const [dateFrom, setDateFrom] = useState(
    reportDetail?.type === REPORTS_TYPE.SAVE
      ? null
      : moment()
          .subtract({
            days: 7,
          })
          .format(TIME_FORMAT)
  );

  useEffect(() => {
    if (reportDetail?.date_from) {
      setDateFrom(moment(reportDetail?.date_from).format(TIME_FORMAT));
    }
  }, [reportDetail?.date_from]);

  const [dateTo, setDateTo] = useState(
    reportDetail?.type === REPORTS_TYPE.SAVE
      ? null
      : moment().format(TIME_FORMAT)
  );

  const [isLoadingMeasurementResults, setIsLoadingMeasurementResults] =
    useState(false);

  const [keyModalInputData, setKeyModalInputData] = useState(Date.now());

  const { mode, id } = useParams<{ mode: string; id: string }>();

  const isFillWorkplace = useMemo(() => {
    const workplaceDataOmit = omit(
      workplaceData,
      "measurement_design",
      "listPlot",
      "measurement_item",
      "urlImage"
    );
    return Object.keys(workplaceDataOmit).some(key => !!workplaceDataOmit[key]);
  }, [workplaceData]);

  const hasPlotLocations = useMemo(() => {
    return !!listPlotLocations?.some(
      item =>
        item.type === TYPE_POINT.SOONKEI || item.type === TYPE_POINT.BAKURO
    );
  }, [listPlotLocations]);

  useEffect(() => {
    if (hasPlotLocations) {
      setIsLoadingMeasurementResults(true);
      const params = {
        report_id: mode === REPORTS_MODE.EDIT ? id : undefined,
        workplace_id: workplaceId ?? undefined,
        date_from: dateFrom ? formatTime(dateFrom, false, true) : undefined,
        date_to: dateTo ? formatTime(dateTo, false, true) : undefined,
      };
      dispatch(getMeasurementResults(params))
        .then(unwrapResult)
        .catch(() => {
          openNotification({
            type: "warning",
            message: MessageError.ERRMSG_002,
          });
        })
        .finally(() => {
          setIsLoadingMeasurementResults(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    workplaceId,
    mode,
    dateFrom,
    dateTo,
    hasPlotLocations,
    listPlotLocations?.length,
    isSortPlotLocations,
  ]);

  const previewTable = useMemo(() => {
    if (!listPlotLocations)
      return [
        {
          key: "A",
          title: "A測定箇所",
          applicablePart: 0,
          alreadyTied: 0,
        },
        {
          key: "B",
          title: "B測定箇所",
          applicablePart: 0,
          alreadyTied: 0,
        },
        {
          key: "ば",
          title: "ばく露計測定箇所",
          applicablePart: 0,
          alreadyTied: 0,
        },
      ];
    const getAllreadyTied = (iconType: number) => {
      return [...listPlotLocations]
        ?.filter(
          item =>
            item.icon_type === iconType && !!item.measurement_result?.length
        )
        .map(item => item.measurement_result)
        .flat(1).length;
    };
    return [
      {
        key: "A",
        title: "A測定箇所",
        applicablePart: workplaceData?.listPlot?.A,
        alreadyTied: getAllreadyTied(ICON_TYPE_A),
      },
      {
        key: "B",
        title: "B測定箇所",
        applicablePart: workplaceData?.listPlot?.B,
        alreadyTied: getAllreadyTied(ICON_TYPE_B),
      },
      {
        key: "ば",
        title: "ばく露計測定箇所",
        applicablePart: workplaceData?.listPlot?.ば,
        alreadyTied: getAllreadyTied(ICON_TYPE_C),
      },
    ];
  }, [listPlotLocations, workplaceData]);

  const filterMeasurementResults = useMemo(() => {
    return cloneDeep(measurementResults).sort((item1, item2) =>
      dayjs(item1.start_time).isAfter(item2.start_time) ? -1 : 1
    );
  }, [measurementResults]);

  const handleSelectPlotLocation = (
    plotId: number,
    record: MeasurementResult
  ) => {
    const plotLocations: DesignPlotLocation[] = form.getFieldValue([
      "workplace",
      "measurement_design",
      "design_plot_locations",
    ]);
    if (!plotLocations) return;
    const index = plotLocations?.findIndex(
      (p: { id: number }) => +p.id === +plotId
    );
    if (index === -1) return;
    const oldIndex = plotLocations?.findIndex(p =>
      p.measurement_result?.find(x => x.measurement_result_id === record.id)
    );
    if (oldIndex !== -1 && !!record.id) {
      const newResult = plotLocations[oldIndex].measurement_result.filter(
        item => item.measurement_result_id !== record.id
      );
      plotLocations[oldIndex].measurement_result = newResult;
    }
    const newMeasurementResult = {
      ...record,
      id: undefined,
      is_simply: record.is_simply ?? false,
      measurement_result_id: record.isNew ? undefined : record.id,
      isNew: undefined,
      start_time: record.start_time,
      end_time: record.end_time,
      laeq: record.laeq,
      device_model: record.device_model,
    };

    if (!plotLocations[index].measurement_result) {
      plotLocations[index].measurement_result = [];
    }
    plotLocations[index].measurement_result = [
      ...plotLocations[index].measurement_result,
      newMeasurementResult,
    ];

    form.setFieldsValue({
      workplace: {
        measurement_design: {
          design_plot_locations: plotLocations,
        },
      },
    });
  };

  const handleRemovePlotLocation = (record: MeasurementResult) => {
    const plotLocations: DesignPlotLocation[] = form.getFieldValue([
      "workplace",
      "measurement_design",
      "design_plot_locations",
    ]);
    const plotLocationsRemovedItem = plotLocations?.map(item => {
      remove(item.measurement_result, {
        measurement_result_id: record.id,
      });
      return item;
    });
    form.setFieldsValue({
      workplace: {
        measurement_design: {
          design_plot_locations: plotLocationsRemovedItem,
        },
      },
    });
  };

  const isConsecutivePlotLocations = useMemo(() => {
    let isConsecutive = true;
    if (!listPlotLocations || listPlotLocations?.length <= 1) return true;
    listPlotLocations.reduce((a, b) => {
      if (a.icon_type === b.icon_type && a.order !== b.order - 1) {
        isConsecutive = false;
      }
      return b;
    });
    return isConsecutive;
  }, [listPlotLocations]);

  useEffect(() => {
    setKeyModalInputData(Date.now());
  }, [workplaceId]);

  return (
    <div className={cx(styles.root, "bg-white border-radius-1")}>
      <ModalInputData
        key={keyModalInputData}
        isOpen={isOpenInputData}
        reportForm={form}
        onCancel={() => {
          setIsOpenInputData(false);
        }}
        handleSelectPlotLocation={handleSelectPlotLocation}
      />

      <input
        type="text"
        className="hidden-input position-Absolute"
        id="title-header"
      />
      <Typography className="p-6 font-weight-medium text-mono-dark-80 border-bottom-solid-gray200">
        測定データの紐づけ/入力
      </Typography>
      <Row className="p-6 gap-46 flex-start">
        <Col flex="1.6">
          {isFillWorkplace && hasPlotLocations ? (
            <>
              {hasPlotLocations && (
                <Typography className="text-left mb-6 text-system-error font-12">
                  見取り図を変更する場合は「測定箇所デザイン」で測定箇所を登録し直してください。
                  <br />
                  騒音計測定データを手入力する場合は「
                  <span
                    className="cursor-pointer text-underline"
                    onClick={() => setIsOpenInputData(true)}
                    aria-hidden="true"
                  >
                    測定データ入力
                  </span>
                  」で登録してください。
                </Typography>
              )}
              <div className="flex-justify-end flex-align-center">
                {hasPlotLocations && (
                  <Button
                    onClick={() => {
                      dispatch(updateIsSortPlotLocations(true));
                    }}
                    disabled={isConsecutivePlotLocations}
                    className="mr-Auto"
                  >
                    自動で測定箇所番号をふり直す
                  </Button>
                )}
                <DatePicker
                  style={{ width: "160px", borderRadius: "1px" }}
                  placeholder=""
                  allowClear={false}
                  value={dateFrom ? moment(dateFrom) : null}
                  disabledDate={current => {
                    return (
                      current &&
                      (current > moment().endOf("day") ||
                        current >= moment(dateTo).endOf("day"))
                    );
                  }}
                  onChange={(_, dateString: string) => {
                    setDateFrom(dateString);
                    document.getElementById("title-header")?.focus();
                  }}
                />
                <span className="px-2 text-mono-dark-80">〜</span>
                <DatePicker
                  style={{ width: "160px", borderRadius: "1px" }}
                  placeholder=""
                  allowClear={false}
                  value={dateTo ? moment(dateTo) : null}
                  disabledDate={current => {
                    return (
                      current &&
                      (current > moment().endOf("day") ||
                        current < moment(dateFrom).startOf("day"))
                    );
                  }}
                  onChange={(_, dateString: string) => {
                    setDateTo(dateString);
                    document.getElementById("title-header")?.focus();
                  }}
                />
              </div>
              {isLoadingMeasurementResults ? (
                <div className="position-Relative text-center mt-4">
                  {LoadingSpinner}
                </div>
              ) : (
                <>
                  {filterMeasurementResults?.length && dateFrom ? (
                    <Table
                      dataSource={filterMeasurementResults}
                      pagination={false}
                      onHeaderRow={() => {
                        return {
                          className: cx(
                            styles.headRow,
                            "font-12 font-weight-medium text-mono-dark-60"
                          ),
                        };
                      }}
                      onRow={row => {
                        return {
                          onClick: () => {
                            if (row.type === DEVICE_TYPE.BAKURO) {
                              window.open(
                                MeasurementResultPathsEnum.BAKURO_MEASUREMENT_RESULTS_DETAILS.replace(
                                  ":id",
                                  String(row.id)
                                )
                              );
                            }
                            if (row.type === DEVICE_TYPE.SOONKEI) {
                              window.open(
                                MeasurementResultPathsEnum.SOONKEI_MEASUREMENT_RESULTS_DETAILS.replace(
                                  ":id",
                                  String(row.id)
                                )
                              );
                            }
                          },
                          className: "cursor-pointer text-mono-dark-80",
                        };
                      }}
                      className={cx(styles.table)}
                    >
                      <Column
                        title="種別"
                        dataIndex="type"
                        render={(type: string) =>
                          type && (
                            <CustomTag
                              color={TAG_STYLE[type].color}
                              backgroundColor={TAG_STYLE[type].backgroundColor}
                            >
                              {TAG_STYLE[type].text}
                            </CustomTag>
                          )
                        }
                      />
                      <Column
                        title="測定箇所"
                        dataIndex="type"
                        render={(type, record: MeasurementResult) => {
                          const defaultValue = listPlotLocations?.find(item =>
                            item.measurement_result?.some(
                              x => x.measurement_result_id === record.id
                            )
                          );
                          return (
                            <Select
                              allowClear
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              onSelect={(value: number) =>
                                handleSelectPlotLocation(value, record)
                              }
                              onClear={() => {
                                handleRemovePlotLocation(record);
                              }}
                              defaultValue={defaultValue?.id}
                            >
                              {type &&
                                getListPlotLocationsByType(
                                  listPlotLocations,
                                  type
                                )?.map(location => (
                                  <Select.Option
                                    key={location.id}
                                    value={location.id}
                                  >
                                    {ICON_TYPE[location.icon_type]}-
                                    {location.order}
                                  </Select.Option>
                                ))}
                            </Select>
                          );
                        }}
                      />
                      <Column title="測定開始日時" dataIndex="start_time" />
                      <Column
                        title="型式/管理名"
                        dataIndex="device_model"
                        render={(
                          device_model,
                          {
                            device_management_name,
                          }: {
                            device_management_name: string;
                          }
                        ) => {
                          return (
                            <>
                              <span>{device_model ?? "-"}</span>
                              {device_management_name && (
                                <span>
                                  /<br />
                                  {device_management_name}
                                </span>
                              )}
                            </>
                          );
                        }}
                      />
                      <Column title="LAeq(全体)" dataIndex="laeq" />
                      <Column
                        title="DOSE(全体)"
                        dataIndex="dose"
                        render={value => (value ? `${value}%` : "-")}
                      />
                      <Column
                        title="除外設定"
                        dataIndex="measurement_type"
                        render={(measurement_type: boolean) => {
                          const type = measurement_type ? "active" : "inactive";
                          const { color, backgroundColor, text } =
                            TAG_MEASUREMENT_STYLE[type];
                          return (
                            <CustomTag
                              color={color}
                              backgroundColor={backgroundColor}
                            >
                              {text}
                            </CustomTag>
                          );
                        }}
                      />
                    </Table>
                  ) : (
                    <div
                      style={{ height: "60px" }}
                      className="flex-center bg-mono-dark-10 flex-direction-column mt-4"
                    >
                      <span className="text-mono-dark-40 font-12 font-weight-medium">
                        測定データがありません。
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              style={{ height: "370px" }}
              className="flex-center bg-mono-dark-10 flex-direction-column"
            >
              {!hasPlotLocations && isFillWorkplace && (
                <>
                  <span className="text-mono-dark-40 font-12 font-weight-medium">
                    測定データがありません。
                  </span>
                  <span className="text-mono-dark-40 font-12 mt-2">
                    「作業場情報入力」から測定データがある作業場を登録してください。
                  </span>
                </>
              )}
              {!isFillWorkplace && (
                <span className="text-mono-dark-40 font-12 mt-2">
                  「作業場情報入力」から作業場を登録してください。
                </span>
              )}
            </div>
          )}
        </Col>
        <Col flex="1">
          <Typography className="font-12 font-weight-bold text-mono-dark-60 mb-2">
            見取り図プレビュー
          </Typography>
          {hasPlotLocations ? (
            <Image
              width="100%"
              src={
                workplaceData?.urlImage ||
                workplaceData?.measurement_design?.rough_sketch_filename
              }
            />
          ) : (
            <div
              style={{ height: "200px" }}
              className="flex-center bg-mono-dark-10"
            >
              <span className="text-mono-dark-40 font-12">
                「作業場情報入力」から測定箇所デザインを作成してください。
              </span>
            </div>
          )}
          <Table
            dataSource={previewTable}
            pagination={false}
            onRow={() => {
              return {
                className: "text-mono-dark-80",
              };
            }}
            onHeaderRow={() => {
              return {
                className: "font-12 font-weight-medium text-mono-dark-60",
              };
            }}
            className={cx(styles.totalTable, "mt-4")}
          >
            <Column dataIndex="title" key="title" align="center" width="40%" />
            <Column
              title="該当箇所"
              dataIndex="applicablePart"
              key="applicablePart"
              align="center"
              width="30%"
            />
            <Column
              title="紐づけ済み"
              dataIndex="alreadyTied"
              key="alreadyTied"
              align="center"
              width="30%"
            />
          </Table>
        </Col>
      </Row>
    </div>
  );
};
export default memo(LinkingData);
