export type OPTION_TYPE = {
  option_id: string;
  option_value: string;
};

type TAG_TYPE = {
  text: string;
  color: string;
  backgroundColor: string;
};

export const TAG_STYLE: Record<string, TAG_TYPE> = {
  1: {
    text: "騒音",
    color: "#00C259",
    backgroundColor: "#E6F7F2",
  },
  2: {
    text: "ばく露",
    color: "#1515E8",
    backgroundColor: "#E5EFFF",
  },
};

export const TAG_MEASUREMENT_STYLE: Record<string, TAG_TYPE> = {
  active: {
    text: "済み",
    color: "#03A800",
    backgroundColor: "#EAF6EA",
  },
  inactive: {
    text: "未設定",
    color: "#8E8E8E",
    backgroundColor: "#F5F7F7",
  },
};
