import { memo, useMemo } from "react";

import { Col, Form, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";

import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

const SelectClassifications = ({
  index,
  form,
  disabled,
}: {
  index: number;
  form: FormInstance;
  disabled: boolean;
}) => {
  const { listClassifications } = useAppSelector(
    (state: RootState) => state.master
  );

  const watchWorkplace = Form.useWatch("workplace");

  const listClassificationsFilter = useMemo(() => {
    const list = [...listClassifications];
    [1, 2, 3].forEach(item => {
      if (item !== index + 1) {
        const fieldValue = form.getFieldValue([
          "workplace",
          `workplace_classification_${item}`,
        ]);
        if (fieldValue) {
          list.splice(
            list?.findIndex(x => x.id === fieldValue),
            1
          );
        }
      }
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, index, listClassifications, watchWorkplace]);

  return (
    <div>
      <Row className="mt-4" gutter={24}>
        <Col flex="146px" offset={0} />
        <Col flex="284px">
          <Form.Item
            initialValue={null}
            name={`workplace_classification_${index + 1}`}
          >
            <Select
              disabled={disabled}
              allowClear
              placeholder="選択してください"
            >
              {listClassificationsFilter?.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.code_jp_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default memo(SelectClassifications);
