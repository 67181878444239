import { memo } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Typography } from "antd";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { getUserLogin, login, LoginRequestDef } from "@app/features/auth/auth";
import { ReportsPathsEnum } from "@app/features/reports/reports";

import { useAppDispatch } from "../../../../redux/store";
import { messageErrorLogin } from "../../constants/auth.constants";
import styles from "./LoginScreen.module.scss";

const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = (data: LoginRequestDef) => {
    dispatch(login(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getUserLogin())
          .then(unwrapResult)
          .then(() => {
            history.push(ReportsPathsEnum.REPORTS);
          })
          .catch(() => {
            openNotification({
              type: "warning",
              message: messageErrorLogin,
            });
          });
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: messageErrorLogin,
        });
      });
  };

  return (
    <div className={cx(styles.root)}>
      <div>
        <div className={cx(styles.logo)}>
          <Image width={80} src={Logo} />
          <Typography.Title level={4} className="text-mono-dark-80">
            騒音ばく露 <br /> データ管理ソフトウェア
          </Typography.Title>
        </div>

        <div className={cx(styles.container)}>
          <Form onFinish={handleSubmit}>
            <Typography className={cx(styles.title)}>メールアドレス</Typography>
            <Form.Item name="email">
              <Input />
            </Form.Item>
            <Typography className={cx(styles.title)}>パスワード</Typography>
            <Form.Item name="password">
              <Input.Password className={cx(styles.pass)} />
            </Form.Item>
            <Form.Item>
              <Button
                className={cx(styles.buttonSubmit)}
                type="primary"
                htmlType="submit"
              >
                ログイン
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default memo(LoginScreen);
