import dayjs from "dayjs";

/**
 * Check if a string looks like an external URL
 */
export const isURL = (str: string) => {
  return /http|www/.test(str);
};

/**
 * A promise to delay an async function
 * @param ms how many milliseconds to wait
 */
export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getInitials = (name: string, maxChar: number) => {
  return name
    .split(/\s/)
    .map(word => word[0])
    .join("")
    .substr(0, maxChar)
    .toUpperCase();
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};

export const formatTime = (
  time: string,
  isSetTimeZone = false,
  isJustGetDate = false
) => {
  const format = isJustGetDate ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
  return isSetTimeZone
    ? dayjs(time).tz().format(format)
    : dayjs(time).format(format);
};

export const numberCount = (
  page: number,
  limit: number,
  totalCount: number
) => {
  return {
    numberCountStart: page * limit - limit + 1,
    numberCountEnd: Math.min(page * limit, totalCount),
  };
};

export const numberFormat = (
  value: number | undefined | null,
  decimals = 1
) => {
  if (value === 0) return 0;
  if (!value) return "-";

  return Number(value).toFixed(decimals);
};

export const getValueClamp = (
  value: number | undefined,
  min: number | undefined,
  max: number | undefined
) => {
  if (!value || !min || !max) return undefined;
  return Math.min(Math.max(value, min), max);
};

export const convertMinuteToHour = (
  minutes: number,
  formatHour = "時間",
  formatMinute = "分"
) => {
  if (!minutes) return "-";
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  return `${hour < 10 ? `0${hour}` : hour}${formatHour} ${
    minute < 10 ? `0${minute}` : minute
  }${formatMinute}`;
};
