import { SortOrder } from "antd/lib/table/interface";

export const STEP_VALUE: Record<string, string> = {
  1: "基礎情報入力",
  2: "測定者情報入力",
  3: "事業所情報入力",
  4: "作業場情報入力",
  5: "測定データの紐づけ/入力",
  6: "所見コメント登録",
};

export const REPORTS_TYPE = {
  DRAFT: 1,
  SAVE: 2,
};

export const MAX_POINT_CANVAS: Record<string, number> = {
  源: 5,
  A: 30,
  B: 6,
  ば: 10,
  人: 10,
};

const COLOR_RED = "#ac0000";
const COLOR_GREEN = "#78a100";

export const RECTANGLES_TYPE = {
  RED: 1,
  GREEN: 2,
};

export const RECTANGLES_TYPE_BY_COLOR = {
  [COLOR_RED]: 1,
  [COLOR_GREEN]: 2,
};

export const MAX_COUNT_RECTANGLES = {
  RED: 5,
  GREEN: 10,
};

export const CANVAS_HEIGHT_ROOT = 520;

export const CANVAS_WIDTH_ROOT = 1200;

export const MAX_COUNT_ZOOM = 5;

export const SCALE_ZOOM = 0.2;

export const TYPE_POINT = {
  SOONKEI: 1,
  BAKURO: 2,
  REST: 3,
};

export const ICON_TYPE: Record<number, string> = {
  0: "源",
  1: "A",
  2: "B",
  3: "ば",
  4: "人",
};

export const ICON_TYPE_A = 1;
export const ICON_TYPE_B = 2;
export const ICON_TYPE_C = 3;

export const REPORTS_MODE = {
  EDIT: "edit",
  CREATE: "create",
};

export const ACTIONS_MODE = {
  UPDATE: "update",
};

export const LIST_PLOT_LOCATIONS = [
  {
    text: "源",
    color: "rgba(235, 75, 0, 1)",
    list: [],
  },
  {
    text: "A",
    color: "rgba(0, 102, 255, 1)",
    list: [],
  },
  {
    text: "B",
    color: "rgba(0, 194, 89, 1)",
    list: [],
  },
  {
    text: "ば",
    color: "rgba(164, 61, 244, 1)",
    list: [],
  },
  {
    text: "人",
    color: "rgba(0, 0, 0, 0.8)",
    list: [],
  },
];

export const STEP_REPORTS = {
  BASIC_INFORMATIONS: 1,
  MEASURER: 2,
  OFFICES: 3,
  WORKPLACE: 4,
  LINKING_DATA: 5,
  COMMENT: 6,
};

export const REPORTS_TABLE_HEAD = [
  {
    text: "最終更新",
    dataIndex: "update_at",
  },
  {
    text: "事業所",
    dataIndex: "office_name",
  },
  {
    text: "作業場",
    dataIndex: "workplace_name",
  },
  {
    text: "測定日",
    dataIndex: "measurement_date",
    width: "60px",
  },
  {
    text: "騒音管理区分",
    dataIndex: "noiseControl",
    width: "60px",
  },
  {
    text: "測定内容",
    dataIndex: "measurement",
    width: "60px",
  },
  {
    text: "ステータス",
    dataIndex: "status",
    width: "60px",
  },
];

export const CLASSIFY: Record<number, string> = {
  1: "Ⅰ",
  2: "Ⅱ",
  3: "Ⅲ",
};

export const MIN_LEQ = 80;

export const MAX_SIZE_UPLOAD = 5 * 1024 * 1024;

export enum MANAGEMENT_TYPE {
  TYPE_1 = "Ⅰ",
  TYPE_2 = "Ⅱ",
  TYPE_3 = "Ⅲ",
}

export enum EVALUATION_TOTAL_TYPE {
  TYPE_1 = 1,
  TYPE_2 = 2,
  TYPE_3 = 3,
}

export const SOUND_LEVEL_METER_PROPERTY_TYPE = {
  FAST: 10,
  SLOW: 20,
};

export const CLASSIFICATIONS_HEAD = [
  {
    text: "号区分",
    dataIndex: "code_jp_name",
    width: "120px",
  },
  {
    text: "説明",
    dataIndex: "note",
  },
];

export const COUNT_WORKPLACE_TYPE = 5;

export const WORKPLACE_TYPE_INFORMATION = [
  {
    key: "第1号",
    text: "粉じん障害に関係するものを取り扱う作業場",
  },
  {
    key: "第2号",
    text: "放射性物質に関係するものを取り扱う作業場",
  },
  {
    key: "第3号",
    text: "特定化学物質に関係するまたはコークス炉を用いる作業場",
  },
  {
    key: "第4号",
    text: "有害金属を取り扱う作業場",
  },
  {
    key: "第5号",
    text: "有機溶剤などを取り扱う作業場",
  },
];

export const SORT_DIRECTION = {
  ASC: "asc",
  DESC: "desc",
};

export const SORT_DIRECTION_TABLE: Record<string, SortOrder> = {
  asc: "ascend",
  desc: "descend",
};

export const FIELD_SORT: Record<string, string> = {
  update_at: "update_at",
  office_name: "office_name",
  workplace_name: "workplace_name",
  measurement_date: "measurement_date",
  noiseControl: "type",
  measurement: "",
  status: "type",
};
