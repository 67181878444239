import { memo, useEffect, useMemo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, FormInstance, Input, Row, Table, Typography, Upload } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { UploadProps } from "antd/lib/upload";
import cx from "classnames";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";

import Button from "@app/components/atoms/Button/Button";
import { Item } from "@app/components/atoms/Form/Form";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { uploadImageToS3 } from "@app/features/master/master";
import { DesignPlotLocation } from "@app/features/measurers/measurers";
import { convertJapaneseCalendar } from "@app/helpers/file.helper";
import { numberFormat } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  CLASSIFY,
  getManagementType,
  ICON_TYPE_A,
  ICON_TYPE_B,
  MAX_POINT_CANVAS,
  MAX_SIZE_UPLOAD,
  MIN_LEQ,
  postEvaluations,
  STEP_REPORTS,
  TYPE_POINT,
} from "../../reports";
import styles from "./Comment.module.scss";

type TableType = {
  no: string;
  leq: number | undefined | null;
  disable: boolean;
};

const Comment = ({ form }: { form: FormInstance }) => {
  const LOCATIONS = [
    {
      type: "A",
      rowCount: 10,
    },
    {
      type: "B",
      rowCount: 2,
    },
  ];

  const [tableDataSoonkei, setTableDataSoonkei] = useState<
    Record<string, TableType[]>
  >({
    A: [],
    B: [],
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableDataBakuro, setTableDataBakuro] = useState<any>([]);
  const [previewImage, setPreviewImage] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notifyError, setNotifyError] = useState<boolean>(false);

  const { stepReport, evaluations } = useAppSelector(
    (state: RootState) => state.reports
  );

  const dispatch = useAppDispatch();

  const listPlotLocations: DesignPlotLocation[] = useWatch([
    "workplace",
    "measurement_design",
    "design_plot_locations",
  ]);

  const DataMeasuermentA = useMemo(() => {
    return [
      {
        measure_A_value: evaluations?.measure_A_value,
        measure_A_value_deviation: evaluations?.measure_A_value_deviation,
        measure_A_data_count: evaluations?.measure_A_data_count,
      },
    ];
  }, [evaluations]);

  const isDuplicatePlotLocationsOrUnlinked = useMemo(() => {
    return (
      listPlotLocations?.some(item => item.measurement_result?.length > 1) ||
      !listPlotLocations?.some(item => item.measurement_result?.length)
    );
  }, [listPlotLocations]);

  useEffect(() => {
    const tableDataSoonkeiA = [];
    const tableDataSoonkeiB = [];

    for (let i = 1; i <= MAX_POINT_CANVAS.A; i += 1) {
      const plotLocation = listPlotLocations?.find(
        item => item.icon_type === ICON_TYPE_A && item.order === i
      );
      const data = {
        no: `A-${i}`,
        leq: isDuplicatePlotLocationsOrUnlinked
          ? undefined
          : plotLocation?.measurement_result?.[0]?.laeq,
      };
      tableDataSoonkeiA.push({
        ...data,
        disable: data.leq ? data.leq < MIN_LEQ : false,
      });
    }

    for (let i = 1; i <= MAX_POINT_CANVAS.B; i += 1) {
      const plotLocation = listPlotLocations?.find(
        item => item.icon_type === ICON_TYPE_B && item.order === i
      );
      const data = {
        no: `B-${i}`,
        leq: isDuplicatePlotLocationsOrUnlinked
          ? undefined
          : plotLocation?.measurement_result?.[0]?.laeq,
      };
      tableDataSoonkeiB.push({
        ...data,
        disable: data.leq ? data.leq < MIN_LEQ : false,
      });
    }

    const convertDataTableBakuro = cloneDeep(listPlotLocations)
      ?.filter(
        item =>
          item.type === TYPE_POINT.BAKURO && !!item.measurement_result?.length
      )
      ?.map(item => {
        const measure = item.measurement_result[0];
        return {
          no: `C-${item.order}`,
          measurement_time: dayjs(measure?.end_time).diff(
            dayjs(measure?.start_time),
            "hours"
          ),
          ...measure,
        };
      });

    setTableDataBakuro(convertDataTableBakuro);
    setTableDataSoonkei({
      A: tableDataSoonkeiA,
      B: tableDataSoonkeiB,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPlotLocations]);

  useEffect(() => {
    if (
      isDuplicatePlotLocationsOrUnlinked &&
      stepReport === STEP_REPORTS.COMMENT &&
      listPlotLocations?.length
    ) {
      setNotifyError(true);
    } else {
      setNotifyError(false);
    }
  }, [
    isDuplicatePlotLocationsOrUnlinked,
    listPlotLocations?.length,
    stepReport,
  ]);

  useEffect(() => {
    if (stepReport !== STEP_REPORTS.COMMENT) return;
    setIsLoading(true);
    dispatch(
      postEvaluations({
        data: {
          design_plot_locations: listPlotLocations,
          workplace_id: form.getFieldValue(["workplace", "id"]),
        },
      })
    )
      .then(unwrapResult)
      .then(data => {
        form.setFieldsValue({ measurement_evaluation: data });
      })
      .finally(() => setIsLoading(false));
  }, [
    dispatch,
    form,
    listPlotLocations,
    isDuplicatePlotLocationsOrUnlinked,
    stepReport,
  ]);

  const handleChange: UploadProps["onChange"] = data => {
    const newFile = data.file.originFileObj;
    if (!newFile) return;
    if (
      !evaluations?.pre_signed_attachment_url ||
      newFile.size > MAX_SIZE_UPLOAD
    )
      return;

    setPreviewImage(newFile);

    dispatch(
      uploadImageToS3({
        url: evaluations.pre_signed_attachment_url,
        imageUpload: newFile,
      })
    ).then(() => {
      form.setFieldsValue({
        attachment_filepath: evaluations.attachment_filepath,
      });
    });
  };

  return (
    <div className="bg-white border-radius-1">
      {isLoading && <LoadingSpinner />}
      <Typography className="heading-m p-6 text-mono-dark-80 border-bottom-solid-gray200">
        所見コメント登録
      </Typography>
      <Row className="p-6 gap-46 flex-start">
        <Item name="measurement_evaluation" initialValue={null} />
        <Col style={{ flex: 1.6, maxWidth: `${(1.6 * 100) / 2.6}%` }}>
          <div className="flex-space-between flex-align-center">
            <p className="heading-m text-mono-dark-60 m-0">測定データ</p>
            <div className="body-m lh-150 text-mono-dark-40 bg-mono-dark-10 px-4 py-1">
              {MIN_LEQ}dB未満
            </div>
          </div>
          {LOCATIONS.map(location => (
            <div className="mt-4 mb-8">
              <p className="heading-s text-mono-dark-60 text-left mb-2">{`${location.type}測定`}</p>
              <div className="d-flex">
                {[1, 2, 3].map((item, index) => (
                  <Table
                    dataSource={[...tableDataSoonkei[location.type]].splice(
                      index * location.rowCount,
                      location.rowCount
                    )}
                    pagination={false}
                    className={cx(
                      styles.table,
                      item === 2 ? cx(styles.tableBorder) : "",
                      "flex-1"
                    )}
                    onRow={rowData => {
                      return {
                        className: cx(rowData.disable ? styles.disableRow : ""),
                      };
                    }}
                  >
                    <Table.Column
                      title="測定点No."
                      dataIndex="no"
                      width="80px"
                      render={data => <div className="tb-data">{data}</div>}
                    />
                    <Table.Column
                      title="等価騒音レベルLeq"
                      dataIndex="leq"
                      className="disableData"
                      render={leq => {
                        return (
                          <div className="tb-data">
                            {leq !== null && leq !== undefined
                              ? `${numberFormat(leq)} dB`
                              : "-"}
                          </div>
                        );
                      }}
                    />
                  </Table>
                ))}
              </div>
            </div>
          ))}

          <div className="mt-4 mb-8">
            <p className="heading-s text-mono-dark-60 text-left mb-2">
              個人ばく露設定
            </p>
            {isDuplicatePlotLocationsOrUnlinked || !tableDataBakuro?.length ? (
              <div className={cx(styles.tableNoData)}>データがありません。</div>
            ) : (
              <Table
                dataSource={tableDataBakuro}
                className={cx(styles.table)}
                pagination={false}
                onHeaderRow={() => ({
                  style: {
                    width: "20%",
                  },
                })}
              >
                <Table.Column
                  dataIndex="no"
                  title="測定点No."
                  render={data => <div className="tb-data">{data}</div>}
                />
                <Table.Column
                  dataIndex="measurement_time"
                  title="測定時間"
                  render={data => <div className="tb-data">{data} h</div>}
                />
                <Table.Column
                  dataIndex="laeq"
                  title="LAeq"
                  render={data => (
                    <div className="tb-data">{numberFormat(data)} dB</div>
                  )}
                />
                <Table.Column
                  dataIndex="dose"
                  title="DOSE"
                  render={data => (
                    <div className="tb-data">{numberFormat(data)} %</div>
                  )}
                />
                <Table.Column
                  dataIndex="time_allowed_for_exposure"
                  title="ばく露許容時間"
                  render={data => <div className="tb-data">{data} h</div>}
                />
              </Table>
            )}
          </div>
          <p className="heading-m text-mono-dark-60 text-left m-0">
            測定結果と評価
          </p>
          <div className="flex-align-center flex-justify-start text-mono-dark-80 text-left mt-6 lh-150">
            <p className="heading-m m-0 mr-4">管理区分評価</p>
            {getManagementType(evaluations) && (
              <p className="body-l m-0">
                第{getManagementType(evaluations)}管理区分
              </p>
            )}
          </div>
          <p className="heading-s text-mono-dark-60 text-left m-0 mt-6">
            A測定
          </p>
          <Table
            dataSource={DataMeasuermentA}
            className={cx(styles.table)}
            pagination={false}
          >
            <Table.Column
              title=""
              width="calc((100%-220px)/3)"
              className="bg-mono-dark-10"
              render={() => (
                <div className="tb-data font-12 text-mono-dark-60">A測定</div>
              )}
            />
            <Table.Column
              title="算術平均値"
              dataIndex="measure_A_value"
              width="calc((100%-220px)/3)"
              render={data => (
                <div className="tb-data">
                  L(A) ={" "}
                  {isDuplicatePlotLocationsOrUnlinked
                    ? "-"
                    : numberFormat(data)}{" "}
                  (dB)
                </div>
              )}
            />
            <Table.Column
              title="標準偏差"
              dataIndex="measure_A_value_deviation"
              width="calc((100%-220px)/3)"
              render={data => (
                <div className="tb-data">
                  σa ={" "}
                  {isDuplicatePlotLocationsOrUnlinked
                    ? "-"
                    : numberFormat(data)}{" "}
                  (dB)
                </div>
              )}
            />
            <Table.Column
              title="平均値の計算に用いたデータの数"
              dataIndex="measure_A_data_count"
              width="220px"
              render={data => (
                <div className="tb-data">
                  {isDuplicatePlotLocationsOrUnlinked
                    ? "-"
                    : `m = ${data ?? ""}`}
                </div>
              )}
            />
          </Table>
          <p className="body-m text-mono-dark-60 text-left m-0 lh-150 mt-2">
            ※
            算術平均値および標準偏差の算出にあたっては、80db未満のデータは除く。
          </p>
          <div className={cx(styles.tableHalf, "mt-4")}>
            <div className="tableHalf_row">
              <div className="heading-s text-mono-dark-60 bg-mono-dark-10 flex-1 p-1">
                B測定（最大値）
              </div>
              <div className="body-m text-mono-dark-80 flex-1 p-1">
                L(B) ={" "}
                {isDuplicatePlotLocationsOrUnlinked
                  ? " - "
                  : numberFormat(evaluations?.measure_B_value)}
                (dB)
              </div>
            </div>
            <div className="tableHalf_row">
              <div className="heading-s text-mono-dark-60 bg-mono-dark-10 flex-1 p-1">
                個人ばく露測定（最大値）
              </div>
              <div className="body-m text-mono-dark-80 flex-1 p-1">
                L(ばく露) ={" "}
                {isDuplicatePlotLocationsOrUnlinked
                  ? " - "
                  : numberFormat(evaluations?.measure_C_value)}
                (dB)
              </div>
            </div>
          </div>
          <p className="heading-s text-mono-dark-60 text-left m-0 mt-6">
            過去履歴
          </p>

          <div className={cx(styles.historyTable, "mt-2 d-flex")}>
            <div className="col-fixed text-mono-dark-60 heading-s">
              <div className="cell-item no-border bg-mono-dark-10" />
              {!!evaluations?.history_evaluations?.length && (
                <div className="cell-item no-border bg-mono-dark-10" />
              )}
              <div className="cell-item bg-mono-dark-10">A測定値</div>
              <div className="cell-item bg-mono-dark-10">B測定値</div>
              <div className="cell-item bg-mono-dark-10">
                個人ばく露測定の結果
              </div>
              <div className="cell-item bg-mono-dark-10">管理区分</div>
            </div>
            <div className="col-content">
              {(evaluations?.history_evaluations?.length
                ? evaluations?.history_evaluations
                : new Array(4).fill({
                    measurement_date: null,
                    evaluation_A: null,
                    evaluation_B: null,
                    evaluation_C: null,
                  })
              )?.map((data, index) => (
                <div className="col-content-item text-center">
                  <div className="heading-s cell-item bg-mono-dark-10 no-border text-dark-60-alpha">
                    {index === 0 ? "前回" : `${index + 1}回前`}
                  </div>
                  {!!evaluations?.history_evaluations?.length && (
                    <div className="heading-s cell-item bg-mono-dark-10 text-dark-60-alpha">
                      {convertJapaneseCalendar(data.measurement_date)}
                    </div>
                  )}
                  <div className="body-m cell-item text-mono-dark-80">
                    {data.measure_A_value !== null &&
                    data.measure_A_value !== undefined
                      ? `${numberFormat(data.measure_A_value)} dB`
                      : "-"}
                  </div>
                  <div className="body-m cell-item text-mono-dark-80">
                    {data.measure_B_value !== null &&
                    data.measure_B_value !== undefined
                      ? `${numberFormat(data.measure_B_value)} dB`
                      : "-"}
                  </div>
                  <div className="body-m cell-item text-mono-dark-80">
                    {data.measure_C_value !== null &&
                    data.measure_C_value !== undefined
                      ? `${numberFormat(data.measure_C_value)} dB`
                      : "-"}
                  </div>
                  <div className="body-m cell-item text-mono-dark-80">
                    {data.evaluation_total
                      ? `${CLASSIFY[data.evaluation_total]}`
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col style={{ flex: 1 }}>
          {notifyError && (
            <div>
              <p className="text-system-error font-12 line-height-18">
                測定データの紐付け画面で測定箇所の設定をやり直してください。
                <br />
                １つの測定箇所に対して１つの測定データと紐付けるように設定してください。
              </p>
            </div>
          )}
          <p className="heading-s text-left text-mono-dark-60 m-0">
            所見コメント
          </p>
          <Item name="memo" initialValue={null}>
            <Input.TextArea
              placeholder="入力してください。"
              maxLength={1000}
              disabled={isDuplicatePlotLocationsOrUnlinked}
              showCount={{
                formatter: ({ count, maxLength }) =>
                  `${count}/${maxLength}文字`,
              }}
              autoSize={false}
              className={cx(styles.textarea, "mt-2")}
            />
          </Item>

          <p className="heading-s text-left text-mono-dark-60 mt-9 pt-2 mb-2">
            画像添付
          </p>

          {previewImage || form.getFieldValue("pre_signed_attachment_url") ? (
            <img
              src={
                previewImage
                  ? URL.createObjectURL(previewImage)
                  : form.getFieldValue("pre_signed_attachment_url")
              }
              alt=""
              width="100%"
            />
          ) : (
            <Upload.Dragger
              listType="picture-card"
              showUploadList={false}
              onChange={handleChange}
              disabled={isDuplicatePlotLocationsOrUnlinked}
            >
              <p className="label-s mt-0 mb-4 text-mono-dark-40">
                ここに画像をドラッグ＆ドロップしてください。
              </p>
              <Button disabled={isDuplicatePlotLocationsOrUnlinked}>
                画像を選択
              </Button>
            </Upload.Dragger>
          )}
          <Item name="pre_signed_attachment_url" initialValue={null} />
          <Item name="attachment_filepath" initialValue={null} />
          <Item name="recorder_level_manufacturer_format" />
          <Item name="recorder_level_dynamic_characteristics" />
        </Col>
      </Row>
    </div>
  );
};
export default memo(Comment);
