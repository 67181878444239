/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useRef } from "react";

import { Circle, Transformer } from "react-konva";

const TransformerMeasurers = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
}: any) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Circle
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={e => {
          const isClientRect = e.target.isClientRectOnScreen();
          onChange({
            ...shapeProps,
            x: isClientRect ? e.target.x() : shapeProps.x - 0.00001,
            y: isClientRect ? e.target.y() : shapeProps.y - 0.00001,
          });
        }}
        strokeScaleEnabled={false}
        onMouseEnter={onMouseEnter}
        onContextMenu={onContextMenu}
        onMouseLeave={onMouseLeave}
        onTransformEnd={() => {
          const node = shapeRef.current;
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
            rotation: node.rotation(),
          });
        }}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

export default memo(TransformerMeasurers);
