import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { WorkplacesEndpointsEnum } from "../constants/workplaces.endpoints";
import { Workplace } from "../workplaces";

// API-WPL-020
const getWorkplacesApi = (): Promise<AxiosResponse> => {
  const url = WorkplacesEndpointsEnum.GET_WORKPLACES;
  return api.get(url);
};

// API-WPL-010
const postWorkplacesApi = (data: Workplace): Promise<AxiosResponse> => {
  const url = WorkplacesEndpointsEnum.GET_WORKPLACES;
  return api.post(url, data);
};

// API-WPL-040
const updateWorkplacesApi = ({
  id,
  data,
}: {
  id: string;
  data: Workplace;
}): Promise<AxiosResponse> => {
  const url = WorkplacesEndpointsEnum.UPDATE_WORKPLACE.replace(":id", id);
  return api.put(url, data);
};

// API-WPL-030
const getWorkplacesDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = WorkplacesEndpointsEnum.GET_WORKPLACES_DETAILS.replace(":id", id);
  return api.get(url);
};

const workplacesApi = {
  getWorkplacesApi,
  postWorkplacesApi,
  updateWorkplacesApi,
  getWorkplacesDetailApi,
};

export default workplacesApi;
