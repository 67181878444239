import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import officesApi from "../api/offices.api";
import { OFFICES_FEATURE_KEY } from "../offices";
import { Office } from "../types/offices.types";

interface initialStateType {
  listOffices: Office[];
  listOfficesDetails: Office[];
}

const initialState: initialStateType = {
  listOffices: [],
  listOfficesDetails: [],
};

// API-OFC-020
export const getOffices = createAsyncThunk(
  `${OFFICES_FEATURE_KEY}/getOffices`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await officesApi.getOfficesApi();
      return response.data.offices;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-OFC-010
export const postOffices = createAsyncThunk(
  `${OFFICES_FEATURE_KEY}/postOffices`,
  async (data: Office, { rejectWithValue }) => {
    try {
      const response = await officesApi.postOfficesApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-OFC-040
export const putOffices = createAsyncThunk(
  `${OFFICES_FEATURE_KEY}/putOffices`,
  async (
    { officeId, data }: { officeId: string; data: Office },
    { rejectWithValue }
  ) => {
    try {
      const response = await officesApi.putOfficesApi({ officeId, data });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOfficesDetails = createAsyncThunk(
  `${OFFICES_FEATURE_KEY}/getWorkplacesDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await officesApi.getOfficesDeatilsApi(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const officesSlice = createSlice({
  name: OFFICES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOffices.pending, state => {
      state.listOffices = [];
    });
    builder.addCase(getOffices.fulfilled, (state, action) => {
      state.listOffices = action.payload;
    });
    builder.addCase(getOffices.rejected, state => {
      state.listOffices = [];
    });
    builder.addCase(getOfficesDetails.pending, state => {
      state.listOfficesDetails = [];
    });
    builder.addCase(getOfficesDetails.fulfilled, (state, action) => {
      state.listOfficesDetails = action.payload;
    });
  },
});

export const officesReducer = officesSlice.reducer;
