import { memo, useEffect, useMemo, useState } from "react";

import cx from "classnames";
import dayjs, { Dayjs } from "dayjs";

import DatePicker from "@app/components/atoms/TimePickerCustom/DatePicker";
import TimePicker from "@app/components/atoms/TimePickerCustom/TimePicker";
import { LIST_HOURS, LIST_MINUTES } from "@app/constants/constants";
import {
  DataTimePicker,
  MAX_HOUR_SELECT,
  TimeSelected,
  TimeSelectedLimit,
} from "@app/features/measurement_results/measurement-results";

import styles from "./DateTimePickerCustom.module.scss";

interface DateTimePickerCustomProps {
  dataTimePicker: DataTimePicker;
  className?: string;
  defaultTime: TimeSelected;
  timeSelectedLimit?: TimeSelectedLimit;
  setTime: (time: TimeSelected) => void;
  isDisabledTime?: boolean;
  disabled?: boolean;
}

const DateTimePickerCustom = ({
  dataTimePicker,
  className,
  defaultTime,
  setTime,
  timeSelectedLimit,
  isDisabledTime,
  disabled,
}: DateTimePickerCustomProps) => {
  const [timeSelected, setTimeSelected] =
    useState<Record<string, Dayjs | undefined>>(defaultTime);

  useEffect(() => {
    setTimeSelected(defaultTime);
  }, [defaultTime]);

  const maxHourSelect = useMemo(() => {
    if (timeSelectedLimit) {
      return Math.min(
        dayjs(timeSelectedLimit.endTimeLimit).diff(
          timeSelectedLimit.startTimeLimit,
          "hour"
        ) + 1,
        MAX_HOUR_SELECT
      );
    }
    return MAX_HOUR_SELECT;
  }, [timeSelectedLimit]);

  return (
    <div className={cx(styles.root, className)}>
      {["start", "end"].map((timeType, index) => (
        <div className="flex-align-center date-time-container">
          {dataTimePicker.map(item => {
            const fieldName = `${timeType}Time`;
            return (
              <>
                {item.textBefore && (
                  <span className="ml-2">
                    {index === 1 && item.textBefore === "開始時刻"
                      ? "表示範囲"
                      : item.textBefore}
                  </span>
                )}
                {item.key === "date" ? (
                  <DatePicker
                    style={{ width: 160 }}
                    allowClear={false}
                    format={item.format}
                    disabled={disabled}
                    value={timeSelected[fieldName]}
                    disabledDate={current => {
                      if (!timeSelectedLimit) return false;
                      return (
                        dayjs(current).isBefore(
                          timeSelectedLimit.startTimeLimit,
                          "day"
                        ) ||
                        dayjs(current).isAfter(
                          timeSelectedLimit.endTimeLimit,
                          "day"
                        )
                      );
                    }}
                    onSelect={value => {
                      const time = {
                        ...timeSelected,
                        [fieldName]: value,
                      };
                      setTimeSelected(time);
                      setTime(time);
                    }}
                    placeholder="日付を選択"
                  />
                ) : (
                  <>
                    <TimePicker
                      placeholder="00"
                      className="ml-2"
                      format={item.format}
                      disabled={disabled}
                      allowClear={false}
                      value={
                        timeSelected[fieldName]
                          ? dayjs(timeSelected[fieldName])
                          : null
                      }
                      hideDisabledOptions
                      showNow={false}
                      onSelect={value => {
                        const time = {
                          ...timeSelected,
                          [fieldName]: value,
                        };
                        if (
                          isDisabledTime &&
                          index === 1 &&
                          time.endTime?.get("hour") === maxHourSelect
                        ) {
                          setTimeSelected({
                            ...timeSelected,
                            endTime: time?.endTime?.set("minute", 0),
                          });
                          setTime({
                            ...timeSelected,
                            endTime: time?.endTime?.set("minute", 0),
                          });
                        } else {
                          setTimeSelected(time);
                          setTime(time);
                        }
                      }}
                      disabledTime={
                        isDisabledTime && index === 1
                          ? () => {
                              return {
                                disabledMinutes: selectedHour => {
                                  return selectedHour === maxHourSelect
                                    ? LIST_MINUTES.filter(m => m !== 0)
                                    : LIST_MINUTES.filter(
                                        m => m !== 0 && m !== 30
                                      );
                                },
                                disabledHours: () => {
                                  return LIST_HOURS.filter(
                                    h => h > maxHourSelect
                                  );
                                },
                              };
                            }
                          : undefined
                      }
                      style={{ width: 80 }}
                    />
                  </>
                )}
                {item.textAfter && (
                  <p className="ml-2">
                    {index === 1 && isDisabledTime && item.textAfter === "時"
                      ? "時間"
                      : item.textAfter}
                  </p>
                )}
              </>
            );
          })}
          {index === 0 && <span className="mr-2">〜</span>}
        </div>
      ))}
    </div>
  );
};

export default memo(DateTimePickerCustom);
