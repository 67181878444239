import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Typography } from "antd";
import { compile } from "path-to-regexp";
import { Link } from "react-router-dom";

import { ReactComponent as LogoAddition } from "@app/assets/images/icons/Addition.svg";
import NoData from "@app/assets/images/no-data.png";
import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import ReportsScreenTable from "../../components/ListReports/ListReports";
import { getReports, ReportsPathsEnum } from "../../reports";

const ReportsScreen = () => {
  const dispatch = useAppDispatch();
  const { listReports } = useAppSelector(state => state.reports);
  const { page, type, sort, direction } = getQueryParams(
    window.location.search
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getReports({
        page: page ? Number(page) : 1,
        type: type ? Number(type) : undefined,
        sort,
        direction,
      })
    )
      .then(unwrapResult)
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [direction, dispatch, page, sort, type]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {listReports?.reports?.length ? (
        <ReportsScreenTable />
      ) : (
        <>
          <div className="px-4 py-6">
            <div className="flex-align-center">
              <Typography className="mr-6 font-16 font-weight-medium text-mono-dark-80">
                報告書
              </Typography>
              <Button type="primary">
                <Link
                  to={compile(ReportsPathsEnum.REPORTS_DETAILS)({
                    mode: "create",
                  })}
                  className="flex-align-center"
                >
                  <LogoAddition className="mr-2" />
                  新規作成
                </Link>
              </Button>
            </div>
            <div className="position-center text-center">
              <div>
                <img className="img-reports" src={NoData} alt="#" />
              </div>

              <div className="mt-7">
                <Typography className="font-16 font-weight-regular text-primary-main">
                  データがありません。
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(ReportsScreen);
