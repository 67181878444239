import { memo, useState } from "react";

import { Checkbox, Col, Form, Input, Row, Typography } from "antd";
import type { FormInstance } from "antd";

import TextTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import { HintText } from "@app/constants/hintText.constants";
import { ValidationMessage } from "@app/constants/messages.constants";

const BasicInformations = ({ form }: { form: FormInstance }) => {
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState<boolean>(false);
  return (
    <div className="bg-white border-radius-1">
      <Typography className="p-6 font-weight-medium text-mono-dark-80 border-bottom-solid-gray200">
        基礎情報入力
      </Typography>
      <div className="py-8">
        <Row gutter={26}>
          <Col flex="166px">
            <TextTooltip content="報告書番号" hintText={HintText.HINT_002} />
          </Col>
          <Col flex="320px">
            <Form.Item
              initialValue={null}
              rules={[
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message: ValidationMessage.VALIDATION_MSG_001,
                },
              ]}
              name="report_number"
            >
              <Input maxLength={10} placeholder="半角英数" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-8" gutter={26}>
          <Col flex="166px">
            <TextTooltip content="記録表番号" hintText={HintText.HINT_003} />
          </Col>
          <Col flex="320px">
            <Form.Item
              initialValue={null}
              rules={[
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message: ValidationMessage.VALIDATION_MSG_001,
                },
              ]}
              name="record_sheet_number"
            >
              <Input maxLength={10} placeholder="半角英数" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-8" gutter={26}>
          <Col
            flex="166px"
            className="text-dark-60-alpha font-12 font-weight-bold mt-1"
          >
            宛名
          </Col>
          <Col span={11}>
            <Form.Item
              initialValue={false}
              name="is_save_address_for_submission"
            >
              <Checkbox
                className="ml-1"
                onChange={e => {
                  setIsSelectedCheckbox(e.target.checked);
                  form.setFieldsValue({
                    is_save_address_for_submission: e.target.checked,
                  });
                }}
              >
                <TextTooltip
                  className="font-weight-regular text-mono-dark-80"
                  content="報告書に宛名を記載"
                  hintText={HintText.HINT_004}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-2" gutter={26}>
          <Col flex="166px" />
          <Col flex="320px" className="pr-2">
            <Form.Item initialValue={null} name="address_for_submission">
              <Input
                maxLength={30}
                disabled={!isSelectedCheckbox}
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col span={1} className="p-0">
            殿
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default memo(BasicInformations);
