import { DesignPlotLocation } from "@app/features/measurers/measurers";

import { Evaluations, MANAGEMENT_TYPE, SORT_DIRECTION } from "../reports";

export const getPathUrl = (url: string | null) => {
  if (!url) return null;
  const arr = url
    .replace(/http:\/\/|https:\/\//, "")
    .replace("?", "/")
    .split("/");
  return `${arr[1]}/${arr[2]}`;
};

export const getListPlotLocationsByType = (
  listPlotLocations: DesignPlotLocation[],
  type: number
) => {
  if (!listPlotLocations?.length) return [];
  return listPlotLocations
    .filter(plot => plot.type === type)
    .sort((a, b) => a.icon_type - b.icon_type);
};

export const getManagementType = (evaluations: Evaluations | null) => {
  const measureAValue = evaluations?.measure_A_value ?? 0;
  const measureBValue = evaluations?.measure_B_value ?? 0;

  if (!measureAValue && !measureBValue) return "";
  const measureMax = Math.max(measureAValue, measureBValue);
  if (measureMax < 85) return MANAGEMENT_TYPE.TYPE_1;
  if (measureMax >= 85 && measureMax < 90) return MANAGEMENT_TYPE.TYPE_2;
  if (measureMax >= 90) return MANAGEMENT_TYPE.TYPE_3;
  return "";
};

export const getNumberClassification = (
  classificationNumber: number | null | undefined
) => {
  if (!classificationNumber) return "";
  return `別表${classificationNumber < 9 ? "１" : "２"}の${
    classificationNumber < 9 ? classificationNumber : classificationNumber - 8
  }号`;
};

export const getEvaluationType = (
  evaluation: number | null | undefined,
  iconName: string
) => {
  if (!evaluation) {
    switch (iconName) {
      case "A":
        return "A測定未実施";
      case "B":
        return "B測定未実施";
      case "C":
        return "個人ばく露測定未実施";
      default:
        return "-";
    }
  } else {
    switch (evaluation) {
      case 1:
        return `85dB > 𝘓 (${iconName === "C" ? "ばく露" : iconName})`;
      case 2:
        return `85dB ≦ 𝘓 (${iconName === "C" ? "ばく露" : iconName}) < 90 dB`;
      case 3:
        return `90 dB ≦ 𝘓 (${iconName === "C" ? "ばく露" : iconName})`;

      default:
        return "-";
    }
  }
};

export const getSortDirection = (sort?: string) => {
  switch (sort) {
    case SORT_DIRECTION.ASC:
      return SORT_DIRECTION.DESC;
    case SORT_DIRECTION.DESC:
      return undefined;
    case undefined:
      return SORT_DIRECTION.ASC;
    default:
      return undefined;
  }
};
