import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import cx from "classnames";

import styles from "./LoadingSpinner.module.scss";

const { Title } = Typography;

interface LoadingSpinnerProps {
  text?: string;
  isFitContent?: boolean;
}

const LoadingSpinner = ({ text, isFitContent }: LoadingSpinnerProps) => {
  const antIcon = (
    <Loading3QuartersOutlined style={{ fontSize: 24, color: "white" }} spin />
  );
  return (
    <div
      className={cx(styles.container, {
        [styles.isFitContent]: isFitContent,
      })}
    >
      {!!text && <Title level={3}>{text}</Title>}

      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingSpinner;
