import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import measurementResultApi from "../api/measurement-results.api";
import {
  MeasurementResultDataDef,
  MeasurementResultsDetail,
  MeasurementResultsPaginate,
  MeasurementResultsParams,
  MEASUREMENT_RESULTS_FEATURE_KEY,
} from "../measurement-results";

interface initialStateType {
  measurementResultsPaginate: MeasurementResultsPaginate | null;
  measurementResultsDetail: MeasurementResultsDetail | null;
  listIdMeasurementSelected: React.Key[];
}

const initialState: initialStateType = {
  measurementResultsPaginate: null,
  measurementResultsDetail: null,

  listIdMeasurementSelected: [],
};

// API-DATA-021
export const getListMeasurementResults = createAsyncThunk(
  `${MEASUREMENT_RESULTS_FEATURE_KEY}/getListMeasurers`,
  async (params: MeasurementResultsParams, { rejectWithValue }) => {
    try {
      const response =
        await measurementResultApi.getMeasurementResultsPaginateApi(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-DATA-030
export const getMeasurementResultsDetail = createAsyncThunk(
  `${MEASUREMENT_RESULTS_FEATURE_KEY}/getMeasurementResultsDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await measurementResultApi.getMeasurementResultDetailApi(
        id
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-DATA-010
export const createMeasurementResults = createAsyncThunk(
  `${MEASUREMENT_RESULTS_FEATURE_KEY}/createMeasurementResults`,
  async (
    { data, params }: { data: FormData; params: { type: number } },
    { rejectWithValue }
  ) => {
    try {
      const response = await measurementResultApi.createMeasurementResultsApi({
        data,
        params,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateMeasurementResult = createAsyncThunk(
  `${MEASUREMENT_RESULTS_FEATURE_KEY}/UpdateMeasurementResult`,
  async (
    {
      MeasurementResultId,
      data,
    }: { MeasurementResultId: string; data: MeasurementResultDataDef },
    { rejectWithValue }
  ) => {
    try {
      const response = await measurementResultApi.UpdateMeasurementResultApi({
        MeasurementResultId,
        data,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const measurementResultSlice = createSlice({
  name: MEASUREMENT_RESULTS_FEATURE_KEY,
  initialState,
  reducers: {
    updateListIdMeasurementSelected: (state, action) => {
      state.listIdMeasurementSelected = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getListMeasurementResults.fulfilled, (state, actions) => {
      state.measurementResultsPaginate = actions.payload;
    });
    builder.addCase(getListMeasurementResults.rejected, state => {
      state.measurementResultsPaginate = null;
    });

    builder.addCase(getMeasurementResultsDetail.pending, state => {
      state.measurementResultsDetail = null;
    });
    builder.addCase(getMeasurementResultsDetail.fulfilled, (state, action) => {
      state.measurementResultsDetail = action.payload;
    });
    builder.addCase(getMeasurementResultsDetail.rejected, state => {
      state.measurementResultsDetail = null;
    });
  },
});

export const { updateListIdMeasurementSelected } =
  measurementResultSlice.actions;

export const measurementResultsReducer = measurementResultSlice.reducer;
