import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { MeasurersResultsEndpointsEnum } from "../constants/measurement-results.endpoints";
import {
  MeasurementResultsParams,
  MeasurementResultDataDef,
} from "../measurement-results";

// API-DATA-021
const getMeasurementResultsPaginateApi = (
  params: MeasurementResultsParams
): Promise<AxiosResponse> => {
  const url = MeasurersResultsEndpointsEnum.GET_MEASUREMENT_RESULTS_PAGINATE;
  return api.get(url, { params });
};

// API-DATA-030
const getMeasurementResultDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = MeasurersResultsEndpointsEnum.MEASUREMENT_RESULTS_DETAIL.replace(
    ":id",
    id
  );
  return api.get(url);
};

// API-DATA-010

const createMeasurementResultsApi = ({
  data,
  params,
}: {
  data: FormData;
  params: { type: number };
}) => {
  const url = MeasurersResultsEndpointsEnum.CREATE_MEASUREMENT_RESULTS;
  return api.post(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
    params,
  });
};

// API-MDT-010

const UpdateMeasurementResultApi = ({
  MeasurementResultId,
  data,
}: {
  MeasurementResultId: string;
  data: MeasurementResultDataDef;
}) => {
  const url = MeasurersResultsEndpointsEnum.PUT_MEASUREMENT_RESULTS.replace(
    ":id",
    MeasurementResultId
  );
  return api.put(url, data);
};

const measurementResultApi = {
  getMeasurementResultsPaginateApi,
  getMeasurementResultDetailApi,
  createMeasurementResultsApi,
  UpdateMeasurementResultApi,
};

export default measurementResultApi;
