import { memo, useEffect, useState } from "react";

import { Tooltip } from "antd";
import { FormInstance } from "antd/es/form";
import { useWatch } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";

import { Item } from "@app/components/atoms/Form/Form";
import DateTimePickerCustom from "@app/components/atoms/TimePickerCustom/DateTimePickerCustom/DateTimePickerCustom";
import { ExclusionTemplate, FilterPeriod } from "@app/features/filters/filters";
import { handleSetTimeByStartTime } from "@app/helpers/dayjs/dayjs.helper";

import {
  DATA_DATE_TIME_PICKER,
  DATA_TIME_PICKER,
  MAX_FILTER_PERIODS,
  TimeSelected,
  TimeSelectedLimit,
} from "../../measurement-results";

interface ExclusionSetByTimeProps {
  form: FormInstance;
  timeSelectedLimit: TimeSelectedLimit;
  filterExclusionSettings?: ExclusionTemplate;
}

const ExclusionSetByTime = ({
  form,
  timeSelectedLimit,
  filterExclusionSettings,
}: ExclusionSetByTimeProps) => {
  const ExclusionTemplateId = useWatch("id");
  const filter_periods: FilterPeriod[] = useWatch("filter_periods");

  const [timeSelected, setTimeSelected] = useState<TimeSelected>({
    startTime: undefined,
    endTime: undefined,
  });

  const [listFilterPeriods, setListFilterPeriods] = useState<FilterPeriod[]>([
    {
      id: 1,
      start_time: null,
      end_time: null,
    },
  ]);

  const [triggerRender, settriggerRender] = useState(Date.now());

  useEffect(() => {
    settriggerRender(Date.now());
  }, [ExclusionTemplateId]);

  useEffect(() => {
    if (!filter_periods) {
      setListFilterPeriods([
        {
          id: 1,
          start_time: null,
          end_time: null,
        },
      ]);
    }
    if (filter_periods?.length) {
      const listFilterPeriodsClone = [...listFilterPeriods].filter(
        item => !!item.start_time || !!item.end_time
      );
      filter_periods.forEach(item => {
        const periodFind = listFilterPeriodsClone.find(
          period =>
            period.end_time === item.end_time &&
            period.start_time === item.start_time
        );
        if (!periodFind && listFilterPeriodsClone.length < MAX_FILTER_PERIODS) {
          listFilterPeriodsClone.push(item);
        }
      });
      setListFilterPeriods(
        listFilterPeriodsClone?.map((item, index) => {
          return { ...item, id: index + 1 };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExclusionTemplateId]);

  useEffect(() => {
    form.setFieldsValue({
      filter_periods: listFilterPeriods,
      valid_start_time: timeSelected.startTime?.format("YYYY/MM/DD HH:mm:00"),
      valid_end_time: timeSelected.endTime?.format("YYYY/MM/DD HH:mm:00"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilterPeriods, timeSelected]);

  useEffect(() => {
    setTimeSelected({
      startTime: filterExclusionSettings?.valid_start_time
        ? dayjs(filterExclusionSettings?.valid_start_time)
        : undefined,
      endTime: filterExclusionSettings?.valid_end_time
        ? dayjs(filterExclusionSettings?.valid_end_time)
        : undefined,
    });
  }, [filterExclusionSettings]);

  const handleRemovePeriods = (id: number | undefined) => {
    setListFilterPeriods(
      [...listFilterPeriods]?.filter(item => item.id !== id)
    );
  };

  const handleAddPeriods = () => {
    setListFilterPeriods([
      ...listFilterPeriods,
      {
        id: listFilterPeriods.length + 1,
        start_time: null,
        end_time: null,
      },
    ]);
  };

  const handleChangeTime = (
    id: number | undefined,
    time: Record<string, dayjs.Dayjs | undefined>
  ) => {
    const listFilterPeriodsClone = cloneDeep(listFilterPeriods);
    const index = listFilterPeriodsClone.findIndex(item => item.id === id);
    if (index === -1) return;
    listFilterPeriodsClone[index].start_time = time.startTime
      ? handleSetTimeByStartTime(time.startTime, timeSelected.startTime)
      : null;
    listFilterPeriodsClone[index].end_time = time.endTime
      ? handleSetTimeByStartTime(time.endTime, timeSelected.startTime)
      : null;
    setListFilterPeriods(listFilterPeriodsClone);
  };

  return (
    <div key={triggerRender}>
      <div className="heading-s">計算に含める時間</div>
      <div className="d-flex mb-6 mt-1">
        <DateTimePickerCustom
          dataTimePicker={DATA_DATE_TIME_PICKER}
          defaultTime={timeSelected}
          timeSelectedLimit={timeSelectedLimit}
          setTime={time => setTimeSelected(time)}
        />
      </div>
      <div className="heading-s">除外する時間</div>
      <div className="periodsScroll">
        {listFilterPeriods?.map((item, index) => (
          <div className="my-2 flex-align-center">
            <DateTimePickerCustom
              dataTimePicker={DATA_TIME_PICKER}
              defaultTime={{
                startTime: item.start_time ? dayjs(item.start_time) : undefined,
                endTime: item.end_time ? dayjs(item.end_time) : undefined,
              }}
              setTime={time => handleChangeTime(item.id, time)}
            />
            {index === listFilterPeriods.length - 1 && index !== 0 && (
              <Tooltip placement="bottom" title="削除">
                <span
                  className="icon-trash cursor-pointer font-20 ml-2 text-primary-main"
                  aria-hidden="true"
                  onClick={() => handleRemovePeriods(item.id)}
                />
              </Tooltip>
            )}
            {index === listFilterPeriods.length - 1 && (
              <Tooltip placement="bottom" title="追加">
                <span
                  className={`icon-plus-circle cursor-pointer font-20 ml-2 text-primary-main ${
                    listFilterPeriods.length === MAX_FILTER_PERIODS && "disable"
                  } `}
                  aria-hidden="true"
                  onClick={handleAddPeriods}
                />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
      <div style={{ display: "none" }}>
        <Item name="filter_periods" />
        <Item name="valid_start_time" />
        <Item name="valid_end_time" />
        <Item name="is_template" />
      </div>
    </div>
  );
};

export default memo(ExclusionSetByTime);
