import { memo } from "react";

import { Modal } from "antd";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import { updateIsChangeForm } from "@app/features/reports/reports";

import { useAppDispatch } from "../../../../../redux/store";
import styles from "./ModalConfirmRedirect.module.scss";

interface ModalConfirmRedirectProps {
  isOpenModalLeave: boolean;
  onCloseDialog: () => void;
  pathRedirect: string;
}

const ModalConfirmRedirect = ({
  isOpenModalLeave,
  onCloseDialog,
  pathRedirect,
}: ModalConfirmRedirectProps) => {
  const dispath = useAppDispatch();
  const history = useHistory();
  return (
    <Modal
      closable={false}
      className={cx(styles.root)}
      okText="このページを離れる"
      cancelText="キャンセル"
      visible={isOpenModalLeave}
      width={350}
      onOk={() => {
        dispath(updateIsChangeForm(false));
        history.push(pathRedirect);
      }}
      onCancel={onCloseDialog}
    >
      <div className="text-center">
        <div className="font-18 font-weight-bold mt-8">
          このサイトを離れますか？
        </div>
        <div className="mb-6">行った変更が保存されない可能性があります。</div>
      </div>
    </Modal>
  );
};

export default memo(ModalConfirmRedirect);
