import { memo, useRef } from "react";

import { useClickAway } from "ahooks";
import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";

import styles from "./DeletePointDialog.module.scss";

const DeletePointDialog = ({
  isOpenDeletePointDialog,
  position,
  onDelete,
  onCancel,
}: {
  isOpenDeletePointDialog: boolean;
  position: { offsetX: number; offsetY: number };
  onDelete: () => void;
  onCancel: () => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(() => {
    onCancel();
  }, ref);

  return (
    <div
      ref={ref}
      style={{
        top: position.offsetY,
        left: position.offsetX,
        display: isOpenDeletePointDialog ? "flex" : "none",
      }}
      className={cx(styles.deletePointBox, "flex-center")}
    >
      <Button type="text" onClick={onDelete}>
        <div className="text-system-error flex-center">
          <span className="icon-trash font-20" />
          <div className="font-12 font-weight-regular ml-1 text-system-error text">
            削除
          </div>
        </div>
      </Button>
    </div>
  );
};

export default memo(DeletePointDialog);
