import * as AWS from "amazon-cognito-identity-js";
import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { AuthEndpointsEnum, getTokens, saveTokens } from "../auth";
import { POOL_DATA } from "../constants/auth.constants";
import {
  CreateAccountDataDef,
  DataUpdateAccounts,
  LoginRequestDef,
} from "../types/auth.types";

const { accessToken } = getTokens();

export const authLogin = (data: LoginRequestDef) => {
  const userPool = new AWS.CognitoUserPool(POOL_DATA);
  const cognitoUser = new AWS.CognitoUser({
    Username: data.email,
    Pool: userPool,
  });

  const authDetails = new AWS.AuthenticationDetails({
    Username: data.email,
    Password: data.password,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result) {
        return resolve(result);
      },
      onFailure() {
        return reject();
      },
    });
  });
};

// API-login
export const getUserLoginApi = (): Promise<AxiosResponse> => {
  const userPool = new AWS.CognitoUserPool(POOL_DATA);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentUser: any = userPool.getCurrentUser();
  if (currentUser?.getUsername() && !accessToken) {
    const accessTokenSave = Object.entries(currentUser.storage).find(item => {
      return item[0].includes(`${currentUser?.getUsername()}.accessToken`);
    })?.[1];
    const refreshTokenSave = Object.entries(currentUser.storage).find(item => {
      return item[0].includes(`${currentUser?.getUsername()}.refreshToken`);
    })?.[1];
    const token = new AWS.CognitoAccessToken({
      AccessToken: `${accessTokenSave}`,
    });
    saveTokens({
      token: `${accessTokenSave}`,
      refreshToken: `${refreshTokenSave}`,
      expiresIn: token.decodePayload().exp,
      userName: token.decodePayload().username,
    });
  }
  const url = AuthEndpointsEnum.LOGIN;
  return api.post(url);
};

// API-registerAccount
export const registerAccountApi = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return new Promise((resolve, reject) => {
    const userPool = new AWS.CognitoUserPool(POOL_DATA);
    userPool.signUp(email, password, [], [], (err, result) => {
      if (err) {
        return reject(err);
      }
      return resolve(result);
    });
  });
};

// API-createAccount
export const createAccountApi = (
  data: CreateAccountDataDef
): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.CREATE_ACCOUNT;
  return api.post(url, data);
};

// API-resendVerificationCode
export const resendVerificationCodeApi = ({ email }: { email: string }) => {
  return new Promise((resolve, reject) => {
    const userPool = new AWS.CognitoUserPool(POOL_DATA);
    const cognitoUser = new AWS.CognitoUser({
      Username: email,
      Pool: userPool,
    });
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        return reject(err);
      }
      return resolve(result);
    });
  });
};

// API-confirm
export const confirmAccountApi = ({
  email,
  code,
}: {
  email: string;
  code: number;
}) => {
  return new Promise((resolve, reject) => {
    const userPool = new AWS.CognitoUserPool(POOL_DATA);
    userPool.getCurrentUser();
    const cognitoUser = new AWS.CognitoUser({
      Username: email,
      Pool: userPool,
    });
    cognitoUser.confirmRegistration(String(code), true, (err, result) => {
      if (err) {
        return reject(err);
      }
      return resolve(result);
    });
  });
};

// API-createAccount
export const getListAccountsApi = () => {
  const url = AuthEndpointsEnum.GET_LIST_ACCOUNTS;
  return api.get(url);
};

// API-updateAccount
export const updateAccountsApi = ({
  id,
  data,
}: {
  id: string;
  data: DataUpdateAccounts;
}) => {
  const url = AuthEndpointsEnum.UPDATE_ACCOUNTS.replace(":id", id);
  return api.put(url, data);
};

// API-refreshToken
export const refreshTokenApi = ({
  refreshToken,
  userName,
}: {
  refreshToken: string;
  userName: string;
}) => {
  return new Promise((resolve, reject) => {
    const userPool = new AWS.CognitoUserPool(POOL_DATA);
    userPool.getCurrentUser();
    const cognitoUser = new AWS.CognitoUser({
      Username: userName,
      Pool: userPool,
    });
    const token = new AWS.CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        return reject(err);
      }
      return resolve(session);
    });
  });
};
