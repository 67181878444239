export enum AuthEndpointsEnum {
  LOGIN = "login",
  LOGOUT = "logout",
  RESET_PASSWORD = "reset-password",
  REFRESH_TOKEN = "refresh-token",
  USERS = "users",
  CREATE_ACCOUNT = "/accounts",
  GET_LIST_ACCOUNTS = "/accounts",
  UPDATE_ACCOUNTS = "/accounts/:id",
}
