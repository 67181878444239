import { RouteItemDef } from "@app/types/route.types";

import BlankLayout from "../../../components/layouts/BlankLayout/BlankLayout";
import BarokuMeasurementResultsDetailScreen from "../Screens/BakuroMeasurementResultsDeatail/BakuroMeasurementResultsDetailScreen";
import MeasurementsResultsScreen from "../Screens/MeasurementsResultsScreen/MeasurementsResultsScreen";
import SoonkeiMeasurementResultsDetailScreen from "../Screens/SoonkeiMeasurementResultsDeatail/SoonkeiMeasurementResultsDetailScreen";
import TgraphMeasurersScreen from "../Screens/TgraphMeasurersScreen/TgraphMeasurersScreen";
import { MeasurementResultPathsEnum } from "../constants/measurement-results.paths";

const MEASUREMENT_RESULTS_T_GRAPH_SCREEN: RouteItemDef = {
  id: "ME-018",
  path: MeasurementResultPathsEnum.MEASUREMENT_RESULTS_T_GRAPH_SCREEN,
  component: TgraphMeasurersScreen,
  navigationTitle: "measurement/t-graph",
  layout: BlankLayout,
};

const MEASUREMENT_RESULTS_SCREEN: RouteItemDef = {
  id: "ME-001",
  path: MeasurementResultPathsEnum.MEASUREMENT_RESULTS,
  component: MeasurementsResultsScreen,
  navigationTitle: "measurement/results",
};

const BAKURO_MEASUREMENT_RESULTS_DETAILS_SCREEN: RouteItemDef = {
  id: "ME-0141",
  path: MeasurementResultPathsEnum.BAKURO_MEASUREMENT_RESULTS_DETAILS,
  component: BarokuMeasurementResultsDetailScreen,
  navigationTitle: "measurement/results",
  layout: BlankLayout,
};

const SOONKEI_MEASUREMENT_RESULTS_DETAILS_SCREEN: RouteItemDef = {
  id: "ME-0142",
  path: MeasurementResultPathsEnum.SOONKEI_MEASUREMENT_RESULTS_DETAILS,
  component: SoonkeiMeasurementResultsDetailScreen,
  navigationTitle: "measurement/results",
  layout: BlankLayout,
};

export const MEASUREMENT_RESULTS_ROUTES = [
  MEASUREMENT_RESULTS_SCREEN,
  MEASUREMENT_RESULTS_T_GRAPH_SCREEN,
  BAKURO_MEASUREMENT_RESULTS_DETAILS_SCREEN,
  SOONKEI_MEASUREMENT_RESULTS_DETAILS_SCREEN,
];
