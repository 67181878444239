import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import masterApi from "../api/master.api";
import { Classification, MASTER_FEATURE_KEY } from "../master";

interface initialStateType {
  listClassifications: Classification[];
}

const initialState: initialStateType = {
  listClassifications: [],
};

// API-CLF-020
export const getClassifications = createAsyncThunk(
  `${MASTER_FEATURE_KEY}/getClassifications`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await masterApi.getClassificationsApi();
      return response.data.classifications;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPresignedUrl = createAsyncThunk(
  `${MASTER_FEATURE_KEY}/getPresignedUrl`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await masterApi.getPresignedUrlApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadImageToS3 = createAsyncThunk(
  `${MASTER_FEATURE_KEY}/uploadImageToS3`,
  async (
    {
      url,
      imageUpload,
    }: {
      url: string;
      imageUpload: File;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await masterApi.uploadImageToS3Api({
        url,
        imageUpload,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const masterSlice = createSlice({
  name: MASTER_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getClassifications.pending, state => {
      state.listClassifications = [];
    });
    builder.addCase(getClassifications.fulfilled, (state, actions) => {
      state.listClassifications = actions.payload;
    });
    builder.addCase(getClassifications.rejected, state => {
      state.listClassifications = [];
    });
  },
});

export const masterReducer = masterSlice.reducer;
