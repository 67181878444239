import { memo } from "react";

import { Checkbox, InputNumber } from "antd";
import { useWatch } from "antd/lib/form/Form";

import { Item } from "@app/components/atoms/Form/Form";

import { TGRAPH_DATA_LIMIT } from "../../measurement-results";

const ExclusionSetByLevel = () => {
  const UNDER_LIMIT = useWatch("under_limit");
  return (
    <div className="d-flex pb-5">
      <div>
        <div className="font-12 font-weight-bold text-mono-gray-80">
          除外するしきい値
        </div>
        <div className="d-flex mt-4">
          <div>
            <div>下限</div>
            <div className="flex-align-end mt-1">
              <Item name="under_limit">
                <InputNumber
                  style={{ width: 120 }}
                  min={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MIN}
                  max={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MAX}
                  placeholder="半角数字"
                />
              </Item>
              <div className="ml-2 font-14">dB未満</div>
            </div>
          </div>
          <div className="ml-4">
            <div>上限</div>
            <div className="flex-align-end mt-1">
              <Item name="upper_limit">
                <InputNumber
                  style={{ width: 120 }}
                  min={UNDER_LIMIT}
                  max={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MAX}
                  placeholder="半角数字"
                />
              </Item>
              <div className="ml-2 font-14">dB超過</div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-7">
        <div className="font-12 font-weight-bold text-mono-gray-80 mb-2">
          除外する項目
        </div>
        <div className="ml-1 d-flex gap-10">
          <Item name="over_flag" valuePropName="checked">
            <Checkbox>
              <div className="font-weight-regular font-14">OVER</div>
            </Checkbox>
          </Item>
          <Item name="under_flag" valuePropName="checked">
            <Checkbox>
              <div className="font-weight-regular font-14">UNDER</div>
            </Checkbox>
          </Item>
          <Item name="impact_flag" valuePropName="checked">
            <Checkbox>
              <div className="font-weight-regular font-14">IMPACT</div>
            </Checkbox>
          </Item>
        </div>
      </div>
    </div>
  );
};

export default memo(ExclusionSetByLevel);
