import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import workplacesApi from "../api/workplaces.api";
import { Workplace, WORKPLACES_FEATURE_KEY } from "../workplaces";

interface initialStateType {
  listWorkplaces: Workplace[];
  listWorkplacesDetails: Workplace[];
}

const initialState: initialStateType = {
  listWorkplaces: [],
  listWorkplacesDetails: [],
};

// API-WPL-020
export const getWorkplaces = createAsyncThunk(
  `${WORKPLACES_FEATURE_KEY}/getWorkplaces`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await workplacesApi.getWorkplacesApi();
      return response.data.workplaces;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-WPL-010
export const postWorkplaces = createAsyncThunk(
  `${WORKPLACES_FEATURE_KEY}/postWorkplaces`,
  async (data: Workplace, { rejectWithValue }) => {
    try {
      const response = await workplacesApi.postWorkplacesApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// API-WPL-040
export const updateWorkplaces = createAsyncThunk(
  `${WORKPLACES_FEATURE_KEY}/updateWorkplaces`,
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: Workplace;
    },
    { rejectWithValue }
  ) => {
    try {
      await workplacesApi.updateWorkplacesApi({
        id,
        data,
      });
      return true;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// API-WPL-030
export const getWorkplacesDetail = createAsyncThunk(
  `${WORKPLACES_FEATURE_KEY}/getWorkplacesDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await workplacesApi.getWorkplacesDetailApi(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const workplacesSlice = createSlice({
  name: WORKPLACES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWorkplaces.pending, state => {
      state.listWorkplaces = [];
    });
    builder.addCase(getWorkplaces.fulfilled, (state, actions) => {
      state.listWorkplaces = actions.payload;
    });
    builder.addCase(getWorkplaces.rejected, state => {
      state.listWorkplaces = [];
    });
    builder.addCase(getWorkplacesDetail.pending, state => {
      state.listWorkplacesDetails = [];
    });
    builder.addCase(getWorkplacesDetail.fulfilled, (state, actions) => {
      state.listWorkplacesDetails = actions.payload;
    });
  },
});

export const workplacesReducer = workplacesSlice.reducer;
