import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import { RouteItemDef } from "@app/types/route.types";

import { ReportsPathsEnum } from "../reports";
import PreviewReportsScreen from "../screens/PreviewReportsScreen/PreviewReportsScreen";
import ReportDetailScreen from "../screens/ReportDetailScreen/ReportDetailScreen";
import ReportsScreen from "../screens/ReportsScreen/ReportsScreen";

const REPORT_DETAIL_SCREEN: RouteItemDef = {
  id: "reports",
  path: ReportsPathsEnum.REPORTS_DETAILS,
  component: ReportDetailScreen,
  navigationTitle: "reports.details",
};

const REPORTS_SCREEN: RouteItemDef = {
  id: "RE-002",
  path: ReportsPathsEnum.REPORTS,
  component: ReportsScreen,
  navigationTitle: "reports",
};

const PREVIEW_REPORTS_SCREEN: RouteItemDef = {
  id: "RE-010",
  path: ReportsPathsEnum.PREVIEW_REPORTS,
  component: PreviewReportsScreen,
  navigationTitle: "reports",
  layout: BlankLayout,
};

export const REPORTS_ROUTES = [
  REPORT_DETAIL_SCREEN,
  REPORTS_SCREEN,
  PREVIEW_REPORTS_SCREEN,
];
