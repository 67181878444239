/* eslint-disable no-irregular-whitespace */
import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";

import Button from "@app/components/atoms/Button/Button";
import TextTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import ModalConfirm from "@app/components/atoms/ModalConfirm/ModalConfirm";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import { MessageError } from "@app/constants/messages.constants";
import { getMeasurers } from "@app/features/measurers/measurers";
import {
  STEP_REPORTS,
  WORKPLACE_TYPE_INFORMATION,
} from "@app/features/reports/reports";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import SelectMeasurers from "./SelectMeasurers/SelectMeasurers";

const MeasuringPersonInformations = ({ form }: { form: FormInstance }) => {
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const {
    reports: { stepReport },
  } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    if (stepReport !== STEP_REPORTS.MEASURER) return;
    dispatch(getMeasurers())
      .then(unwrapResult)
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
      });
  }, [dispatch, stepReport]);

  const { listMeasurers } = useAppSelector(
    (state: RootState) => state.measurers
  );

  const [isOpenModalWorkplace, setIsOpenModalWorkplace] = useState(false);

  const fillDesigner = (value: number) => {
    if (!value) {
      form.resetFields(["measurement_designer"]);
    }
    const selected = [...listMeasurers].find(item => item.id === value);
    if (selected) {
      form.setFieldsValue({
        measurement_designer: {
          registration_number: selected.registration_number,
          name: selected.name,
          measurement_company: {
            ...selected.measurement_company,
          },
        },
      });
    }
  };

  return (
    <div className="bg-white border-radius-1">
      <Typography className="p-6 font-weight-medium text-mono-dark-80 border-bottom-solid-gray200">
        測定者情報入力
      </Typography>

      <div className="py-8 ">
        <Form.List name="measurement_designer">
          {() => {
            return (
              <>
                <Row gutter={26}>
                  <Col flex="175px">
                    <TextTooltip
                      content="測定デザイン担当者"
                      hintText={HintText.HINT_005}
                    />
                  </Col>
                  <Col flex="240px">
                    <div>
                      <div className="font-weight-medium text-mono-dark-60 mb-1">
                        担当者
                      </div>
                      <Form.Item initialValue={null} name="id">
                        <Select
                          allowClear
                          onChange={value => {
                            fillDesigner(value);
                          }}
                          placeholder="選択してください"
                        >
                          {listMeasurers.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="pl-1" flex="160px">
                    <div>
                      <div className="font-weight-medium text-mono-dark-60 mb-1">
                        登録番号
                      </div>
                      <Form.Item initialValue={null} name="registration_number">
                        <Input readOnly disabled />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Form.List name="measurement_company">
                  {() => {
                    return (
                      <>
                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="400px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                測定機関名
                              </div>
                              <Form.Item initialValue={null} name="name">
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="400px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                代表者名
                              </div>
                              <Form.Item
                                initialValue={null}
                                name="representative_name"
                              >
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="400px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                代表者職
                              </div>
                              <Form.Item
                                initialValue={null}
                                name="representative_job"
                              >
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="640px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                所在地
                              </div>
                              <Form.Item initialValue={null} name="address">
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="160px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                測定機関の登録番号
                              </div>
                              <Form.Item
                                initialValue={null}
                                name="registration_number"
                              >
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="400px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                統一精度管理の参加
                              </div>
                              <Form.Item
                                initialValue={null}
                                name="env_group_year"
                              >
                                <Input readOnly disabled placeholder="" />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px" />
                          <Col flex="400px">
                            <div>
                              <div className="font-weight-medium text-mono-dark-60 mb-1">
                                登録に係る指定作業場の種類
                              </div>

                              {new Array(5).fill(1).map((_, index) => {
                                return (
                                  <div className="ant-checkbox-group">
                                    <Form.Item
                                      initialValue={null}
                                      name={`workplace_type_${index + 1}`}
                                    >
                                      <Checkbox
                                        disabled
                                        checked={
                                          !!form.getFieldValue(
                                            "measurement_designer"
                                          )?.measurement_company?.[
                                            `workplace_type_${index + 1}`
                                          ]
                                        }
                                      >
                                        {`第${index + 1}号`}{" "}
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                );
                              })}
                              <div className="pl-4 mt-4 font-14 bg-red text-white line-height-22">
                                登録に係る指定作業場の種類がわからない方は
                                <Button
                                  onClick={() => setIsOpenModalWorkplace(true)}
                                  className="text-white"
                                  type="text"
                                >
                                  <span className="text font-weight-regular font-12 text-white">
                                    こちら。
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={26} className="mt-8">
                          <Col flex="175px">
                            <TextTooltip
                              content="測定担当者"
                              hintText={HintText.HINT_006}
                            />
                          </Col>
                          <Col flex="840px">
                            <div className="py-2 px-4 font-12 bg-mono-dark-10">
                              測定担当者は最大5名まで登録できます。
                              <br />
                              1〜2名の登録はそのまま名前が記載されますが、3名以上の登録で「担当者1」で登録した担当者名のみ記載され、以降は「他◯名」と省略されます。
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                </Form.List>
              </>
            );
          }}
        </Form.List>

        <Form.List name="measurers">
          {() => (
            <>
              {[...new Array(5)].map((_, index) => (
                <SelectMeasurers form={form} index={index} />
              ))}
            </>
          )}
        </Form.List>
      </div>

      <ModalConfirm
        onClose={() => setIsOpenModalWorkplace(false)}
        onCancel={() => setIsOpenModalWorkplace(false)}
        width={900}
        title=""
        isHiddenButtonSubmit
        isOpen={isOpenModalWorkplace}
      >
        <div>
          「登録に係る指定作業場」とは、作業環境測定法施行規則の
          <br />
          別表　作業場の種類によって定められた5種類の作業場を指します。
          <br />
          各作業場は、以下のように大別されます。
        </div>
        <Table
          dataSource={WORKPLACE_TYPE_INFORMATION}
          pagination={false}
          bordered
          columns={[
            {
              title: "種類",
              dataIndex: "key",
            },
            {
              title: "内容",
              dataIndex: "text",
            },
          ]}
          className="mt-4 ant-table-cell center"
        />
        <div className="mt-4">
          詳細は以下の中央労働災害防止協会ホームページ記載の
          <br />
          作業環境測定法施行規則 別表 作業場の種類を確認してください。
        </div>
        <Typography.Link
          href="https://www.jaish.gr.jp/horei/hor1-3/hor1-3-6-m-2.html"
          target="_blank"
        >
          https://www.jaish.gr.jp/horei/hor1-3/hor1-3-6-m-2.html
        </Typography.Link>
      </ModalConfirm>
    </div>
  );
};
export default memo(MeasuringPersonInformations);
