// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  useState,
  useEffect,
  memo,
  useRef,
  useMemo,
  useLayoutEffect,
} from "react";

import { Line } from "@ant-design/plots";
import dayjs from "dayjs";
import { get } from "lodash";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { ExclusionTemplate } from "@app/features/filters/filters";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { getValueClamp } from "@app/helpers/util.helper";

import { dataModalDisplaySettingsType } from "../../Screens/TgraphMeasurersScreen/TgraphMeasurersScreen";
import { getScalevalueTgraph } from "../../helpers/measurement-results.helpers";
import {
  DATE_TIME_FORMAT,
  MAX_WIDTH_TGRAPH,
  MeasurementResultsDetail,
  MEASUREMENTS_RESULTS_SCALE,
  X_AXIS_SCALE,
  TgraphData,
  TIME_FORMAT,
  TIME_FORMAT_NEXT_DAY,
  TIME_3_MINUTES,
} from "../../measurement-results";

interface TgraphContainerProps {
  measurementResultsDetail: MeasurementResultsDetail | null;
  dataModalDisplaySettings: dataModalDisplaySettingsType;
  timeAxis: {
    startTimeLimit?: string;
    endTimeLimit?: string;
  };
  triggerDownload: number;
  dataModalExclusionSettings?: ExclusionTemplate | null;
}

const TgraphContainer = ({
  measurementResultsDetail,
  dataModalDisplaySettings,
  timeAxis,
  triggerDownload,
  dataModalExclusionSettings,
}: TgraphContainerProps) => {
  const { listId } = getQueryParams(window.location.search);

  const [dataTgraph, setDataTgraph] = useState<Record<string, string>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tickCount, setTickCount] = useState<number>(0);
  const [annotations, setAnnotations] = useState<{
    Over: string[];
    Under: string[];
    Impact: string[];
  } | null>(null);

  const chartRef = useRef(null);
  const boxTgraphRef = useRef(null);

  useEffect(() => {
    if (triggerDownload && chartRef?.current) {
      const nameDownload = `${
        measurementResultsDetail?.measurement_result?.device_model ?? "_"
      }_${
        measurementResultsDetail?.measurement_result?.device_serial_no ?? "_"
      }_${measurementResultsDetail?.measurement_result?.start_time ?? "_"}.png`;
      chartRef.current.downloadImage(nameDownload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDownload]);

  const scaleValue = useMemo(() => {
    const timeDiff = dayjs(dataModalDisplaySettings.timeEnd).diff(
      dataModalDisplaySettings.timeStart,
      "minute"
    );
    const scale = getScalevalueTgraph(timeDiff);
    return {
      key: dataModalDisplaySettings.isScale
        ? X_AXIS_SCALE[dataModalDisplaySettings?.XAxisScale].key
        : scale.key,
      value: dataModalDisplaySettings.isScale
        ? X_AXIS_SCALE[dataModalDisplaySettings?.XAxisScale].value
        : scale.value,
    };
  }, [
    dataModalDisplaySettings?.XAxisScale,
    dataModalDisplaySettings.isScale,
    dataModalDisplaySettings.timeEnd,
    dataModalDisplaySettings.timeStart,
  ]);

  const getCompensationTgraph = (count: number, isStart = false) => {
    return new Array(count).fill(1).map((_, index) => {
      return {
        address: null,
        start_time: dayjs(
          isStart
            ? timeAxis?.startTimeLimit
            : measurementResultsDetail?.measurement_result?.end_time
        )
          .add(index * scaleValue.value, scaleValue.key)
          .format(DATE_TIME_FORMAT),
        laeq: 0,
        lceq: 0,
        lcpeak: 0,
        dose: null,
        over: null,
        under: null,
        impact: null,
        lae: null,
        lce: null,
      };
    });
  };

  const getValueBetweenTimeLimit = (value: number, time: string) => {
    if (!value) {
      return null;
    }
    if (
      dataModalDisplaySettings?.timeStart &&
      dataModalDisplaySettings?.timeEnd
    ) {
      return dayjs(dayjs(time).format(TIME_FORMAT)).isBetween(
        dataModalDisplaySettings.timeStart,
        dataModalDisplaySettings.timeEnd,
        "second",
        "[]"
      ) ||
        dayjs(dayjs(time).format(TIME_FORMAT_NEXT_DAY)).isBetween(
          dataModalDisplaySettings.timeStart,
          dataModalDisplaySettings.timeEnd,
          "second",
          "[]"
        )
        ? value
        : null;
    }
    return value;
  };

  const convertDataTgraph = (tgraphValue: TgraphData[]) => {
    const graphData = [];

    if (!tgraphValue?.length || !measurementResultsDetail) return [];

    const countCompensationStart = Math.round(
      dayjs(
        dayjs(measurementResultsDetail?.measurement_result?.start_time)?.format(
          TIME_FORMAT
        )
      ).diff(
        dayjs(timeAxis?.startTimeLimit)?.format(TIME_FORMAT),
        scaleValue.key
      ) / scaleValue.value
    );

    const countCompensationEnd = Math.round(
      dayjs(dayjs(timeAxis?.endTimeLimit)?.format(TIME_FORMAT)).diff(
        dayjs(measurementResultsDetail?.measurement_result?.end_time)?.format(
          TIME_FORMAT
        ),
        scaleValue.key
      ) / scaleValue.value
    );

    const newTgraphValue = [
      ...getCompensationTgraph(Math.max(countCompensationStart, 0), true),
      ...tgraphValue,
      ...getCompensationTgraph(Math.max(countCompensationEnd, 0)),
    ]?.filter(item => !!getValueBetweenTimeLimit(1, item.start_time));

    newTgraphValue?.forEach(row => {
      const { laeq, lceq, lcpeak, start_time } = row;
      graphData.push({
        start_time,
        value: getValueBetweenTimeLimit(Number(laeq), start_time),
        category: "LAeq",
      });
      graphData.push({
        start_time,
        value: getValueBetweenTimeLimit(Number(lceq), start_time),
        category: "LCeq",
      });
      graphData.push({
        start_time,
        value: getValueBetweenTimeLimit(Number(lcpeak), start_time),
        category: "LCpeak",
      });
    });

    setTickCount(newTgraphValue.length);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return graphData;
  };

  const getAnnotations = (result: TgraphData[]) => {
    const listTime = {
      Over: [],
      Under: [],
      Impact: [],
    };
    if (!result.length) return;
    result?.forEach(re => {
      if (Number(re.over) && !!getValueBetweenTimeLimit(1, re.start_time)) {
        listTime.Over.push(re.start_time);
      }
      if (Number(re.under) && !!getValueBetweenTimeLimit(1, re.start_time)) {
        listTime.Under.push(re.start_time);
      }
      if (Number(re.impact) && !!getValueBetweenTimeLimit(1, re.start_time)) {
        listTime.Impact.push(re.start_time);
      }
    });

    const mergeTime = list => {
      let index = 0;
      if (!list.length) return [];
      const array = [];
      list?.reduce((a, b) => {
        if (!array[index]) {
          array[index] = [];
        }
        if (dayjs(b).diff(a, scaleValue.key) === scaleValue.value) {
          if (!array[index]?.includes(a)) {
            array[index]?.push(a);
          }
          array[index]?.push(b);
        } else {
          index += 1;
        }
        return b;
      });
      return array;
    };

    const overFlagAnnotations = mergeTime(listTime.Over);
    const underFlagAnnotations = mergeTime(listTime.Under);
    const impactFlagAnnotations = mergeTime(listTime.Impact);

    const convertListArray = listArr => {
      return listArr
        ?.filter(item => item.length > 1)
        ?.map(item => {
          return [item[0], item.at(-1)];
        });
    };
    setAnnotations({
      Over: convertListArray(overFlagAnnotations),
      Under: convertListArray(underFlagAnnotations),
      Impact: convertListArray(impactFlagAnnotations),
    });
  };

  const getExclusionAnnotations = (
    startTime,
    endTime,
    isFullHeight = false
  ) => {
    return {
      type: "region",
      start: xScale => {
        const index = xScale.values?.findIndex(time =>
          dayjs(time).isSameOrAfter(startTime)
        );
        return [
          index !== -1 ? index : undefined,
          isFullHeight
            ? "min"
            : getValueClamp(
                dataModalExclusionSettings?.under_limit,
                dataModalDisplaySettings.yAxisRangeMin,
                dataModalDisplaySettings.yAxisRangeMax
              ) ?? "min",
        ];
      },
      end: xScale => {
        const index = xScale.values?.findIndex(time =>
          dayjs(time).isSameOrAfter(endTime)
        );
        return [
          index !== -1 ? index : undefined,
          isFullHeight
            ? "max"
            : getValueClamp(
                dataModalExclusionSettings?.upper_limit,
                dataModalDisplaySettings.yAxisRangeMin,
                dataModalDisplaySettings.yAxisRangeMax
              ) ?? "max",
        ];
      },
      style: {
        fill: "p(a)data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADpJREFUKFONzEsKADAIA9Hk/oe2WGjpR6OzfgzRy9hwBoAVnMhnCm6k4IUy+KEIhuiFKTqhRAuWyOEA/DwKCnfY+F8AAAAASUVORK5CYII=",
        fillOpacity: 1,
      },
    };
  };

  const listAnnotations = useMemo(() => {
    const arr = [];

    if (!dataTgraph.length) return [];

    if (
      dataModalExclusionSettings?.valid_start_time &&
      dayjs(dataModalExclusionSettings.valid_start_time).isBetween(
        measurementResultsDetail?.measurement_result?.start_time,
        measurementResultsDetail?.measurement_result?.end_time,
        "second",
        "[]"
      )
    ) {
      arr.push(
        getExclusionAnnotations(
          dataTgraph?.[0]?.start_time,
          dayjs(dataModalExclusionSettings.valid_start_time)?.format(
            "YYYY-MM-DD HH:mm:00"
          )
        )
      );
    }

    if (
      dataModalExclusionSettings?.valid_end_time &&
      dayjs(dataModalExclusionSettings.valid_end_time).isBetween(
        measurementResultsDetail?.measurement_result?.start_time,
        measurementResultsDetail?.measurement_result?.end_time,
        "second",
        "[]"
      )
    ) {
      arr.push(
        getExclusionAnnotations(
          dayjs(dataModalExclusionSettings.valid_end_time)?.format(
            "YYYY-MM-DD HH:mm:00"
          ),
          dataTgraph?.at(-1)?.start_time
        )
      );
    }

    dataModalExclusionSettings?.filter_periods?.forEach(item => {
      arr.push(getExclusionAnnotations(item.start_time, item.end_time));
    });

    annotations?.Impact?.forEach(i => {
      arr.push({
        type: "region",
        start: [i[0], "min"],
        end: [i.at(-1), "max"],
        style: {
          fill: "#F6AA00",
          fillOpacity: 0.35,
          stroke: "#F6AA00",
          strokeOpacity: 1,
        },
      });
      if (dataModalExclusionSettings?.impact_flag) {
        arr.push(getExclusionAnnotations(i[0], i.at(-1), true));
      }
    });
    annotations?.Over?.forEach(o => {
      arr.push({
        type: "region",
        start: [o[0], "min"],
        end: [o.at(-1), "max"],
        style: {
          fill: "#EB4B00",
          fillOpacity: 0.25,
          stroke: "#F6AA00",
          strokeOpacity: 1,
        },
      });
      if (dataModalExclusionSettings?.over_flag) {
        arr.push(getExclusionAnnotations(o[0], o.at(-1), true));
      }
    });
    annotations?.Under?.forEach(u => {
      arr.push({
        type: "region",
        start: [u[0], "min"],
        end: [u.at(-1), "max"],
        style: {
          fill: "#4DC4FF",
          fillOpacity: 0.35,
          stroke: "#F6AA00",
          strokeOpacity: 1,
        },
      });
      if (dataModalExclusionSettings?.under_flag) {
        arr.push(getExclusionAnnotations(u[0], u.at(-1), true));
      }
    });
    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    annotations,
    dataTgraph,
    dataModalExclusionSettings,
    dataModalDisplaySettings,
  ]);

  const getDataGraph3Minute = () => {
    const timeScale = TIME_3_MINUTES;
    const tc = measurementResultsDetail?.measurement_result?.tc ?? 0;
    const lc = measurementResultsDetail?.measurement_result?.lc ?? 0;
    let data1s = measurementResultsDetail?.data_1_second ?? [];
    const indexTime = data1s?.findIndex(
      data =>
        dayjs(data.start_time).format(DATE_TIME_FORMAT) ===
        dayjs(dataModalDisplaySettings.timeStart).format("YYYY/MM/DD HH:mm:00")
    );
    if (indexTime !== -1) {
      data1s = data1s?.slice(indexTime);
    }
    if (data1s?.length) {
      const dataBySeconds = [];
      let laeq;
      let lae;
      let lceq;
      let lcpeakAvg;
      let overAvg;
      let underAvg;
      let impactAvg;
      let lce;
      let time;
      let tgraphValue = {};
      data1s.forEach((dataResult, index) => {
        if (index % second === 0) {
          laeq = 10 ** (Number(dataResult.laeq) / 10);
          lae = Number(dataResult.laeq);
          lceq = 10 ** (Number(dataResult.lceq) / 10);
          lce = Number(dataResult.lceq);
          lcpeakAvg = Number(dataResult.lcpeak);
          overAvg = Number(dataResult.over);
          underAvg = Number(dataResult.under);
          impactAvg = Number(dataResult.impact);
          tgraphValue = dataResult;
        }
        if (index % timeScale !== 0) {
          lae =
            10 *
            Math.log10(10 ** (lae / 10) + 10 ** (Number(dataResult.laeq) / 10));
          lce =
            10 *
            Math.log10(10 ** (lce / 10) + 10 ** (Number(dataResult.lceq) / 10));
          laeq += 10 ** (Number(dataResult.laeq) / 10);
          lceq += 10 ** (Number(dataResult.lceq) / 10);
          lcpeakAvg = Math.max(lcpeakAvg, Number(dataResult.lcpeak));
          overAvg = Math.max(overAvg || Number(dataResult.over));
          underAvg = Math.max(underAvg || Number(dataResult.under));
          impactAvg = Math.max(impactAvg || Number(dataResult.impact));
        }
        if (
          index % timeScale === timeScale - 1 ||
          index === data1s.length - 2
        ) {
          time = (index % timeScale) + 1;
          const tgraphValueclone = {
            ...tgraphValue,
            address: Math.floor(index / timeScale + 1),
            measurement_time: `0:03:00`,
            laeq: (10 * Math.log10(laeq / time)).toFixed(2),
            lceq: (10 * Math.log10(lceq / time)).toFixed(2),
            lcpeak: lcpeakAvg.toFixed(2),
            over: overAvg,
            under: underAvg,
            impact: impactAvg,
            dose: (
              100 *
              (10 ** (lae / 10) / (tc * 3600 * 10 ** (lc / 10)))
            ).toFixed(2),
            lae: lae.toFixed(2),
            lce: lce.toFixed(2),
          };
          dataBySeconds.push(tgraphValueclone);
        }
      });
      return dataBySeconds;
    }
    return [];
  };

  const getDataByTimeRange = () => {
    const timeDiff = dayjs(dataModalDisplaySettings.timeEnd).diff(
      dataModalDisplaySettings.timeStart,
      "minute"
    );
    if (timeDiff >= 5 * 60 + 30) return getDataGraph3Minute();
    if (timeDiff >= 60 + 30 && timeDiff < 5 * 60 + 30)
      return measurementResultsDetail?.data_60_second;
    return measurementResultsDetail?.data_30_second;
  };

  useEffect(() => {
    const tgraphValue = dataModalDisplaySettings.isScale
      ? get(
          measurementResultsDetail,
          `${MEASUREMENTS_RESULTS_SCALE[dataModalDisplaySettings.XAxisScale]}`
        )
      : getDataByTimeRange();
    if (tgraphValue?.length) {
      getAnnotations(tgraphValue);
      setDataTgraph(convertDataTgraph(tgraphValue));
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModalDisplaySettings]);

  useLayoutEffect(() => {
    setIsLoading(true);
  }, [dataModalDisplaySettings]);

  const config = {
    height: 240,
    xField: "start_time",
    yField: "value",
    seriesField: "category",
    xAxis: {
      label: {
        formatter: (v: string) =>
          dayjs(v)?.format(listId?.length < 2 ? DATE_TIME_FORMAT : "HH:mm:ss"),
      },
      tickLine: {
        style: {
          lineWidth: 2,
          stroke: "rgba(0, 0, 0, 0.12)",
        },
        length: 5,
      },
      grid: {
        line: {
          style: {
            stroke: "rgba(0,0,0,0.12)",
          },
        },
      },
    },
    yAxis: {
      min: dataModalDisplaySettings.yAxisRangeMin,
      max: dataModalDisplaySettings.yAxisRangeMax,
      tickLine: {
        style: {
          lineWidth: 1,
          stroke: "rgba(0,0,0,0.12)",
        },
        length: 8,
      },
      grid: {
        line: {
          style: {
            stroke: "rgba(0,0,0,0.12)",
          },
        },
      },
      nice: false,
      tickCount: 5,
    },
    color: ["#03AF7A", "#804000", "#005AFF"],
    annotations: listAnnotations,
    legend: false,
    data: dataTgraph,
    autoFit: false,
    smooth: true,
    width: getValueClamp(
      tickCount * 9.5 + 120,
      boxTgraphRef?.current?.offsetWidth - 10,
      dataModalDisplaySettings.isScale
        ? MAX_WIDTH_TGRAPH
        : boxTgraphRef?.current?.offsetWidth - 10
    ),
    padding: [10, 40, 25, 55],
    renderer: "svg",
  };

  const configFixedYAxis = {
    ...config,
    data: [],
    width: 66,
    padding: [10, 0, 25, 55],
  };

  return (
    <>
      {(isLoading || !measurementResultsDetail) && (
        <LoadingSpinner isFitContent />
      )}
      <div ref={boxTgraphRef} key={tickCount} className="boxTgraph">
        {!!dataTgraph?.length && (
          <>
            <Line
              {...config}
              onReady={() => {
                setIsLoading(false);
              }}
            />
            <div className="fixedYAxis">
              <Line {...configFixedYAxis} />
            </div>
            <Line
              {...config}
              style={{ display: "none" }}
              renderer="canvas"
              onReady={plot => {
                chartRef.current = plot;
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default memo(TgraphContainer);
