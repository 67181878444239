import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MEASURERS_FEATURE_KEY } from "@app/features/measurers/measurers";

import measurersApi from "../api/measurers.api";
import {
  CreateMeasureDataDef,
  MeasurementResult,
  Measurer,
} from "../types/measurers.types";

interface initialStateType {
  listMeasurers: Measurer[];
  measurementResults: MeasurementResult[];
  simplyMeasurementResults: MeasurementResult[];
  listMeasurersDetails: Measurer[];
}

const initialState: initialStateType = {
  listMeasurers: [],
  measurementResults: [],
  simplyMeasurementResults: [],
  listMeasurersDetails: [],
};

// API-MEASURER-020
export const getMeasurers = createAsyncThunk(
  `${MEASURERS_FEATURE_KEY}/getMeasurers`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await measurersApi.getMeasurersApi();
      return response.data.measurers;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMeasurementResults = createAsyncThunk(
  `${MEASURERS_FEATURE_KEY}/getMeasurementResults`,
  async (
    params: {
      report_id?: string;
      workplace_id?: string;
      date_from?: string;
      date_to?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await measurersApi.getMeasurementResultsApi(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMeasurersDetails = createAsyncThunk(
  `${MEASURERS_FEATURE_KEY}/getMeasurersDetails`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await measurersApi.getMeasurersDetailsApi(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createMeasurer = createAsyncThunk(
  `${MEASURERS_FEATURE_KEY}/createMeasurer`,
  async (data: CreateMeasureDataDef, { rejectWithValue }) => {
    try {
      const response = await measurersApi.createMeasurerApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editMeasurer = createAsyncThunk(
  `${MEASURERS_FEATURE_KEY}/editMeasurer`,
  async (
    { id, data }: { id: string; data: CreateMeasureDataDef },
    { rejectWithValue }
  ) => {
    try {
      const response = await measurersApi.editMeasurerApi({ id, data });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const measurersSlice = createSlice({
  name: MEASURERS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMeasurers.pending, state => {
      state.listMeasurers = [];
    });
    builder.addCase(getMeasurers.fulfilled, (state, actions) => {
      state.listMeasurers = actions.payload;
    });
    builder.addCase(getMeasurers.rejected, state => {
      state.listMeasurers = [];
    });
    builder.addCase(getMeasurersDetails.pending, state => {
      state.listMeasurersDetails = [];
    });
    builder.addCase(getMeasurersDetails.fulfilled, (state, actions) => {
      state.listMeasurersDetails = actions.payload;
    });

    builder.addCase(getMeasurementResults.pending, state => {
      state.measurementResults = [];
      state.simplyMeasurementResults = [];
    });
    builder.addCase(getMeasurementResults.fulfilled, (state, actions) => {
      const { measurement_results, simply_measurement_results } =
        actions.payload;
      state.measurementResults = measurement_results;
      state.simplyMeasurementResults = simply_measurement_results;
    });
    builder.addCase(getMeasurementResults.rejected, state => {
      state.measurementResults = [];
      state.simplyMeasurementResults = [];
    });
  },
});

export const measurersReducer = measurersSlice.reducer;
