import { delay } from "./util.helper";
/**
 * Function to download a file.
 *
 * Adds `a` html element with link to file,
 * and triggers a click to download the file,
 * and finally removes the element from the DOM
 * @param url Path to the file
 * @param filename Name of the file
 */
export const download = async (url: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = url;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();

  // Chrome requires the timeout
  await delay(100);
  document.body.removeChild(link);
};

/**
 * Function to initiate download of multiple files
 * @param files Array of path to files
 */
export const downloadMultipleFiles = (files: string[]) => {
  files.forEach(async (file, index) => {
    const filename = file.substring(file.lastIndexOf("/") + 1);
    await delay(index * 1000);
    download(file, filename);
  });
};

/**
 * Convert blob file to csv file, and initiates a download of the file
 * @param blobData Data from api
 * @param fileName Name of file
 */
export const processBlobToCSV = (blobData: string, fileName: string) => {
  const contentType = "text/csv";
  const blob = new Blob([blobData], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const filename = `${fileName}.csv`;
  download(url, filename);
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getBase64Image = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.onerror = () => {
      return reject();
    };
    reader.readAsDataURL(blob);
  });
};

export const convertJapaneseCalendar = (
  date: string | null | undefined,
  getYear = false
) => {
  if (!date) return "-";
  const options: Intl.DateTimeFormatOptions = getYear
    ? {
        year: "2-digit",
      }
    : {
        era: "narrow",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      };
  return new Intl.DateTimeFormat("ja-JP-u-ca-japanese", options).format(
    new Date(date)
  );
};

export const CSVToJSON = (csv: string) => {
  const lines = csv.split("\n");
  const keys = lines[0].split(",");
  return lines.slice(1).map(line => {
    return line.split(",").reduce((acc, cur, i) => {
      const toAdd: Record<string, string> = {};
      toAdd[keys[i]] = cur;
      return { ...acc, ...toAdd };
    }, {});
  });
};
