/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useMemo, useRef, useState } from "react";

import { useMouse } from "ahooks";
import { FormInstance, Modal } from "antd";
import { useWatch } from "antd/lib/form/Form";
import cx from "classnames";
import { KonvaEventObject } from "konva/lib/Node";
import { cloneDeep, isEqualWith } from "lodash";
import {
  Layer,
  Image as ImageKonva,
  Group,
  Text,
  Circle,
  Rect,
  Stage,
} from "react-konva";
import useImage from "use-image";

import Button from "@app/components/atoms/Button/Button";
import HintTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import { MessageError } from "@app/constants/messages.constants";
import {
  MeasurementDesign,
  MeasurementResult,
} from "@app/features/measurers/measurers";
import {
  CANVAS_HEIGHT_ROOT,
  MAX_POINT_CANVAS,
  MAX_COUNT_RECTANGLES,
  RECTANGLES_TYPE,
  MAX_COUNT_ZOOM,
  SCALE_ZOOM,
  CANVAS_WIDTH_ROOT,
  TYPE_POINT,
  RECTANGLES_TYPE_BY_COLOR,
  updateIsSortPlotLocations,
  LIST_PLOT_LOCATIONS,
  updateUrlImageCanvasUpload,
  updateIsEditCanvas,
  MAX_SIZE_UPLOAD,
} from "@app/features/reports/reports";
import { dataURLtoFile, getBase64Image } from "@app/helpers/file.helper";
import { delay } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./CanvasDesignMeasuringPoint.module.scss";
import DeletePointDialog from "./DeletePointDialog/DeletePointDialog";
import HintTextCanvas from "./HintTextCanvas/HintTextCanvas";
import TransformerMeasurers from "./TransformerMeasurers/TransformerMeasurers";

interface CanvasDesignMeasuringPointProps {
  isOpenCanvas: boolean;
  handleCloseCanvas: () => void;
  setUrlImage: (data: string) => void;
  setListPlot: (data: Record<string, number>) => void;
  fileNameDownload: string;
  form: FormInstance;
}

interface typeRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
  stroke: string;
  strokeWidth: number;
  dash: number[];
  id: string;
  type: number;
  scaleX: number;
  scaleY: number;
  rotation: number;
}

interface pointType {
  text: string;
  color: string;
  list: {
    id?: number;
    x: number;
    y: number;
    order: number;
    measurement_result?: MeasurementResult[];
  }[];
}

const CanvasDesignMeasuringPoint = ({
  isOpenCanvas,
  setUrlImage,
  handleCloseCanvas,
  setListPlot,
  fileNameDownload,
  form,
}: CanvasDesignMeasuringPointProps) => {
  const [fileUpload, setFileUpload] = useState<string>("");
  const [image] = useImage(fileUpload);
  const stageRef = useRef<any>();
  const imageRef = useRef<any>();
  const mainRef = useRef(null);
  const layerRef = useRef<any>(null);

  const mouse = useMouse(mainRef.current);

  const { isSortPlotLocations } = useAppSelector(
    (state: RootState) => state.reports
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [rotateValue, setRotateValue] = useState<number>(0);
  const [zoomCount, setZoomCount] = useState<number>(0);
  const [boxPosition, setBoxPosition] = useState<{
    offsetX: number;
    offsetY: number;
  }>({ offsetX: 0, offsetY: 0 });
  const [selectedPoint, setSelectedPoint] = useState<{
    circleIndex: number;
    index: number;
    linked?: boolean;
  } | null>(null);
  const [ratio, setRatio] = useState<number>(1);

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [triggerSortPlotLocations, setTriggerSortPlotLocations] =
    useState<boolean>(false);

  const listPlotLocationsInForm = useWatch([
    "workplace",
    "measurement_design",
    "design_plot_locations",
  ]);

  const measurementDesignData: MeasurementDesign = useWatch([
    "workplace",
    "measurement_design",
  ]);

  const workplaceId = useWatch(["workplace", "id"]);

  const [scale, setScale] = useState(1);

  const dispatch = useAppDispatch();

  const mappingDataPlotLocations = () => {
    const newList: pointType[] = cloneDeep(LIST_PLOT_LOCATIONS);
    const initialListPlotLocations =
      measurementDesignData?.design_plot_locations;
    if (!initialListPlotLocations) return newList;
    initialListPlotLocations.forEach(item => {
      newList[item.icon_type].list.push({
        ...item,
        x: Number(item.x_pos),
        y: Number(item.y_pos),
      });
    });
    return newList;
  };

  const mappingDataDesignMovementRangeFrames = () => {
    const initialDesignMovementRangeFrames =
      measurementDesignData?.design_movement_range_frames;
    if (!initialDesignMovementRangeFrames) return [];
    return initialDesignMovementRangeFrames.map(item => {
      return {
        x: Number(item.x_pos),
        y: Number(item.y_pos),
        width: 100,
        height: 100,
        radius: 50,
        stroke: item.color,
        strokeWidth: 4,
        type: RECTANGLES_TYPE_BY_COLOR[item.color as "#ac0000" | "#78a100"],
        dash: [20, 20],
        id: (Math.random() * 10000).toFixed(0),
        scaleX: Number(item.horizontal_radius),
        scaleY: Number(item.vertical_radius),
        rotation: Number(item.slope),
      };
    });
  };

  const [rectangles, setRectangles] = useState<typeRectangle[]>(
    mappingDataDesignMovementRangeFrames()
  );

  const [listMeasuringPoints, setListMeasuringPoints] = useState<pointType[]>(
    mappingDataPlotLocations()
  );

  const circleName = useRef("");
  const stageSizeRoot = useRef<{
    stageWidth: number;
    stageHeight: number;
    imageWidth: number;
    imageHeight: number;
  } | null>(null);

  const [stageSize, setStageSize] = useState<{
    stageWidth: number;
    stageHeight: number;
    imageWidth: number;
    imageHeight: number;
  }>({
    stageWidth: 0,
    stageHeight: 0,
    imageWidth: 0,
    imageHeight: 0,
  });

  const { isEditCanvas, isShowHintText } = useMemo(() => {
    const list = [...listMeasuringPoints].map(item => item.list).flat(1);
    return {
      isEditCanvas:
        zoomCount !== 0 || !!rectangles.length || !!list.length || !image,
      isShowHintText: !(
        rotateValue !== 0 ||
        !!rectangles.length ||
        !!list.length ||
        !image
      ),
    };
  }, [image, listMeasuringPoints, rectangles.length, rotateValue, zoomCount]);

  const readFile = (file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader?.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleUploadImage = async (files: FileList | null) => {
    const file = files ? files[0] : null;
    if (!file) {
      return null;
    }
    if (/^(.(?!.*.(jpg|png|jpeg)))*$/.test(file.name.toLocaleLowerCase())) {
      return null;
    }
    const imgUrl = await readFile(file); // base64
    if (file.size > MAX_SIZE_UPLOAD) {
      return null;
    }
    return setFileUpload(imgUrl as string);
  };

  const handleZoom = (stage: any, increaseScale: boolean) => {
    if (!stage || !stageSizeRoot?.current) return;
    const oldScale = stage.scaleX();
    const newScale = increaseScale
      ? oldScale - SCALE_ZOOM
      : oldScale + SCALE_ZOOM;
    if (newScale < 1 || newScale > 2) return;
    const { stageWidth, stageHeight } = stageSizeRoot.current;
    stage.to({
      x: 0,
      y: 0,
      scaleX: newScale,
      scaleY: newScale,
      duration: 0.1,
    });
    setStageSize({
      ...stageSize,
      stageWidth: stageWidth * newScale,
      stageHeight: stageHeight * newScale,
    });
    setScale(newScale);
  };

  const handleDownloadURI = (uri: string, name: string) => {
    if (uri) {
      const link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSave = async () => {
    if (stageRef?.current) {
      setSelectedId(null);
      setTimeout(() => {
        const uri = stageRef.current.toDataURL({
          mimeType: "image/png",
          quality: 1.0,
        });
        handleDownloadURI(uri, fileNameDownload);
      }, 100);
    }
  };

  const convertDataCanvas = () => {
    const designPlotLocation = listMeasuringPoints
      .map((item, index) => {
        const typePoint = () => {
          switch (index) {
            case 1:
            case 2:
              return TYPE_POINT.SOONKEI;
            case 3:
              return TYPE_POINT.BAKURO;
            default:
              return TYPE_POINT.REST;
          }
        };
        return item.list?.map(x => {
          return {
            id: x.id ?? (Math.random() * 100000000).toFixed(),
            order: x.order,
            type: typePoint(),
            icon_type: index,
            x_pos: x.x.toFixed(5),
            y_pos: x.y.toFixed(5),
            measurement_result: x.measurement_result ?? [],
          };
        });
      })
      .flat(1);

    const designMovementRangeFrames = rectangles?.map((item, index) => {
      return {
        id: item.id,
        order: index + 1,
        color: item.stroke,
        x_pos: item.x.toFixed(5),
        y_pos: item.y.toFixed(5),
        horizontal_radius: item.scaleX?.toFixed(5),
        vertical_radius: item.scaleY?.toFixed(5),
        slope: item.rotation?.toFixed(5),
      };
    });

    return {
      design_plot_locations: designPlotLocation,
      design_movement_range_frames: designMovementRangeFrames,
    };
  };

  const handleSubmit = async () => {
    if (!stageRef?.current || !stageSizeRoot.current) {
      return handleCloseCanvas();
    }
    setIsSubmitting(true);
    stageRef.current.to({
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
      duration: 0.1,
    });
    setStageSize({
      ...stageSize,
      stageWidth: stageSizeRoot.current.stageWidth,
      stageHeight: stageSizeRoot.current.stageHeight,
    });
    setScale(1);
    setZoomCount(0);
    setSelectedId(null);

    await delay(100);

    const urlImageCanvas = stageRef.current.toDataURL({
      mimeType: "image/png",
      quality: 1.0,
    });

    const isEdit = !isEqualWith(
      {
        design_movement_range_frames:
          measurementDesignData?.design_movement_range_frames,
        design_plot_locations: measurementDesignData?.design_plot_locations,
      },
      convertDataCanvas(),
      (val1, val2, key) => {
        return key === "measurement_result" ? true : undefined;
      }
    );

    dispatch(updateIsEditCanvas(isEdit));

    dispatch(
      updateUrlImageCanvasUpload({
        imageCanvas: dataURLtoFile(urlImageCanvas, fileNameDownload),
        imageBackground: dataURLtoFile(fileUpload, fileNameDownload),
      })
    );

    form.setFieldsValue({
      workplace: {
        measurement_design: {
          ...convertDataCanvas(),
        },
      },
    });

    setUrlImage(urlImageCanvas);
    handleCloseCanvas();
    setIsSubmitting(false);
    setTriggerSortPlotLocations(false);
    dispatch(updateIsSortPlotLocations(false));

    return null;
  };

  const getSize = (rotation: number, ratioValue: number = ratio) => {
    let newWidth;
    let newHeight;
    if (rotation === 0 || rotation === 180) {
      newWidth = CANVAS_HEIGHT_ROOT / ratioValue;
      newHeight = CANVAS_WIDTH_ROOT * ratioValue;
      return {
        stageWidth: newWidth > CANVAS_WIDTH_ROOT ? CANVAS_WIDTH_ROOT : newWidth,
        stageHeight:
          newWidth > CANVAS_WIDTH_ROOT ? newHeight : CANVAS_HEIGHT_ROOT,
        imageHeight:
          newWidth > CANVAS_WIDTH_ROOT ? CANVAS_WIDTH_ROOT : newWidth,
        imageWidth:
          newWidth > CANVAS_WIDTH_ROOT ? newHeight : CANVAS_HEIGHT_ROOT,
      };
    }
    newWidth = ratioValue * CANVAS_HEIGHT_ROOT;
    newHeight = CANVAS_WIDTH_ROOT / ratioValue;
    return {
      stageWidth: newWidth > CANVAS_WIDTH_ROOT ? CANVAS_WIDTH_ROOT : newWidth,
      stageHeight:
        newWidth > CANVAS_WIDTH_ROOT ? newHeight : CANVAS_HEIGHT_ROOT,
      imageWidth: newWidth > CANVAS_WIDTH_ROOT ? CANVAS_WIDTH_ROOT : newWidth,
      imageHeight:
        newWidth > CANVAS_WIDTH_ROOT ? newHeight : CANVAS_HEIGHT_ROOT,
    };
  };

  const handleRotate = () => {
    if (imageRef.current && stageSizeRoot?.current) {
      setZoomCount(0);
      setStageSize(getSize(rotateValue));
      stageSizeRoot.current = getSize(rotateValue);
    }
    if (rotateValue === 270) {
      setRotateValue(0);
    } else {
      setRotateValue(rotateValue + 90);
    }
  };

  const handleDropPoint = (e: React.DragEvent<HTMLDivElement>) => {
    stageRef.current.setPointersPositions(e);
    const plot = stageRef.current.getPointerPosition();
    if (
      plot.x < 0 ||
      plot.y < 0 ||
      plot.x > stageSize.stageWidth ||
      plot.y > stageSize.stageHeight
    )
      return;
    const find = [...listMeasuringPoints].map(item => {
      if (item.text === circleName.current) {
        const maxPoint = MAX_POINT_CANVAS[item.text];
        if (item.list.length < maxPoint) {
          const lastPoint = item.list[item.list.length - 1];
          const order = lastPoint?.order ?? 0;
          if (order < maxPoint) {
            item.list.push({
              x: plot.x / scale,
              y: plot.y / scale,
              order: order + 1,
            });
          } else {
            [...new Array(maxPoint)].every((_, index) => {
              const pointFind = item.list?.find(
                (x: { order: number }) => x.order === index + 1
              );
              if (!pointFind) {
                item.list.splice(index, 0, {
                  x: plot.x / scale,
                  y: plot.y / scale,
                  order: index + 1,
                });
                return false;
              }
              return true;
            });
          }
        }
        return item;
      }
      return item;
    });
    setListMeasuringPoints(find);
  };

  const handleInsertRectangle = (type: number) => {
    if (image) {
      const rects = rectangles.filter(x => x.type === type);
      const maxLength =
        type === RECTANGLES_TYPE.RED
          ? MAX_COUNT_RECTANGLES.RED
          : MAX_COUNT_RECTANGLES.GREEN;
      if (rects.length < maxLength) {
        setRectangles([
          ...rectangles,
          {
            x: 200,
            y: 100,
            width: 100,
            height: 100,
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            radius: 50,
            stroke: type === RECTANGLES_TYPE.RED ? "#ac0000" : "#78a100",
            strokeWidth: 4,
            dash: [20, 20],
            id: (Math.random() * 10000).toFixed(0),
            type,
          },
        ]);
      }
    }
  };

  const checkDeselect = (e: KonvaEventObject<MouseEvent>) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  // update measurement_result
  useEffect(() => {
    if (listPlotLocationsInForm) {
      const newList: pointType[] = cloneDeep(LIST_PLOT_LOCATIONS);
      listPlotLocationsInForm.forEach((item: any) => {
        newList[item.icon_type].list.push({
          ...item,
          x: Number(item.x_pos),
          y: Number(item.y_pos),
        });
      });
      setListMeasuringPoints(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPlotLocationsInForm]);

  useEffect(() => {
    if (image) {
      const ratioImg = image.naturalWidth / image.naturalHeight;
      setRatio(ratioImg);
      stageSizeRoot.current = getSize(90, ratioImg);
      setStageSize(stageSizeRoot.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    setListPlot({
      A: listMeasuringPoints[1]?.list.length,
      B: listMeasuringPoints[2]?.list.length,
      ば: listMeasuringPoints[3]?.list.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMeasuringPoints]);

  useEffect(() => {
    const urlBackground = measurementDesignData?.background_sketch_filename;
    if (urlBackground) {
      getBase64Image(urlBackground).then(data => {
        setFileUpload(data as string);
      });
    } else {
      setFileUpload(fileUpload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplaceId]);

  // sort order plot locations
  useEffect(() => {
    if (isSortPlotLocations) {
      const newPlot = [...listMeasuringPoints].map(item => {
        const newList = item.list.map((x, index) => {
          return {
            ...x,
            order: index + 1,
          };
        });
        return {
          ...item,
          list: newList,
        };
      });
      setListMeasuringPoints(newPlot);
      setTriggerSortPlotLocations(true);
    }
  }, [isSortPlotLocations, listMeasuringPoints]);

  useEffect(() => {
    if (triggerSortPlotLocations) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSortPlotLocations]);

  return (
    <>
      <Modal
        title={null}
        visible={isOpenCanvas || isSortPlotLocations}
        width={1232}
        footer={null}
        closable={false}
        className={cx(styles.root)}
      >
        <div className={cx(styles.titleRoot, "flex-align-center")}>
          <div>
            <div className="font-12 font-weight-medium text-mono-gray-80 mr-4">
              <HintTooltip
                className="font-12 font-weight-regular text-mono-dark-80"
                content="測定箇所を配置"
                hintText={HintText.HINT_030}
              />
            </div>
            <div className="flex-center gap-12">
              {listMeasuringPoints.map(item => (
                <div className="flex-align-center">
                  <div
                    draggable
                    style={{
                      backgroundColor: item.color,
                    }}
                    className={cx(styles.point, "flex-center", {
                      [styles.disablePoint]:
                        item.list.length === MAX_POINT_CANVAS[item.text] ||
                        !image,
                    })}
                    onDragStart={() => {
                      circleName.current = item.text;
                    }}
                  >
                    {item.text}
                  </div>
                  <div className="font-10 ml-1 font-mono-dark-80">
                    {item.list.length}/{MAX_POINT_CANVAS[item.text]}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="font-12 font-weight-medium text-mono-gray-80 mr-4">
              移動範囲を描画
            </div>
            <span className="icon-shape-tool font-24" />
            <div className="flex-align-center">
              <div
                style={{
                  backgroundColor: "rgba(172, 0, 0, 1)",
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                  borderRadius: "50%",
                  border: "1px solid white",
                  marginLeft: 8,
                }}
                className={cx({
                  [styles.disableRects]: !image,
                })}
                aria-hidden="true"
                onClick={() => {
                  handleInsertRectangle(1);
                }}
              />
              <div className="font-10 ml-1 font-mono-dark-80">
                {rectangles.filter(x => x.type === RECTANGLES_TYPE.RED).length}/
                {MAX_COUNT_RECTANGLES.RED}
              </div>
            </div>

            <div className="flex-align-center ml-4">
              <div
                style={{
                  backgroundColor: "rgba(120, 161, 0, 1)",
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                  borderRadius: "50%",
                  border: "1px solid white",
                }}
                className={cx({
                  [styles.disableRects]: !image,
                })}
                onClick={() => {
                  handleInsertRectangle(2);
                }}
                aria-hidden="true"
              />
              <div className="font-10 ml-1 font-mono-dark-80">
                {
                  rectangles.filter(x => x.type === RECTANGLES_TYPE.GREEN)
                    .length
                }
                /{MAX_COUNT_RECTANGLES.GREEN}
              </div>
            </div>
          </div>

          <div>
            <div className="flex-align-center">
              <div className="font-12 font-weight-regular">90°回転</div>
              <Button
                onClick={handleRotate}
                disabled={isEditCanvas}
                type="text"
              >
                <span className="icon-redo font-20 cursor-pointer" />
              </Button>
            </div>
            <div className="flex-align-center">
              <Button type="text" disabled={!image} onClick={handleSave}>
                <div className="flex-align-center text-primary-secondary">
                  <span className="icon-download font-20" />
                  <span className="ml-1 text font-12">ダウンロード</span>
                </div>
              </Button>
              <Button
                disabled={isSubmitting}
                className={cx(styles.btnSubmit)}
                type="primary"
                onClick={handleSubmit}
              >
                <div className="font-12 font-weight-bold">編集を完了</div>
              </Button>
            </div>
          </div>
        </div>

        <div className={cx(styles.container, "flex-center")}>
          {image && stageSizeRoot?.current ? (
            <div
              ref={mainRef}
              className={cx(styles.main)}
              onDrop={handleDropPoint}
              onDragOver={e => e.preventDefault()}
              onWheel={() => setIsOpenDeleteDialog(false)}
            >
              {isSubmitting && <LoadingSpinner isFitContent />}
              {isShowHintText && <HintTextCanvas />}
              <DeletePointDialog
                isOpenDeletePointDialog={isOpenDeleteDialog}
                position={{
                  offsetX: boxPosition.offsetX,
                  offsetY: boxPosition.offsetY,
                }}
                onCancel={() => {
                  setIsOpenDeleteDialog(false);
                }}
                onDelete={() => {
                  if (selectedPoint) {
                    const { circleIndex, index, linked } = selectedPoint;
                    if (linked) {
                      openNotification({
                        type: "warning",
                        message: MessageError.ERRMSG_008,
                      });
                      setIsOpenDeleteDialog(false);
                      return;
                    }
                    const newList = [...listMeasuringPoints];
                    newList[circleIndex].list.splice(index, 1);
                    setListMeasuringPoints(newList);
                  }
                  const newRects = [...rectangles].filter(
                    x => x.id !== selectedId
                  );
                  setRectangles(newRects);
                  setIsOpenDeleteDialog(false);
                }}
              />

              <Stage
                onMouseDown={checkDeselect}
                ref={stageRef}
                style={{
                  marginTop:
                    stageSize.stageHeight < CANVAS_HEIGHT_ROOT
                      ? (CANVAS_HEIGHT_ROOT - stageSize.stageHeight) / 2
                      : 0,
                }}
                width={stageSize.stageWidth}
                height={stageSize.stageHeight}
                zIndex={1}
              >
                <Layer zIndex={0}>
                  <ImageKonva
                    onMouseDown={() => setSelectedId(null)}
                    zIndex={0}
                    image={image}
                    ref={imageRef}
                    rotation={rotateValue}
                    width={stageSize.imageWidth}
                    height={stageSize.imageHeight}
                    x={stageSizeRoot.current.stageWidth / 2}
                    y={
                      rotateValue === 0 || rotateValue === 180
                        ? stageSize.imageHeight / 2
                        : stageSize.imageWidth / 2
                    }
                    offset={{
                      x: stageSize.imageWidth / 2,
                      y: stageSize.imageHeight / 2,
                    }}
                  />

                  {rectangles.map((rect, i) => {
                    return (
                      <TransformerMeasurers
                        key={rect.id}
                        shapeProps={rect}
                        isSelected={rect.id === selectedId}
                        onSelect={() => {
                          setSelectedId(rect.id);
                        }}
                        onContextMenu={(e: any) => {
                          e.evt.preventDefault();
                          setSelectedPoint(null);
                          setIsOpenDeleteDialog(true);
                          setBoxPosition({
                            offsetX: mouse.elementX + 50,
                            offsetY: mouse.elementY - 30,
                          });
                        }}
                        onMouseEnter={() => {
                          stageRef.current.container().style.cursor = "grab";
                        }}
                        onMouseLeave={() => {
                          stageRef.current.container().style.cursor = "default";
                        }}
                        onChange={(newAttrs: typeRectangle) => {
                          const rects = rectangles.slice();
                          rects[i] = newAttrs;
                          setRectangles(rects);
                        }}
                      />
                    );
                  })}
                </Layer>

                {listMeasuringPoints?.map(
                  (cricle: any, circleIndex: number) => (
                    <Layer zIndex={10} ref={layerRef}>
                      {cricle.list?.map((item: any, index: number) => (
                        <Group
                          draggable
                          scale={{
                            x: 1 / scale,
                            y: 1 / scale,
                          }}
                          x={item.x}
                          y={item.y}
                          onMouseEnter={() => {
                            stageRef.current.container().style.cursor = "grab";
                          }}
                          onMouseUp={() => {
                            stageRef.current.container().style.cursor = "grab";
                          }}
                          onMouseDown={() => {
                            stageRef.current.container().style.cursor =
                              "grabbing";
                          }}
                          onMouseLeave={() => {
                            stageRef.current.container().style.cursor =
                              "default";
                          }}
                          onContextMenu={e => {
                            e.evt.preventDefault();
                            setIsOpenDeleteDialog(true);
                            setBoxPosition({
                              offsetX: mouse.elementX + 40,
                              offsetY: mouse.elementY - 20,
                            });
                            setSelectedPoint({
                              circleIndex,
                              index,
                              linked: item.measurement_result?.length > 0,
                            });
                          }}
                          onDragEnd={e => {
                            stageRef.current.container().style.cursor = "grab";
                            const newList = [...listMeasuringPoints];
                            const point = newList[circleIndex].list[index];
                            const isClientRect =
                              e.target.isClientRectOnScreen();
                            newList[circleIndex].list[index] = {
                              ...point,
                              x: isClientRect
                                ? e.target.x()
                                : point.x - 0.00001,
                              y: isClientRect
                                ? e.target.y()
                                : point.y - 0.00001,
                            };
                            setListMeasuringPoints(newList);
                          }}
                        >
                          <Circle
                            radius={20}
                            width={40}
                            height={40}
                            fill={cricle.color}
                            stroke="white"
                          />
                          <Rect
                            x={-20}
                            y={25}
                            stroke="white"
                            strokeWidth={1}
                            width={42}
                            height={24}
                            cornerRadius={3}
                            fill="#0"
                          />
                          <Text
                            x={index > 8 ? -15 : -12}
                            y={32}
                            text={`${cricle.text}-${item.order}`}
                            fontSize={14}
                            fontStyle="bold"
                            fill="#FFFFFF"
                          />
                          <Text
                            x={-6}
                            y={-5}
                            text={cricle.text}
                            fontStyle="bold"
                            fontSize={16}
                            fill="#FFFFFF"
                          />
                        </Group>
                      ))}
                    </Layer>
                  )
                )}
              </Stage>
              <div className={cx(styles.buttonZoom)}>
                <Button
                  className={cx(styles.button, "mb-1")}
                  type="primary"
                  disabled={zoomCount === MAX_COUNT_ZOOM}
                  onClick={() => {
                    handleZoom(stageRef.current, false);
                    setZoomCount(zoomCount + 1);
                  }}
                >
                  +
                </Button>
                <Button
                  type="primary"
                  className={cx(styles.button)}
                  disabled={zoomCount === 0}
                  onClick={() => {
                    setZoomCount(zoomCount - 1);
                    handleZoom(stageRef.current, true);
                  }}
                >
                  -
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="text-white font-18 font-weight-medium mb-5">
                ここにファイルをドラッグ＆ドロップしてください。
              </div>

              <Button
                onClick={() => document.getElementById("upload")?.click()}
              >
                ファイルを選択
              </Button>
              <input
                onChange={e => {
                  handleUploadImage(e.target.files);
                }}
                hidden
                type="file"
                id="upload"
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default memo(CanvasDesignMeasuringPoint);
