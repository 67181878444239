import dayjs, { Dayjs } from "dayjs";
import ja from "dayjs/locale/ja";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

import { DATE_TIME_FORMAT } from "@app/features/measurement_results/measurement-results";

dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale(ja);

export const handleSetTimeByStartTime = (
  time: Dayjs,
  startTime: Dayjs | undefined
) => {
  if (!startTime) return time?.format(DATE_TIME_FORMAT);
  return time
    .set("date", startTime.get("date"))
    .set("year", startTime.get("year"))
    .set("month", startTime.get("month"))
    ?.format(DATE_TIME_FORMAT);
};
