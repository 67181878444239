import { memo } from "react";

import cx from "classnames";

import styles from "./HintTextCanvas.module.scss";

const HintTextCanvas = () => {
  return (
    <div className={cx(styles.container, "flex-center")}>
      <div>
        <div className="text-white">
          <div className="body-m">操作手順ヒント</div>
          <ul className={cx(styles.list)}>
            <li>
              必要であればアップロードした画像を「90°回転」で回転させてください。
            </li>
            <li>
              右下の拡大/縮小ボタンで、任意の場所を見やすいように表示してください。
            </li>
            <li>
              左上のアイコンをドラッグ＆ドロップして測定箇所を配置してください。
            </li>
          </ul>
          <div>
            ※測定箇所を配置すると画像の回転ができなくなりますのでご注意ください。
          </div>
        </div>
        <div className="text-mono-dark-20 body-s mt-4">
          このヒントは見取り図を編集すると非表示になります。
        </div>
      </div>
    </div>
  );
};

export default memo(HintTextCanvas);
