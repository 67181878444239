type TAG_TYPE = {
  text: string;
  color: string;
  backgroundColor: string;
};

export const MEASUREMENT_RESULTS_TABLE_HEAD = [
  {
    text: "種別",
    dataIndex: "type",
  },
  {
    text: "型式",
    dataIndex: "device_model",
  },
  {
    text: "製造番号",
    dataIndex: "device_serial_no",
  },
  {
    text: "管理名",
    dataIndex: "device_management_name",
  },
  {
    text: "除外設定",
    dataIndex: "filted_status",
  },
  {
    text: "報告書",
    dataIndex: "report_link_status",
  },
  {
    text: "作業場",
    dataIndex: "workplace_name",
    width: "350px",
  },
  {
    text: "測定開始日時",
    dataIndex: "measure_start_datetime",
  },
  {
    text: "測定時間",
    dataIndex: "measurement_interval",
  },
  {
    text: "LAeq(全体)",
    dataIndex: "laeq",
  },
  {
    text: "DOSE(全体)",
    dataIndex: "dose",
  },
  {
    text: "",
    dataIndex: "over",
    width: "200px",
  },
];

export const WINDOWS_APP_STYLE: Record<string, TAG_TYPE> = {
  1: {
    text: "OV",
    color: "#FFFFFF",
    backgroundColor: "#F6AA00",
  },
  2: {
    text: "UN",
    color: "#FFFFFF",
    backgroundColor: "#4DC4FF",
  },
  3: {
    text: "IM",
    color: "#FFFFFF",
    backgroundColor: "#EB4B00",
  },
};

export const MEASUREMENTS_RESULTS_SELECTED_TYPE = {
  騒音: 1,
  ばく露: 2,
  すべて: "",
};

export const MEASUREMENTS_RESULTS_SELECTED_VALUE: Record<number, string> = {
  1: "騒音",
  2: "ばく露",
};

export const MEASUREMENTS_RESULTS_NUMBER = {
  COUNT: 10,
  MAX_SELECTED: 5,
};

export const MEASUREMENTS_RESULTS_SCALE: Record<number, string> = {
  1: "data_1_second",
  2: "data_30_second",
  3: "data_60_second",
};

export const TGRAPH_DATA_DEFAULT: {
  Y_AXIS_RANGE_MIN: number;
  Y_AXIS_RANGE_MAX: number;
  X_AXIS_SCALE: 1 | 2 | 3;
} = {
  Y_AXIS_RANGE_MIN: 50,
  Y_AXIS_RANGE_MAX: 150,
  X_AXIS_SCALE: 1,
};

export const TGRAPH_DATA_LIMIT = {
  Y_AXIS_RANGE_MIN: 50,
  Y_AXIS_RANGE_MAX: 150,
};

export const DATA_DATE_TIME_PICKER = [
  { key: "date", format: "YYYY/MM/DD", textAfter: "" },
  { key: "hour", format: "HH", textAfter: "時" },
  { key: "minute", format: "mm", textAfter: "分" },
];

export const DATA_DATE_TIME_DISPLAY_SETTING = [
  { key: "date", format: "YYYY/MM/DD", textBefore: "", textAfter: "" },
  { key: "hour", format: "HH", textBefore: "開始時刻", textAfter: "時" },
  { key: "minute", format: "mm", textBefore: "表示範囲", textAfter: "分" },
];

export const DATA_TIME_PICKER = [
  { key: "hour", format: "HH", textAfter: "時" },
  { key: "minute", format: "mm", textAfter: "分" },
  { key: "second", format: "ss", textAfter: "秒" },
];

export const MAX_HOUR_SELECT = 15;

export const X_AXIS_SCALE = {
  1: {
    key: "second",
    value: 1,
  },
  2: {
    key: "second",
    value: 30,
  },
  3: {
    key: "minute",
    value: 1,
  },
};

export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";

export const TIME_FORMAT = "2022/01/01 HH:mm:ss";
export const TIME_FORMAT_NEXT_DAY = "2022/01/02 HH:mm:ss";
export const TIME_FORMAT_HOUR_SECOND = "HH:mm:ss";
export const TIME_FORMAT_HOUR = "HHｈ MMｍ";

export const MAX_WIDTH_TGRAPH = 48421;

export const MAX_FILTER_PERIODS = 10;
export const DEVICE_TYPE = {
  SOONKEI: 1,
  BAKURO: 2,
};

export const FILTED_STATUS_TYPE: Record<number, string> = {
  10: "未編集",
  20: "設定済み",
};

export const REPORT_LINK_STATUS_TYPE: Record<number, string> = {
  10: "未割り当て",
  20: "引用済み",
};

export const DATA_VALUE = [
  {
    title1: "測定開始日時",
    title2: "測定時間",
    dataKey: {
      name: "start_time",
    },
    dataKey1: {
      name: "measurement_interval",
    },
  },
  {
    title1: "LAeq(全体)",
    title2: "DOSE(全体)",
    dataKey: {
      name: "laeq",
      format: " dB",
    },
    dataKey1: {
      name: "dose",
      format: " %",
    },
  },
  {
    title1: "ばく露許容時間",
    title2: "LCeq(全体)",
    dataKey: {
      name: "time_allowed_for_exposure",
    },
    dataKey1: {
      name: "lceq",
      format: " dB",
    },
  },
  {
    title1: "LCpeak(全体)",
    title2: "基準時間",
    dataKey: {
      name: "lcpeak",
      format: " dB",
    },
    dataKey1: {
      name: "tc",
      format: " h",
    },
  },
  {
    title1: "基準レベル",
    title2: "Exchange Rate",
    dataKey: {
      name: "lc",
      format: " dB",
    },
    dataKey1: {
      name: "exchange_rate",
    },
  },
  {
    title1: "推奨SNR値(全体)",
    title2: "LAeq-8hn",
    dataKey: {
      name: "recommendation_snr",
    },
    dataKey1: {
      name: "laeq_8hn",
      format: " dB",
    },
  },
  {
    title1: "LAE(全体)",
    title2: "",
    dataKey: {
      name: "lae",
      format: " dB",
    },
    dataKey1: {
      name: "",
    },
  },
];

export const EXCLUSION_SETTING = [
  {
    title1: "測定開始日時",
    title2: "測定時間",
    dataKey: { name: "valid_start_time" },
    dataKey1: {
      name: "valid_end_time",
    },
  },
  {
    title1: "LAeq(除外後)",
    title2: "DOSE(除外後)",
    dataKey: { name: "edited_laeq", format: " dB" },
    dataKey1: { name: "edited_dose", format: " %" },
  },
  {
    title1: "ばく露許容時間",
    title2: "",
    dataKey: {
      name: "edited_time_allowed_for_exposure",
    },
    dataKey1: { name: "" },
  },
];

export const WORKSHOP_INFORMATION = [
  { title: "測定者", dataKey: "name" },
  { title: "事業所", dataKey: "name" },
  { title: "作業場", dataKey: "name" },
  { title: "作業者", dataKey: "worker_name" },
  { title: "測定箇所", dataKey: "measure_location" },
];

export const DATA_VALUE_SONKIE = [
  {
    title1: "測定開始日時",
    title2: "測定時間",
    dataKey: {
      name: "start_time",
    },
    dataKey1: { name: "measurement_interval" },
  },
  {
    title1: "LAeq(全体)",
    title2: "DOSE(全体)",
    dataKey: { name: "laeq", format: " dB" },
    dataKey1: {
      name: "",
    },
  },
  {
    title1: "ばく露許容時間",
    title2: "推奨SNR値",
    dataKey: {
      name: "",
    },
    dataKey1: {
      name: "recommendation_snr",
    },
  },
];

export const WORKSHOP_SOONKEI_INFORMATION = [
  { title: "測定者", dataKey: "name" },
  { title: "事業所", dataKey: "name" },
  { title: "作業場", dataKey: "name" },
  { title: "作業者", dataKey: "" },
  { title: "測定箇所", dataKey: "measure_location" },
];

export const TIME_3_MINUTES = 3 * 60;
