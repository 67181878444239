import { memo } from "react";

import { get } from "lodash";

import { CLASSIFY, ReportsDataDef } from "@app/features/reports/reports";
import { convertJapaneseCalendar } from "@app/helpers/file.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

const PreviewReportsPage6 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  const { evaluations } = useAppSelector((state: RootState) => state.reports);

  return (
    <div id="page-6" className="page-a4 mx-Auto px-6">
      <div className="heading-m pt-4 mb-2 header-reports-number flex-space-between width-fit-content ml-Auto font-12">
        <span>記録表番号</span>
        <span>{dataReport?.record_sheet_number}</span>
      </div>
      <div className="heading-m"> １０. 所見等</div>
      <div style={{ minHeight: 200, border: "1px solid" }}>
        {dataReport?.memo}
        <img
          className="mt-3"
          crossOrigin="anonymous"
          style={{ maxHeight: 300, objectFit: "contain" }}
          width="100%"
          src={dataReport?.pre_signed_attachment_url}
          alt=""
        />
      </div>
      <div className="heading-s my-2">過去測定結果履歴 等価騒音レベル(dB)</div>
      <div>
        <table>
          <tr>
            <td />
            {new Array(8).fill(1).map((_, index) => {
              return (
                <td className="bg-mono-dark-10">
                  {index === 0 ? (
                    "今回"
                  ) : (
                    <>{index === 1 ? "前回" : `${index}回前`}</>
                  )}
                </td>
              );
            })}
          </tr>
          {[
            { title: "測定年月日", dataKey: "measurement_date" },
            { title: "A測定値", dataKey: "measure_A_value" },
            { title: "B測定値", dataKey: "measure_B_value" },
            { title: "個人ばく露測定値", dataKey: "measure_C_value" },
            { title: "管理区分", dataKey: "evaluation_total" },
          ].map((item, index) => (
            <tr>
              <td className="text-center bg-mono-dark-10" width={90}>
                {item.title}
              </td>
              {new Array(8).fill(1).map((_, val) => (
                <td className="text-center font-11" width={50}>
                  {index === 0 &&
                    `${convertJapaneseCalendar(
                      get(
                        evaluations,
                        `history_evaluations.${val}.${item.dataKey}`
                      )
                    )}`}
                  {index !== 0 &&
                    index !== 4 &&
                    `${
                      get(
                        evaluations,
                        `history_evaluations.${val}.${item.dataKey}`
                      ) ?? "-"
                    }`}

                  {index === 4 &&
                    (CLASSIFY[
                      Number(
                        get(
                          evaluations,
                          `history_evaluations.${val}.${item.dataKey}`
                        )
                      )
                    ] ??
                      "-")}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default memo(PreviewReportsPage6);
