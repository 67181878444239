import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { MeasurersEndpointsEnum } from "../constants/measurers.endpoints";
import { CreateMeasureDataDef } from "../measurers";

// API-MEASURER-020
const getMeasurersApi = (): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.GET_MEASURERS;
  return api.get(url);
};

const getMeasurementResultsApi = (params: {
  report_id?: string;
  workplace_id?: string;
  date_from?: string;
  date_to?: string;
}): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.MEASUREMENT_RESULTS;
  return api.get(url, { params });
};

// API-DATA-030
const getMeasurementResultDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.MEASUREMENT_RESULTS_DETAIL.replace(
    ":id",
    id
  );
  return api.get(url);
};

const getMeasurersDetailsApi = (id: string): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.GET_MEASURERS_DETAILS.replace(":id", id);
  return api.get(url);
};

const createMeasurerApi = (
  data: CreateMeasureDataDef
): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.CREATE_MEASURER;
  return api.post(url, data);
};

const editMeasurerApi = ({
  id,
  data,
}: {
  id: string;
  data: CreateMeasureDataDef;
}): Promise<AxiosResponse> => {
  const url = MeasurersEndpointsEnum.EDIT_MEASURERS.replace(":id", id);
  return api.put(url, data);
};

const measurersApi = {
  getMeasurersApi,
  getMeasurementResultsApi,
  getMeasurementResultDetailApi,
  getMeasurersDetailsApi,
  createMeasurerApi,
  editMeasurerApi,
};

export default measurersApi;
