import { memo, useMemo } from "react";

import dayjs from "dayjs";

import {
  ICON_TYPE_A,
  ReportsDataDef,
  ICON_TYPE_B,
  ICON_TYPE_C,
  getNumberClassification,
} from "@app/features/reports/reports";
import { convertJapaneseCalendar } from "@app/helpers/file.helper";

const PreviewReportsPage2 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  const getTotalPointByType = (type: number) => {
    return dataReport?.workplace?.measurement_design?.design_plot_locations
      ?.map(item => item.icon_type)
      ?.filter(item => item === type)?.length;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTimeType = (type: number) => {
    const listMeasureResult =
      dataReport?.workplace?.measurement_design?.design_plot_locations
        ?.filter(
          item => item.icon_type === type && !!item.measurement_result?.length
        )
        ?.map(item => item.measurement_result?.[0]);

    const startDateTime = dayjs.min(
      listMeasureResult
        ?.filter(item => !!item?.start_time)
        ?.map(item => dayjs(item?.start_time)) ?? []
    );

    const startDateTimeOld = dayjs.max(
      listMeasureResult
        ?.filter(item => !!item?.start_time)
        ?.map(item => dayjs(item?.start_time)) ?? []
    );

    const endDateTime = dayjs.max(
      listMeasureResult
        ?.filter(item => !!item?.end_time)
        ?.map(item => dayjs(item?.end_time)) ?? []
    );

    return {
      startDateTime: startDateTime?.format(
        `${convertJapaneseCalendar(
          startDateTime ? startDateTime.format("YYYY/MM/DD") : null,
          true
        )} MM 月 DD 日`
      ),
      startDateTimeOld: startDateTimeOld?.format(
        `${convertJapaneseCalendar(
          startDateTime ? startDateTime.format("YYYY/MM/DD") : null,
          true
        )} MM 月 DD 日`
      ),
      startHoursTime: startDateTime?.format("HH 時 mm 分"),
      endHoursTime: endDateTime?.format("HH 時 mm 分"),
      measurementTime:
        endDateTime && startDateTime
          ? `${Math.abs(
              dayjs(endDateTime.format("YYYY/MM/DD HH:00:00")).diff(
                startDateTime.format("YYYY/MM/DD HH:00:00"),
                "minute"
              )
            )}分`
          : "-",
    };
  };

  const { listMeasures, measureCount } = useMemo(() => {
    const measureFilter = dataReport?.measurers?.filter(item => item.id);
    const totalMeasure = measureFilter?.length ?? 0;

    if (totalMeasure > 0 && totalMeasure < 3) {
      return {
        listMeasures: measureFilter,
        measureCount: totalMeasure,
      };
    }

    return {
      listMeasures: [measureFilter?.[0]],
      measureCount: totalMeasure,
    };
  }, [dataReport?.measurers]);

  const listClassification = useMemo(() => {
    return [
      dataReport?.workplace?.workplace_classification_1,
      dataReport?.workplace?.workplace_classification_2,
      dataReport?.workplace?.workplace_classification_3,
    ].filter(item => item);
  }, [dataReport]);

  const isTimeOverlap = useMemo(() => {
    return (
      !!getTimeType(ICON_TYPE_C).startDateTime &&
      getTimeType(ICON_TYPE_C).startDateTime ===
        getTimeType(ICON_TYPE_C).startDateTimeOld
    );
  }, [getTimeType]);

  return (
    <div id="page-2" className="page-a4 mx-Auto mb-5 pb-5 px-6">
      <section>
        <div className="heading-l font-20 font-weight-bold text-center pt-4">
          作業環境測定結果記録表（騒音用）
        </div>
        <div className="text-end">
          <div className="heading-m mt-2 width250 flex-space-between width-fit-content ml-Auto font-12">
            <span>記録表番号</span>
            <span>{dataReport?.record_sheet_number}</span>
          </div>
          <div className="heading-m mt-2 width250 flex-space-between width-fit-content ml-Auto font-12">
            <span>添付する報告書（証明書）番号</span>
            <span>{dataReport?.report_number}</span>
          </div>
        </div>
      </section>

      <div className="pt-4">
        <div>
          <div className="heading-m">１．測定を実施した者</div>
          <table>
            <tr className="bg-mono-dark-10">
              <td width={90} />
              <td>デザイン</td>
              <td>測 定</td>
            </tr>
            <tr>
              <td className="bg-mono-dark-10">氏名</td>
              <td className="text-overflow py-0 px-1">
                {dataReport?.measurement_designer?.name
                  ? dataReport?.measurement_designer?.name
                  : "-"}
              </td>
              <td>
                <div className="text-overflow py-0 px-1">
                  {listMeasures?.map(item => {
                    return item?.name ? (
                      <span className="mr-3">{item?.name}</span>
                    ) : (
                      "-"
                    );
                  })}
                  {measureCount >= 3 && (
                    <span className="ml-1"> 他{measureCount - 1}人</span>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td className="bg-mono-dark-10">登録番号</td>
              <td>
                {dataReport?.measurement_designer?.registration_number
                  ? dataReport?.measurement_designer?.registration_number
                  : "-"}
              </td>
              <td>
                {listMeasures?.map(item => {
                  return item?.registration_number ? (
                    <span className="mr-2">{item?.registration_number}</span>
                  ) : (
                    "-"
                  );
                })}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div className="heading-m mt-2">２．騒音作業の種類</div>
          <table>
            <tr>
              <td className="bg-mono-dark-10" colSpan={2} width={180}>
                ガイドライン別表の号区分
              </td>
              <td width={300}>
                {listClassification.map((classification, index) => (
                  <>{`${getNumberClassification(classification)}${
                    index === listClassification.length - 1 ? "" : "、"
                  }`}</>
                ))}
              </td>
            </tr>
            <tr>
              <td
                className="bg-mono-dark-10"
                width={106}
                style={{ height: "60px" }}
              >
                騒音作業の概要
              </td>
              <td colSpan={2} className="text-left">
                {dataReport?.workplace?.noise_summary}
              </td>
            </tr>
            <tr>
              <td className="bg-mono-dark-10">騒音の発生状況</td>
              <td colSpan={2}>
                {dataReport?.workplace?.constant_noise && "★"}
                <span className="font-weight-bold mr-6">定常騒音</span>
                {dataReport?.workplace?.regular_fluctuation && "★"}
                <span className="font-weight-bold mr-6">規則的変動</span>
                {dataReport?.workplace?.random_fluctuation && "★"}
                <span className="font-weight-bold mr-6">不規則変動</span>
                {dataReport?.workplace?.impact_noise && "★"}
                <span className="font-weight-bold mr-6">衝撃音</span>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div className="heading-m mt-2">３．測定 実施日時</div>
          <table>
            <colgroup>
              <col span={1} className="bg-mono-dark-10" />
            </colgroup>
            <tr className="bg-mono-dark-10">
              <td width={120} />
              <td width={110}>実施日</td>
              <td>開始時刻(イ)</td>
              <td>終了時刻(ロ)</td>
              <td>時間(ロ)ー(イ)</td>
            </tr>
            <tr>
              <td>A 測定</td>
              <td>{getTimeType(ICON_TYPE_A).startDateTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_A).startHoursTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_A).endHoursTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_A).measurementTime ?? "-"}</td>
            </tr>
            <tr>
              <td>B測定</td>
              <td>{getTimeType(ICON_TYPE_B).startDateTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_B).startHoursTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_B).endHoursTime ?? "-"}</td>
              <td>{getTimeType(ICON_TYPE_B).measurementTime ?? "-"}</td>
            </tr>
            <tr className="bg-mono-dark-10">
              <td rowSpan={2}>個人ばく露測定</td>
              <td colSpan={2}>{isTimeOverlap ? "実施日" : "実施開始日"}</td>
              {!isTimeOverlap && <td colSpan={2}>実施終了日</td>}
            </tr>
            <tr>
              <td colSpan={2}>
                {getTimeType(ICON_TYPE_C).startDateTime ?? "-"}
              </td>
              {!isTimeOverlap && (
                <td colSpan={2}>
                  {getTimeType(ICON_TYPE_C).startDateTimeOld ?? "-"}
                </td>
              )}
            </tr>
          </table>
        </div>
        <div>
          <div className="heading-m mt-2">４．単位作業場所等の概要</div>
          <table>
            <tr>
              <td className="bg-mono-dark-10" width={100}>
                単位作業場所No
              </td>
              <td width={150}>
                {dataReport?.workplace?.workplace_number ?? "-"}
              </td>
              <td className="bg-mono-dark-10" width={150}>
                広さ
              </td>
              <td>
                <div className="width-fit-content flex-space-between mx-Auto">
                  <span style={{ width: "30px" }}>
                    {dataReport?.workplace?.extent ?? "-"}
                  </span>
                  <span>[㎡]</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="bg-mono-dark-10">A測定点の数 </td>
              <td>N = {getTotalPointByType(ICON_TYPE_A) ?? "-"}</td>
              <td className="bg-mono-dark-10">B測定値の数</td>
              <td>
                <div className="width-fit-content flex-space-between mx-Auto">
                  <span>N =</span>
                  <span className="width30">
                    {getTotalPointByType(ICON_TYPE_B) ?? "-"}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}> </td>
              <td className="bg-mono-dark-10">個人ばく露測定値の数</td>
              <td>
                <div className="width-fit-content flex-space-between mx-Auto">
                  <span>N =</span>
                  <span className="width30">
                    {getTotalPointByType(ICON_TYPE_C) ?? "-"}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="text-left pl-4">
                <div>単位作業場所の範囲を決定した理由</div>
                <div>
                  （１）騒音の発生状況・・・{" "}
                  {dataReport?.workplace?.noise_sources}
                </div>
                <div>
                  （２）労働者の行動範囲・・・{" "}
                  {dataReport?.workplace?.range_of_action_for_workers}
                </div>
                <div>
                  （３）その他・・・{" "}
                  {dataReport?.workplace?.reason_for_measuring}
                </div>
              </td>
            </tr>
            <tr style={{ height: 40, verticalAlign: "top" }}>
              <td colSpan={4} className="text-left pl-4">
                B測定の測定点と測定時刻を決定した理由
                <br />
                {dataReport?.workplace?.reason_for_measuring_B_point}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="text-left bg-mono-dark-10" width={280}>
                A測定の間隔が６m以上の場合、その間隔
              </td>
              <td>縦方向</td>
              <td width={60}>
                {dataReport?.workplace?.vertical_spacing_of_A_point}[m]
              </td>
              <td>横方向</td>
              <td width={60}>
                {dataReport?.workplace?.horizontal_spacing_of_A_point}[m]
              </td>
            </tr>
            <tr>
              <td
                style={{ verticalAlign: "top" }}
                className="text-left pl-2"
                height={60}
                colSpan={5}
              >
                <span>
                  A測定の数が５未満の場合、その数及び配置を決定した理由.
                </span>
                <span className="ml-4">
                  {dataReport?.workplace?.count_of_A_point} 箇所
                </span>
                <div>{dataReport?.workplace?.reason_for_count_of_A_point}</div>
              </td>
            </tr>
            <tr>
              <td className="text-left bg-mono-dark-10" colSpan={1}>
                個人ばく露測定が必要と判断した理由
              </td>
              <td className="text-left" colSpan={4}>
                {dataReport?.workplace?.reason_for_measuring_noise_exposure}
              </td>
            </tr>
            <tr>
              <td className="text-left bg-mono-dark-10" colSpan={1}>
                個人ばく露測定の測定点と測定時刻を決定した理由
              </td>
              <td className="text-left" colSpan={4}>
                {dataReport?.workplace?.reason_for_design_noise_exposure}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(PreviewReportsPage2);
