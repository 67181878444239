import { memo, useState, useEffect } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Input, Radio, RadioChangeEvent, Row, Select } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { get } from "lodash";
import { stringify } from "qs";
import { Link, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import TextTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import {
  MessageError,
  MessageSuccess,
} from "@app/constants/messages.constants";
import {
  ExclusionTemplate,
  getExclusionTemplateSettings,
  postExclusionTemplateSettings,
  updateExclusionTemplateSettings,
} from "@app/features/filters/filters";
import { getMeasurersDetails } from "@app/features/measurers/measurers";
import { getOfficesDetails } from "@app/features/offices/offices";
import { ICON_TYPE } from "@app/features/reports/reports";
import { getWorkplacesDetail } from "@app/features/workplaces/workplaces";
import { convertMinuteToHour, numberFormat } from "@app/helpers/util.helper";
import { useAppSelector, useAppDispatch } from "@app/redux/store";

import { TIME_FORMAT_HOUR_SECOND } from "../../constants/measurement-results.constants";
import { MeasurementResultPathsEnum } from "../../constants/measurement-results.paths";
import { getTimeFrame } from "../../helpers/measurement-results.helpers";
import {
  DATA_VALUE,
  EXCLUSION_SETTING,
  FILTED_STATUS_TYPE,
  getMeasurementResultsDetail,
  MeasurementResultsDetail,
  REPORT_LINK_STATUS_TYPE,
  UpdateMeasurementResult,
  WORKSHOP_INFORMATION,
} from "../../measurement-results";
import styles from "./BakuroMeasurementResultsDetailScreen.module.scss";

const BakuroMeasurementResultsDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [isOpenSelectBox, setIsOpenSelectBox] = useState(false);

  const [valueFilter, setValueFilter] = useState<
    ExclusionTemplate | null | undefined
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { measurementResultsDetail } = useAppSelector(
    state => state.measurement_results
  );

  const [measurementResultMemo, setMeasurementResultMemo] =
    useState<string>("");

  useEffect(() => {
    setMeasurementResultMemo(
      `${measurementResultsDetail?.measurement_result.memo ?? ""}`
    );
  }, [measurementResultsDetail?.measurement_result.memo]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(getExclusionTemplateSettings()),
      dispatch(getMeasurementResultsDetail(id))
        .then(unwrapResult)
        .then((data: MeasurementResultsDetail) => {
          Promise.all([
            data.measurement_result?.workplace_id &&
              dispatch(
                getWorkplacesDetail(
                  String(data.measurement_result.workplace_id)
                )
              ),
            data.measurement_result?.measurer_id &&
              dispatch(
                getMeasurersDetails(String(data.measurement_result.measurer_id))
              ),
            data.measurement_result?.office_id &&
              dispatch(
                getOfficesDetails(String(data.measurement_result.office_id))
              ),
          ]).finally(() => {
            setIsLoading(false);
          });
        }),
    ]).then(result => {
      if (result[0]?.payload?.isAxiosError) {
        setIsLoading(false);
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
      }
    });
  }, [dispatch, id]);

  const {
    offices: { listOfficesDetails },
    workplaces: { listWorkplacesDetails },
    measurers: { listMeasurersDetails },
    filters: { listExclusionTemplateSettings },
  } = useAppSelector(state => state);

  const handleChangeExclusionTemplateSettings = (e: RadioChangeEvent) => {
    const exclusionTemplate = listExclusionTemplateSettings?.find(
      item => item.id === e.target.value
    );

    setValueFilter(exclusionTemplate);
    setIsOpenSelectBox(false);
  };

  useEffect(() => {
    setValueFilter(measurementResultsDetail?.measurement_result?.filter);
  }, [measurementResultsDetail]);

  const handleExclusionSetting = () => {
    setIsLoading(true);

    const convertData = {
      ...valueFilter,
      measurement_result_id: measurementResultsDetail?.measurement_result?.id,
      id: undefined,
      filter_periods: valueFilter?.filter_periods?.map(item => {
        return {
          ...item,
          id: undefined,
        };
      }),
    };

    const handleSubmit = measurementResultsDetail?.measurement_result.filter_id
      ? dispatch(
          updateExclusionTemplateSettings({
            data: convertData,
            id: measurementResultsDetail.measurement_result.filter_id,
          })
        )
      : dispatch(postExclusionTemplateSettings(convertData));

    handleSubmit
      .then(unwrapResult)
      .then(() => {
        dispatch(getMeasurementResultsDetail(id)).finally(() => {
          setIsLoading(false);
        });
        openNotification({
          type: "success",
          message: MessageSuccess.MSG_005,
        });
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
        setIsLoading(false);
      });
  };

  const { TextArea } = Input;
  const handleUpdateMemo = () => {
    setIsLoading(true);
    dispatch(
      UpdateMeasurementResult({
        MeasurementResultId: String(
          measurementResultsDetail?.measurement_result.id
        ),
        data: {
          memo: measurementResultMemo,
        },
      })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={cx(styles.listTable, "px-6 py-6")}>
        <div className="bg-white px-4 py-4 flex-space-between">
          <div className="d-flex gap-24">
            <div>
              <div className="font-12 text-mono-dark-40">型式</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result?.device_model ??
                  "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40">製造番号</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result
                  ?.device_serial_no ?? "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40">管理名称</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result
                  ?.device_management_name ?? "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40">
                除外設定ステータス
              </div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result?.filted_status
                  ? FILTED_STATUS_TYPE[
                      measurementResultsDetail?.measurement_result
                        ?.filted_status
                    ]
                  : "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40">報告書ステータス</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result
                  ?.report_link_status
                  ? REPORT_LINK_STATUS_TYPE[
                      measurementResultsDetail?.measurement_result
                        ?.report_link_status
                    ]
                  : "-"}
              </div>
            </div>
          </div>
          <div className="flex-align-center gap-16">
            <Link
              to={{
                pathname:
                  MeasurementResultPathsEnum.MEASUREMENT_RESULTS_T_GRAPH_SCREEN,
                search: stringify(
                  { listId: [id] },
                  {
                    arrayFormat: "brackets",
                    encode: false,
                  }
                ),
              }}
              target="_blank"
            >
              <Button type="primary" className="flex-align-center">
                <span className="icon-view-chart font-20 mr-3 " />
                <span>T-Lグラフを表示</span>
              </Button>
            </Link>
          </div>
        </div>
        <Row className="mt-6" gutter={26}>
          <Col flex={802}>
            <div className="heading-m mb-2 text-dark-80-alpha">データ値</div>
            <div className="p-6 bg-white">
              <div className={cx(styles.tableHalf)}>
                {DATA_VALUE.map((item, index) => {
                  const valueDataKey = get(
                    measurementResultsDetail,
                    `measurement_result.${item.dataKey.name}`
                  );
                  const valueDataKey1 = get(
                    measurementResultsDetail,
                    `measurement_result.${item.dataKey1.name}`
                  );
                  return (
                    <>
                      <div className="tableHalf_row">
                        <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                          {item.title1}
                        </div>
                        <div className="text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden">
                          {index === 0 && <>{valueDataKey ?? "-"}</>}
                          {index === 2 && (
                            <>{`${convertMinuteToHour(
                              valueDataKey,
                              " h",
                              " m"
                            )}`}</>
                          )}
                          {index !== 0 && index !== 2 && index !== 5 && (
                            <>
                              <>
                                {`${numberFormat(valueDataKey)}${
                                  item.dataKey.format ?? ""
                                }`}
                              </>
                            </>
                          )}
                          {index === 5 && <>{valueDataKey ?? "-"}</>}
                        </div>
                        <>
                          <div
                            className={`heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2 ${
                              item.title2 ? "" : "hidden"
                            }`}
                          >
                            {item.title2}
                          </div>
                          <div
                            className={`text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden ${
                              item.title2 ? "" : "hidden"
                            }`}
                          >
                            {index !== 2 && index !== 5 && (
                              <>
                                {valueDataKey1
                                  ? `${valueDataKey1}${
                                      item.dataKey1.format ?? ""
                                    }`
                                  : "-"}
                              </>
                            )}
                            {index === 2 && (
                              <>{`${numberFormat(valueDataKey1)}${
                                item.dataKey1.format ?? ""
                              }`}</>
                            )}
                            {index === 5 && (
                              <>{`${numberFormat(valueDataKey1)}${
                                item.dataKey1.format ?? ""
                              }`}</>
                            )}
                          </div>
                        </>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="mt-6" />
              <div>
                <TextTooltip content="除外設定" hintText={HintText.HINT_029} />
                <div className="mt-2 d-flex">
                  <Select
                    dropdownMatchSelectWidth={512}
                    style={{ width: 400 }}
                    open={isOpenSelectBox}
                    value={valueFilter?.name}
                    onFocus={() => setIsOpenSelectBox(true)}
                    allowClear
                    onClear={() => {
                      setValueFilter(undefined);
                    }}
                    onDropdownVisibleChange={() => setIsOpenSelectBox(false)}
                    placeholder="テンプレートを選択してください"
                    notFoundContent={<></>}
                    dropdownRender={() => (
                      <div className="p-4">
                        <div className={cx(styles.contentHeader)}>
                          <div>テンプレート名</div>
                          <div>時間帯</div>
                        </div>
                        <Radio.Group
                          onChange={handleChangeExclusionTemplateSettings}
                          className={cx(styles.radioGroup)}
                        >
                          {listExclusionTemplateSettings?.map(item => {
                            const { start_time, end_time } = getTimeFrame(
                              item?.filter_periods
                            );
                            return (
                              <Radio
                                className={cx(styles.radioBox, "with100")}
                                value={item.id}
                              >
                                <div className="d-flex">
                                  <div className={cx(styles.radioTextBox)}>
                                    {item.name}
                                  </div>
                                  <div className={cx(styles.radioTime)}>
                                    {start_time && end_time
                                      ? `${
                                          getTimeFrame(item?.filter_periods)
                                            .start_time ?? "-"
                                        } 
                          〜 
                          ${getTimeFrame(item?.filter_periods).end_time ?? "-"}`
                                      : "-"}
                                  </div>
                                </div>
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    )}
                  />

                  <Button
                    disabled={isLoading || !valueFilter?.name}
                    onClick={handleExclusionSetting}
                    type="primary"
                    className="ml-3"
                  >
                    <div className="font-12 font-weight-regular">反映</div>
                  </Button>
                </div>
              </div>

              <div className={cx(styles.tableHalf, "mt-2")}>
                {EXCLUSION_SETTING.map((item, index) => {
                  const valueDataKey = valueFilter?.name
                    ? get(
                        measurementResultsDetail,
                        `measurement_result.${item.dataKey.name}`
                      )
                    : null;
                  const valueDataKey1 = valueFilter?.name
                    ? get(
                        measurementResultsDetail,
                        `measurement_result.${item.dataKey1.name}`
                      )
                    : "-";
                  return (
                    <>
                      <div className="tableHalf_row">
                        <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                          {item.title1}
                        </div>
                        <div className="text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden">
                          {index === 0 && (
                            <>{valueFilter?.valid_start_time ?? "-"}</>
                          )}
                          {index === 1 && (
                            <>
                              {numberFormat(valueDataKey)}{" "}
                              {item.dataKey.format ?? ""}
                            </>
                          )}
                          {index === 2 && (
                            <>{convertMinuteToHour(valueDataKey, " h", " m")}</>
                          )}
                        </div>
                        <>
                          <div
                            className={`heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2 ${
                              item.title2 ? "" : "hidden"
                            }`}
                          >
                            {item.title2}
                          </div>
                          <div
                            className={`text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden ${
                              item.title2 ? "" : "hidden"
                            }`}
                          >
                            {index === 0 && (
                              <>
                                {valueFilter?.valid_end_time &&
                                valueFilter.valid_start_time
                                  ? dayjs(
                                      new Date(0).setSeconds(
                                        dayjs(valueFilter?.valid_end_time).diff(
                                          valueFilter?.valid_start_time,
                                          "second"
                                        )
                                      )
                                    ).format(TIME_FORMAT_HOUR_SECOND)
                                  : "-"}
                              </>
                            )}
                            {index === 1 && (
                              <>
                                {valueDataKey1}
                                {item.dataKey1.format ?? ""}
                              </>
                            )}
                          </div>
                        </>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col flex={492}>
            <div className="heading-m mb-2 text-dark-80-alpha">作業場情報</div>
            <div className="p-6 bg-white">
              <div className={cx(styles.tableHalf)}>
                {WORKSHOP_INFORMATION.map((item, index) => (
                  <>
                    <div className="tableHalf_row">
                      <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                        {item.title}
                      </div>
                      <div className="text-left body-m text-mono-dark-80 flex-1 px-4 py-1 no-wrap-hidden">
                        {index === 0 && (
                          <>
                            {get(listMeasurersDetails, `${item.dataKey}`)
                              ? get(listMeasurersDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 1 && (
                          <>
                            {get(listOfficesDetails, `${item.dataKey}`)
                              ? get(listOfficesDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 2 && (
                          <>
                            {get(listWorkplacesDetails, `${item.dataKey}`)
                              ? get(listWorkplacesDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 3 && (
                          <>
                            {get(
                              measurementResultsDetail,
                              `measurement_result.${item.dataKey}`
                            )
                              ? get(
                                  measurementResultsDetail,
                                  `measurement_result.${item.dataKey}`
                                )
                              : "-"}
                          </>
                        )}
                        {index === 4 && (
                          <>
                            {!!measurementResultsDetail?.measurement_result
                              ?.design_plot_location_type &&
                            !!measurementResultsDetail?.measurement_result
                              ?.design_plot_location_order
                              ? `${
                                  ICON_TYPE[
                                    measurementResultsDetail?.measurement_result
                                      ?.design_plot_location_type
                                  ]
                                }-${
                                  measurementResultsDetail?.measurement_result
                                    ?.design_plot_location_order
                                }`
                              : "-"}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-6">
          メモ
          <TextArea
            onChange={e => setMeasurementResultMemo(e.target.value)}
            onBlur={handleUpdateMemo}
            value={measurementResultMemo}
            placeholder="入力してください。"
            maxLength={200}
            autoSize={{ minRows: 5, maxRows: 5 }}
            showCount
            className={cx(styles.textarea, "mt-2")}
          />
        </div>
      </div>
    </>
  );
};

export default memo(BakuroMeasurementResultsDetail);
