import { memo } from "react";

import { Button } from "antd";
import cx from "classnames";

import styles from "./ModalCustom.module.scss";

type ModalCustomProps = {
  okText?: string;
  isOpen: boolean;
  cancelText?: string;
  className: string;
  children?: React.ReactNode;
  isShowButton?: boolean;
  isDisableButton?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
};

const ModalCustom = ({
  cancelText = "キャンセル",
  okText = "新規登録",
  className = "",
  children,
  isShowButton = false,
  isDisableButton,
  onCancel,
  onOk,
  isOpen,
}: ModalCustomProps) => {
  return (
    <div
      className={cx(className, styles.modal, {
        [styles.isShowModal]: isOpen,
      })}
    >
      <div className="dialog_content">{children}</div>
      <div className="dialog_footer">
        {isShowButton && (
          <div className="text-end">
            <Button className="mr-4" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button disabled={isDisableButton} type="primary" onClick={onOk}>
              {okText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ModalCustom);
