import { useEffect, useMemo, useState } from "react";

import { InputNumber, Radio, RadioChangeEvent } from "antd";
import cx from "classnames";
import dayjs, { Dayjs } from "dayjs";

import ModalConfirm from "@app/components/atoms/ModalConfirm/ModalConfirm";
import DateTimePickerCustom from "@app/components/atoms/TimePickerCustom/DateTimePickerCustom/DateTimePickerCustom";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";

import { dataModalDisplaySettingsType } from "../../Screens/TgraphMeasurersScreen/TgraphMeasurersScreen";
import {
  DATA_DATE_TIME_DISPLAY_SETTING,
  TGRAPH_DATA_DEFAULT,
  TGRAPH_DATA_LIMIT,
} from "../../measurement-results";
import styles from "./ModalDisplaySettings.module.scss";

interface ModalDisplaySettingsProps {
  isOpenModalDisplaySettings: boolean;
  onCloseModalDisplaySettings: () => void;
  setDataModalDisplaySettings: (data: dataModalDisplaySettingsType) => void;
  timeSelectedLimit: {
    startTimeLimit?: string;
    endTimeLimit?: string;
  };
}

const initialStateDataModal = {
  yAxisRangeMin: TGRAPH_DATA_DEFAULT.Y_AXIS_RANGE_MIN,
  yAxisRangeMax: TGRAPH_DATA_DEFAULT.Y_AXIS_RANGE_MAX,
  timeEnd: undefined,
  timeStart: undefined,
  XAxisScale: TGRAPH_DATA_DEFAULT.X_AXIS_SCALE,
  isScale: true,
};

const ModalDisplaySettings = ({
  isOpenModalDisplaySettings,
  onCloseModalDisplaySettings,
  setDataModalDisplaySettings,
  timeSelectedLimit,
}: ModalDisplaySettingsProps) => {
  const [valueRadio, setValueRadio] = useState(1);
  const [dataModal, setDataModal] = useState<dataModalDisplaySettingsType>(
    initialStateDataModal
  );

  const handleChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
    setDataModal({
      ...dataModal,
      XAxisScale: e.target.value,
    });
  };

  const [timeSelected, setTimeSelected] = useState<
    Record<string, Dayjs | undefined>
  >({
    startTime: undefined,
    endTime: undefined,
  });

  useEffect(() => {
    setTimeSelected({
      startTime: dayjs(timeSelectedLimit.startTimeLimit),
      endTime: dayjs(timeSelectedLimit.startTimeLimit)
        .set("hour", 0)
        .set("minute", 0),
    });
  }, [timeSelectedLimit]);

  useEffect(() => {
    setDataModal({
      ...dataModal,
      timeStart:
        timeSelected.startTime && !dataModal.isScale
          ? dayjs(timeSelected.startTime)?.format("YYYY/MM/DD HH:mm:00")
          : undefined,
      timeEnd:
        timeSelected.endTime && !dataModal.isScale
          ? dayjs(timeSelected.startTime)
              .add(timeSelected.endTime.get("hour"), "hour")
              .add(timeSelected.endTime.get("minute"), "minute")
              ?.format("YYYY/MM/DD HH:mm:59")
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelected, dataModal.isScale]);

  const isValidFormDataModal = useMemo(() => {
    return (
      !dataModal.yAxisRangeMax ||
      !dataModal.yAxisRangeMin ||
      (!!dataModal.timeEnd &&
        timeSelected.endTime?.get("hour") === 0 &&
        timeSelected.endTime?.get("minute") === 0)
    );
  }, [dataModal, timeSelected]);

  return (
    <div>
      <ModalConfirm
        isOpen={isOpenModalDisplaySettings}
        cancelText="設定をクリア"
        okText="設定を反映"
        onClose={onCloseModalDisplaySettings}
        onCancel={() => {
          onCloseModalDisplaySettings();
          setDataModal(initialStateDataModal);
          setValueRadio(1);
          setTimeSelected({
            startTime: dayjs(timeSelectedLimit.startTimeLimit),
            endTime: dayjs(timeSelectedLimit.startTimeLimit)
              .set("hour", 0)
              .set("minute", 0),
          });
        }}
        className={cx(styles.diaLog)}
        title=""
        onOk={() => {
          if (isValidFormDataModal) {
            openNotification({
              type: "warning",
              message: MessageError.ERRMSG_002,
            });
          } else {
            onCloseModalDisplaySettings();
            setTimeout(() => {
              setDataModalDisplaySettings(dataModal);
            }, 100);
          }
        }}
      >
        <div className="strip-y">
          <p className="heading-s text-mono-gray-80">Y軸範囲</p>
          <div className="flex-align-center">
            <InputNumber
              value={dataModal.yAxisRangeMin}
              onChange={value =>
                setDataModal({ ...dataModal, yAxisRangeMin: value })
              }
              min={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MIN}
              max={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MAX}
            />
            <span className="px-2">-</span>
            <InputNumber
              value={dataModal.yAxisRangeMax}
              onChange={value =>
                setDataModal({ ...dataModal, yAxisRangeMax: value })
              }
              min={dataModal.yAxisRangeMin}
              max={TGRAPH_DATA_LIMIT.Y_AXIS_RANGE_MAX}
            />
            <span className="font-14 ml-2">dB</span>
          </div>
        </div>
        <div className="body-s text-mono-gray-80 bg-mono-gray-10 width-fit-content p-2 mt-4">
          50dB - 150dBを指定できます。
        </div>
        <div className="strip-x">
          <p className="heading-s text-mono-gray-80">X軸</p>
          <Radio
            checked={!dataModal.isScale}
            onClick={() => setDataModal({ ...dataModal, isScale: false })}
            className="mb-2 bg-mono-gray-10 px-2 py-1 radio-title"
          >
            時間範囲指定
          </Radio>
          <DateTimePickerCustom
            className={cx(styles.dateTimePicker)}
            dataTimePicker={DATA_DATE_TIME_DISPLAY_SETTING}
            defaultTime={timeSelected}
            setTime={time => setTimeSelected(time)}
            timeSelectedLimit={timeSelectedLimit}
            isDisabledTime
            disabled={dataModal.isScale}
          />
        </div>
        <Radio
          checked={dataModal.isScale}
          onClick={() => setDataModal({ ...dataModal, isScale: true })}
          className="bg-mono-gray-10 px-2 py-1 radio-title"
        >
          スケール指定
        </Radio>
        <div className="axis-radio-x">
          <Radio.Group
            onChange={handleChangeRadio}
            disabled={!dataModal.isScale}
            value={dataModal.isScale ? valueRadio : undefined}
          >
            <Radio value={1}>1秒</Radio>
            <Radio value={2}>30秒</Radio>
            <Radio value={3}>1分</Radio>
          </Radio.Group>
        </div>
      </ModalConfirm>
    </div>
  );
};
export default ModalDisplaySettings;
