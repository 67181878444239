/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useMemo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { useWatch } from "antd/lib/form/Form";
import Column from "antd/lib/table/Column";
import cx from "classnames";
import dayjs from "dayjs";
import { get, omit } from "lodash";
import useImage from "use-image";

import Button from "@app/components/atoms/Button/Button";
import { Item, List } from "@app/components/atoms/Form/Form";
import TextTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import ModalConfirm from "@app/components/atoms/ModalConfirm/ModalConfirm";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import {
  MessageError,
  MessageSuccess,
} from "@app/constants/messages.constants";
import { getClassifications } from "@app/features/master/master";
import {
  ACTIONS_MODE,
  getPathUrl,
  CLASSIFICATIONS_HEAD,
  STEP_REPORTS,
} from "@app/features/reports/reports";
import {
  getWorkplaces,
  postWorkplaces,
  updateWorkplaces,
  Workplace,
} from "@app/features/workplaces/workplaces";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import CanvasDesignMeasuringPoint from "./CanvasDesignMeasuringPoint/CanvasDesignMeasuringPoint";
import SelectClassifications from "./SelectClassifications/SelectClassifications";
import styles from "./WorkplacesInfomations.module.scss";

const WorkplacesInfomations = ({
  form,
  handleUploadImage,
}: {
  form: FormInstance;
  handleUploadImage: (callback: () => void) => void;
}) => {
  const { TextArea } = Input;
  const dispatch = useAppDispatch();

  const {
    workplaces: { listWorkplaces },
    reports: { stepReport },
    master: { listClassifications },
  } = useAppSelector((state: RootState) => state);

  const [isOpenCanvas, setIsOpenCanvas] = useState<boolean>(false);
  const [urlImage, setUrlImage] = useState<string | null>(null);
  const [listPlot, setListPlot] = useState<Record<string, number>>({
    A: 0,
    B: 0,
    ば: 0,
  });
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalClassifications, setIsOpenModalClassifications] =
    useState<boolean>(false);

  const office = useWatch("office");
  const watchWorkplace = useWatch("workplace");

  const fileNameDownload = useMemo(() => {
    return `${form.getFieldValue(["office", "name"]) ?? "_"}_${
      form.getFieldValue(["workplace", "name"]) ?? "_"
    }_${dayjs().tz().format("YYYYMMDD")}.png`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, office, watchWorkplace]);

  useEffect(() => {
    if (stepReport === STEP_REPORTS.WORKPLACE) {
      setIsLoading(true);
      Promise.all([dispatch(getWorkplaces()), dispatch(getClassifications())])
        .catch(() => {
          openNotification({ type: "error", message: MessageError.ERRMSG_002 });
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, stepReport]);

  useEffect(() => {
    if (watchWorkplace?.id) {
      setListPlot({
        A: 0,
        B: 0,
        ば: 0,
      });
    }
  }, [watchWorkplace]);

  const clearErrors = () => {
    Object.keys(form.getFieldValue("workplace"))
      .filter(name => form.getFieldError(["workplace", name]).length)
      .forEach(item => {
        form.setFields([
          {
            name: ["workplace", item],
            errors: [],
          },
        ]);
      });
  };

  const handlePostOrUpdateWorkplace = (mode?: string) => {
    setIsSubmitting(true);
    handleUploadImage(() => {
      const data = {
        ...omit(form.getFieldValue("workplace"), "listPlot", "urlImage"),
        office_id: form.getFieldValue(["office", "id"]),
      } as Workplace;

      if (data?.measurement_design) {
        data.measurement_design = {
          ...data.measurement_design,
          workplace_id: data.id,
          rough_sketch_filename: getPathUrl(
            data.measurement_design.rough_sketch_filename
          ),
          background_sketch_filename: getPathUrl(
            data.measurement_design.background_sketch_filename
          ),
        };
      }

      clearErrors();

      const handle =
        mode === ACTIONS_MODE.UPDATE
          ? dispatch(
              updateWorkplaces({
                id: form.getFieldValue(["workplace", "id"]),
                data: omit(data, [
                  "measurement_design.updated_at",
                  "measurement_design.created_at",
                ]) as Workplace,
              })
            )
          : dispatch(
              postWorkplaces(
                omit(data, [
                  "id",
                  "measurement_design.id",
                  "measurement_design.workplace_id",
                ]) as Workplace
              )
            );

      handle
        .then(unwrapResult)
        .then(res => {
          dispatch(getWorkplaces())
            .then(unwrapResult)
            .then(listWp => {
              if (res.id) {
                const workplace = listWp.find(
                  (wp: { id: number }) => wp.id === res.id
                );
                form.setFieldsValue({
                  workplace: workplace ?? null,
                });
              }
              openNotification({
                type: "success",
                message:
                  mode === ACTIONS_MODE.UPDATE
                    ? MessageSuccess.MSG_007
                    : MessageSuccess.MSG_008,
              });
            });
        })
        .catch((err: any) => {
          const { message } = get(err, "errors");
          Object.keys(message).forEach(item => {
            form.setFields([
              {
                name: ["workplace", item],
                errors: [message[item]?.[0]],
              },
            ]);
          });
          openNotification({
            type: "warning",
            message:
              mode === ACTIONS_MODE.UPDATE
                ? MessageError.ERRMSG_009
                : MessageError.ERRMSG_010,
          });
        })
        .finally(() => setIsSubmitting(false));
    });
  };

  const imageCanvas = useMemo(() => {
    if (urlImage) {
      return urlImage;
    }
    return watchWorkplace?.measurement_design?.rough_sketch_filename ?? "";
  }, [watchWorkplace, urlImage]);

  const isDisableInput = useMemo(() => {
    return !isSelectedCheckbox && !watchWorkplace?.id;
  }, [isSelectedCheckbox, watchWorkplace?.id]);

  const [image] = useImage(imageCanvas);

  return (
    <>
      <List name="workplace">
        {() => (
          <>
            {isLoading && <LoadingSpinner />}
            <CanvasDesignMeasuringPoint
              isOpenCanvas={isOpenCanvas}
              fileNameDownload={fileNameDownload}
              handleCloseCanvas={() => setIsOpenCanvas(false)}
              setUrlImage={data => {
                setUrlImage(data);
                form.setFieldsValue({
                  workplace: {
                    urlImage: data,
                  },
                });
              }}
              setListPlot={data => {
                setListPlot(data);
                form.setFieldsValue({
                  workplace: {
                    listPlot: data,
                    measurement_item: {
                      measure_A_value: data.A,
                      measure_B_value: data.B,
                      measure_C_value: data.ば,
                    },
                  },
                });
              }}
              form={form}
            />
            <div className={cx(styles.root, "bg-white border-radius-1")}>
              <div className="pr-4 flex-space-between border-bottom-solid-gray200">
                <Typography className="p-6 font-weight-medium text-mono-dark-80 ">
                  作業場情報入力
                </Typography>
                <div className="flex-align-center gap-16">
                  <Typography className="font-12 font-weight-regular text-mono-dark-80">
                    入力した事業所情報を：
                  </Typography>
                  <Button
                    onClick={() =>
                      handlePostOrUpdateWorkplace(ACTIONS_MODE.UPDATE)
                    }
                    disabled={!watchWorkplace?.id || isSubmitting}
                  >
                    上書き
                  </Button>
                  <Button
                    onClick={() => handlePostOrUpdateWorkplace()}
                    disabled={!isSelectedCheckbox || isSubmitting}
                  >
                    新規登録
                  </Button>
                </div>
              </div>
              <div className="py-8 px-4">
                <Row className="mt-2" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      className="font-12"
                      content="事業所名"
                      hintText={HintText.HINT_007}
                    />
                  </Col>
                  <Col flex="400px">
                    <Input
                      maxLength={30}
                      disabled
                      placeholder="リオン金属"
                      value={office?.name}
                    />
                    <Form.Item
                      className={cx(styles.officeItem)}
                      initialValue={null}
                      name="office_id"
                    />
                  </Col>
                </Row>
                <Row className="mt-8" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      className="font-12"
                      content="作業場名称"
                      hintText={HintText.HINT_012}
                    />
                  </Col>
                  <Col flex="400px">
                    <Form.Item initialValue={null} name="id">
                      <Select
                        onChange={value => {
                          const workplace = listWorkplaces?.find(
                            item => item.id === value
                          );
                          if (workplace) {
                            setUrlImage("");
                            form.resetFields(["workplace"]);
                            form.setFieldsValue({
                              workplace,
                            });
                          }
                        }}
                        onClear={() => {
                          form.resetFields(["workplace"]);
                          setUrlImage("");
                        }}
                        allowClear
                        placeholder="テンプレートから選択"
                        disabled={isSelectedCheckbox}
                      >
                        {listWorkplaces.map(item => {
                          return (
                            <Select.Option value={item.id} key={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-8" gutter={24}>
                  <Col flex="146px" offset={0} />
                  <Col span={9}>
                    <Checkbox
                      onChange={e => {
                        clearErrors();
                        setIsSelectedCheckbox(e.target.checked);
                      }}
                    >
                      <Typography className="font-weight-regular text-mono-dark-80">
                        新しい作業場を入力する
                      </Typography>
                    </Checkbox>
                  </Col>
                </Row>
                <Row className="mt-2" gutter={24}>
                  <Col flex="146px" offset={0} />
                  <Col span={9}>
                    <Form.Item initialValue={null} name="name">
                      <Input maxLength={30} disabled={isDisableInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <div className="text-end font-12">作業場所No</div>
                  </Col>
                  <Col flex="295px">
                    <Form.Item initialValue={null} name="workplace_number">
                      <Input
                        maxLength={10}
                        disabled={isDisableInput}
                        placeholder="入力してください"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      className="font-12"
                      content="ガイドライン別表の号区分"
                      hintText={HintText.HINT_014}
                    />
                  </Col>
                  <Col flex="182px">
                    <div className="px-4 py-2 font-weight-regular font-12 bg-mono-gray-10 text-mono-dark-60">
                      最大3つまで登録できます。
                    </div>
                  </Col>
                </Row>

                {[...new Array(3)].map((_, index) => (
                  <SelectClassifications
                    disabled={isDisableInput}
                    form={form}
                    index={index}
                  />
                ))}

                <Row className="mt-4" gutter={24}>
                  <Col flex="146px" offset={0} />
                  <Col flex="384px">
                    <div className="pl-4 mt-4 font-14 bg-red text-white">
                      ガイドライン別表の号区分がわからない方は
                      <Button
                        onClick={() => setIsOpenModalClassifications(true)}
                        className="text-white"
                        type="text"
                      >
                        <span className="text font-weight-regular font-12 text-white">
                          こちら。
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      className="font-12"
                      content="騒音作業の概要"
                      hintText={HintText.HINT_015}
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item name="noise_summary" initialValue={null}>
                      <TextArea
                        maxLength={110}
                        disabled={isDisableInput}
                        showCount
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-2" gutter={24}>
                  <Col flex="146px" offset={0} />
                  <Col flex="482px">
                    <div className="pl-4 py-2 font-12 bg-mono-gray-10 text-mono-gray-80">
                      「ガイドライン上の区分」を4つ以上登録したい場合、こちらに記入してください。
                    </div>
                  </Col>
                </Row>
                <Row gutter={24} className="mt-8">
                  <Col flex="146px">
                    <div>騒音の種類</div>
                  </Col>
                  <Col flex="400px">
                    <div className="d-flex gap-19">
                      <Form.Item name="constant_noise" valuePropName="checked">
                        <Checkbox
                          disabled={isDisableInput}
                          checked={form.getFieldValue([
                            "workplace",
                            "constant_noise",
                          ])}
                          className="font-12 font-weight-regular text-mono-dark-80"
                        >
                          定常騒音
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="regular_fluctuation"
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={isDisableInput}
                          checked={form.getFieldValue([
                            "workplace",
                            "regular_fluctuation",
                          ])}
                          className="font-12 font-weight-regular text-mono-dark-80"
                        >
                          規則的変動
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="random_fluctuation"
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={isDisableInput}
                          checked={form.getFieldValue([
                            "workplace",
                            "random_fluctuation",
                          ])}
                          className="font-12 font-weight-regular text-mono-dark-80"
                        >
                          不規則変動
                        </Checkbox>
                      </Form.Item>
                      <Form.Item name="impact_noise" valuePropName="checked">
                        <Checkbox
                          disabled={isDisableInput}
                          checked={form.getFieldValue([
                            "workplace",
                            "impact_noise",
                          ])}
                          className="font-12 font-weight-regular text-mono-dark-80"
                        >
                          衝撃音
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-8" gutter={24}>
                  <Col flex="146px">
                    <div className="font-12">作業場の広さ（m2）</div>
                  </Col>
                  <Col flex="240px">
                    <Form.Item initialValue={null} name="extent">
                      <InputNumber
                        disabled={isDisableInput}
                        min={0}
                        max={9999}
                        placeholder="半角英数"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} className="p-0">
                    m2
                  </Col>
                </Row>

                <Row className="mt-8" gutter={24}>
                  <Col flex="166px">
                    <TextTooltip
                      className="font-12"
                      content="単位作業場の範囲決定理由"
                      hintText={HintText.HINT_018}
                    />
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <div className="font-12">（1）騒音の発生状況</div>
                  </Col>
                  <Col flex="886px">
                    <Form.Item initialValue={null} name="noise_sources">
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={90}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <div className="font-12">（2）労働者の行動範囲</div>
                  </Col>
                  <Col flex="886px">
                    <Form.Item
                      initialValue={null}
                      name="range_of_action_for_workers"
                    >
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={90}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <div className="font-12">（3）その他</div>
                  </Col>
                  <Col flex="886px">
                    <Form.Item initialValue={null} name="reason_for_measuring">
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={90}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <TextTooltip
                      className="font-12"
                      content="測定箇所デザイン"
                      hintText={HintText.HINT_022}
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item initialValue={null} name="measurement_design">
                      <div className={cx(styles.imageBox)}>
                        {image && imageCanvas ? (
                          <img
                            src={imageCanvas}
                            style={{
                              width: (image.naturalWidth * 35) / 100,
                              height: (image.naturalHeight * 35) / 100,
                            }}
                            alt="#"
                          />
                        ) : (
                          <>
                            <div className={cx(styles.noImage, "flex-center")}>
                              測定箇所デザインを作成してください。
                            </div>
                          </>
                        )}
                        <Button
                          className="ml-4 font-12"
                          disabled={isDisableInput}
                          onClick={() => setIsOpenCanvas(true)}
                          type="primary"
                        >
                          編集
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-2" gutter={24}>
                  <Col flex="146px" offset={0} />
                  <Col flex="320px">
                    <div className="pl-4 py-2 font-12 bg-mono-gray-10 text-mono-gray-80">
                      作業場の見取り図をアップロードしてください
                    </div>
                  </Col>
                </Row>
                <Row className="mt-8" gutter={24}>
                  <Col flex="146px">
                    <div className="font-12">測定点数</div>
                  </Col>
                  <Col flex="320px">
                    <div className="py-2 flex-space-around font-12 bg-mono-gray-10 text-mono-gray-80">
                      <div>A測定</div>
                      <div>B測定</div>
                      <div>ばく露</div>
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col flex="146px" />
                  <Col flex="320px">
                    <div className="flex-space-around font-12 bg-mono-white text-mono-dark-60 box-shadow">
                      <List name="measurement_item">
                        {() => (
                          <>
                            {Object.keys(listPlot).map(item => (
                              <Item
                                initialValue={null}
                                name={`measure_${item}_value`}
                              >
                                <div className="font-14 text-mono-dark-80 font-weight-regular">
                                  {form.getFieldValue([
                                    "workplace",
                                    "measurement_item",
                                    `measure_${
                                      item === "ば" ? "C" : item
                                    }_value`,
                                  ])}
                                </div>
                              </Item>
                            ))}
                          </>
                        )}
                      </List>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4" gutter={24}>
                  <Col flex="146px" />
                  <Col flex="631px">
                    <div className="py-2 px-4 font-12 bg-mono-gray-10 text-mono-gray-80">
                      「測定箇所デザイン」で指定した測定箇所です。修正したい場合は「測定箇所デザイン」を編集してください。
                    </div>
                  </Col>
                </Row>

                <Row className="mt-8" gutter={24}>
                  <Col flex="146px">
                    <TextTooltip
                      content="A測定の間隔が6ｍ以上の場合、その間隔"
                      className="font-12"
                      hintText={HintText.HINT_024}
                    />
                  </Col>
                  <Col flex="180px">
                    <div>
                      <div className="font-weight-medium font-12 text-mono-gray-80 mb-1">
                        縦方向
                      </div>
                      <div className="flex-align-center gap-8">
                        <Form.Item
                          initialValue={null}
                          name="vertical_spacing_of_A_point"
                        >
                          <InputNumber
                            disabled={isDisableInput}
                            max={99}
                            min={0}
                            placeholder="半角数字"
                          />
                        </Form.Item>
                        <div>m</div>
                      </div>
                    </div>
                  </Col>
                  <Col flex="180px">
                    <div>
                      <div className="font-weight-medium font-12 text-mono-gray-80 mb-1">
                        横方向
                      </div>
                      <div className="flex-align-center gap-8">
                        <Form.Item
                          initialValue={null}
                          name="horizontal_spacing_of_A_point"
                        >
                          <InputNumber
                            disabled={isDisableInput}
                            max={99}
                            min={0}
                            placeholder="半角数字"
                          />
                        </Form.Item>
                        <div>m</div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <div className="font-weight-medium font-12 text-mono-gray-80 mb-1">
                      A測定の数が5未満の場合、その数
                    </div>
                  </Col>
                  <Col flex="156px">
                    <Form.Item name="count_of_A_point">
                      <InputNumber
                        disabled={isDisableInput}
                        min={0}
                        max={5}
                        formatter={value => {
                          if (!value) return "";
                          return String(Math.floor(value));
                        }}
                        placeholder="半角数字"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      content="A測定の数が5未満の場合、配置を決定した理由"
                      className="font-12"
                      hintText={HintText.HINT_025}
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item
                      initialValue={null}
                      name="reason_for_count_of_A_point"
                    >
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={100}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px" offset={0}>
                    <TextTooltip
                      content="B測定の測定点と測定時刻を決定した理由"
                      className="font-12"
                      hintText={HintText.HINT_025} // TODO: wait hint text
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item
                      initialValue={null}
                      name="reason_for_measuring_B_point"
                    >
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={100}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <TextTooltip
                      content="個人ばく露測定が必要と判断した理由"
                      className="font-12"
                      hintText={HintText.HINT_026}
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item
                      initialValue={null}
                      name="reason_for_measuring_noise_exposure"
                    >
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={30}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-8 flex-start" gutter={24}>
                  <Col flex="146px">
                    <TextTooltip
                      content="個人ばく露測定の測定点と測定時刻を決定した理由"
                      className="font-12"
                      hintText={HintText.HINT_027}
                    />
                  </Col>
                  <Col flex="886px">
                    <Form.Item
                      initialValue={null}
                      name="reason_for_design_noise_exposure"
                    >
                      <TextArea
                        showCount
                        disabled={isDisableInput}
                        maxLength={30}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </List>
      <ModalConfirm
        onClose={() => setIsOpenModalClassifications(false)}
        onCancel={() => setIsOpenModalClassifications(false)}
        width={900}
        title=""
        isHiddenButtonSubmit
        isOpen={isOpenModalClassifications}
      >
        <Table
          dataSource={listClassifications}
          className="mt-4 ant-table-cell center"
          bordered
        >
          {CLASSIFICATIONS_HEAD.map(item => (
            <Column
              dataIndex={item.dataIndex}
              width={item.width}
              align="left"
              title={item.text}
              render={value => {
                if (typeof value === "string") {
                  return (
                    <div className="font-14" title={value}>
                      {value}
                    </div>
                  );
                }
                return value;
              }}
            />
          ))}
        </Table>
      </ModalConfirm>
    </>
  );
};

export default memo(WorkplacesInfomations);
