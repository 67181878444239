import { memo, useEffect, useState } from "react";

import { Form, Tabs } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { isEqual } from "lodash";

import { useForm } from "@app/components/atoms/Form/Form";
import ModalCustom from "@app/components/atoms/ModalCustom/ModalCustom";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import {
  ExclusionTemplate,
  postExclusionTemplateSettings,
  updateExclusionTemplateSettings,
} from "@app/features/filters/filters";
import { handleSetTimeByStartTime } from "@app/helpers/dayjs/dayjs.helper";
import { useAppDispatch } from "@app/redux/store";

import { TimeSelectedLimit } from "../../measurement-results";
import ExclusionSetByLevel from "../ExclusionSetByLevel/ExclusionSetByLevel";
import ExclusionSetByTemplate from "../ExclusionSetByTemplate/ExclusionSetByTemplate";
import ExclusionSetByTime from "../ExclusionSetByTime/ExclusionSetByTime";
import styles from "./ModalExclusionSettings.module.scss";

interface ModalExclusionSettingsProps {
  isOpenModalExclusionSettings: boolean;
  onCloseModalExclusionSettings: () => void;
  setModalExclusionSettingsKey: () => void;
  onGetMeasurementDetail: () => void;
  timeSelectedLimit: TimeSelectedLimit;
  filterExclusionSettings?: ExclusionTemplate;
}

const ModalExclusionSettings = ({
  isOpenModalExclusionSettings,
  onCloseModalExclusionSettings,
  onGetMeasurementDetail,
  setModalExclusionSettingsKey,
  timeSelectedLimit,
  filterExclusionSettings,
}: ModalExclusionSettingsProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const listStepExclusionSettings = [
    {
      name: "テンプレートで設定",
      component: <ExclusionSetByTemplate form={form} />,
    },
    {
      name: "時間で設定",
      component: (
        <ExclusionSetByTime
          form={form}
          filterExclusionSettings={filterExclusionSettings}
          timeSelectedLimit={timeSelectedLimit}
        />
      ),
    },
    {
      name: "レベルで設定",
      component: <ExclusionSetByLevel />,
    },
  ];

  useEffect(() => {
    form.setFieldsValue(filterExclusionSettings);
  }, [filterExclusionSettings, form]);

  const handleSubmit = () => {
    const data: ExclusionTemplate = form.getFieldsValue();

    if (isEqual(data, filterExclusionSettings)) {
      setIsSubmitting(false);
      return;
    }

    if (
      (data.valid_start_time &&
        data.valid_end_time &&
        dayjs(timeSelectedLimit.startTimeLimit).isAfter(
          data.valid_start_time
        )) ||
      dayjs(timeSelectedLimit.endTimeLimit).isBefore(data.valid_end_time)
    ) {
      openNotification({
        type: "warning",
        message: MessageError.ERRMSG_002,
      });
      return;
    }

    setIsSubmitting(true);

    const convertData = {
      ...data,
      id: filterExclusionSettings?.id,
      measurement_result_id: filterExclusionSettings?.measurement_result_id,
      filter_periods: data?.filter_periods
        ?.filter(x => x.end_time && x.start_time)
        ?.map(item => {
          return {
            ...item,
            exclusion_template_setting_id: undefined,
            id: undefined,
            start_time: handleSetTimeByStartTime(
              dayjs(item.start_time),
              dayjs(data.valid_start_time)
            ),
            end_time: handleSetTimeByStartTime(
              dayjs(item.end_time),
              dayjs(data.valid_start_time)
            ),
          };
        }),
    };

    Promise.all([
      filterExclusionSettings?.id
        ? dispatch(
            updateExclusionTemplateSettings({
              data: convertData,
              id: filterExclusionSettings.id,
            })
          )
        : dispatch(postExclusionTemplateSettings(convertData)),
    ])
      .then(result => {
        if (result[0]?.payload?.isAxiosError) {
          openNotification({
            type: "warning",
            message: MessageError.ERRMSG_002,
          });
        } else {
          onGetMeasurementDetail();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        onCloseModalExclusionSettings();
      });
  };

  return (
    <ModalCustom
      isOpen={isOpenModalExclusionSettings}
      isShowButton
      cancelText="設定をクリア"
      okText="設定を反映"
      isDisableButton={isSubmitting}
      onCancel={() => {
        onCloseModalExclusionSettings();
        setModalExclusionSettingsKey();
      }}
      className={cx(styles.diaLog)}
      onOk={handleSubmit}
    >
      <div className="label-m">除外設定</div>
      <Form form={form}>
        <Tabs className={cx(styles.tabsReport)}>
          {listStepExclusionSettings.map(item => (
            <Tabs.TabPane forceRender tab={item.name} key={item.name}>
              <div className="pt-3">{item.component}</div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Form>
    </ModalCustom>
  );
};

export default memo(ModalExclusionSettings);
