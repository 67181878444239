import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DesignPlotLocation } from "@app/features/measurers/measurers";

import { ReportsEndpointsEnum } from "../reports";
import { PramsReport, ReportsDataDef } from "../types/reports.types";

// API-RP-010
const createReportApi = ({
  data,
  params,
}: {
  data: ReportsDataDef;
  params: { type: number };
}): Promise<AxiosResponse> => {
  const url = ReportsEndpointsEnum.SAVE_REPORT;
  return api.post(url, data, {
    params,
  });
};

// API-RP-040
const updateReportApi = ({
  data,
  id,
  params,
}: {
  data: ReportsDataDef;
  id: string;
  params: { type: number };
}): Promise<AxiosResponse> => {
  const url = ReportsEndpointsEnum.UPDATE_REPORT.replace(":id", id);
  return api.put(url, data, {
    params,
  });
};

const getReportDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = ReportsEndpointsEnum.GET_REPORT_DETAIL.replace(":id", id);
  return api.get(url);
};

// API-CALC-030
const postEvaluationsApi = ({
  data,
}: {
  data: {
    design_plot_locations: DesignPlotLocation[];
    workplace_id: number;
  };
}): Promise<AxiosResponse> => {
  const url = ReportsEndpointsEnum.POST_EVALUATIONS;
  return api.post(url, data);
};

// API-RP-020
const getReportsApi = (params: PramsReport): Promise<AxiosResponse> => {
  const url = ReportsEndpointsEnum.GET_REPORTS;
  return api.get(url, { params });
};

const reportsApi = {
  createReportApi,
  getReportDetailApi,
  updateReportApi,
  getReportsApi,
  postEvaluationsApi,
};

export default reportsApi;
