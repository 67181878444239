import { memo } from "react";

import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import cx from "classnames";

type TextTooltipProps = {
  content: string;
  hintText?: string;
  className?: string;
  colorIcon?: string;
  placement?: TooltipPlacement;
};

const HintTooltip = ({
  content,
  hintText,
  className = "font-12 font-weight-bold text-dark-60-alpha",
  colorIcon = "text-primary-main",
  placement = "top",
}: TextTooltipProps) => {
  return (
    <div className="flex-align-center">
      <div className={cx(className, "mr-1 font-12")}>{content}</div>
      <Tooltip placement={placement} title={hintText}>
        <span className={`icon-help font-16 hover-pointer ${colorIcon}`} />
      </Tooltip>
    </div>
  );
};

export default memo(HintTooltip);
