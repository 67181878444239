import { memo, useMemo } from "react";

import { Col, Form, Input, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";

import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

const SelectMeasurers = ({
  index,
  form,
}: {
  index: number;
  form: FormInstance;
}) => {
  const { listMeasurers } = useAppSelector(
    (state: RootState) => state.measurers
  );

  const watchMeasurers = Form.useWatch("measurers");

  const listMeasurersFilter = useMemo(() => {
    return [...listMeasurers].filter(item => {
      if (item.id !== form.getFieldValue(["measurers", index, "id"])) {
        return !form
          .getFieldValue("measurers")
          ?.find((x: { id: number }) => x?.id === item.id);
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, index, listMeasurers, watchMeasurers]);

  const fillWorker = (value: number, idx: number) => {
    const measurers = [...listMeasurers]?.find(item => item.id === value);
    const measureList = form.getFieldValue("measurers");
    if (measurers && measureList) {
      measureList[idx].registration_number = measurers.registration_number;
      measureList[idx].order = idx + 1;
      measureList[idx].name = measurers.name;
      form.setFieldsValue({
        measurers: measureList,
      });
    }
  };

  return (
    <div>
      <Row gutter={26} className="mt-8">
        <Col flex="175px" />
        <Col flex="240px">
          <div>
            <div className="font-weight-medium text-mono-dark-60 font-12 mb-1">
              担当者 {index + 1}
            </div>
            <Form.Item initialValue={null} name={[index, "id"]}>
              <Select
                allowClear
                onClear={() => {
                  const list = form.getFieldValue("measurers");
                  list[index].registration_number = null;
                  form.setFieldsValue({ measurers: list });
                }}
                onChange={value => {
                  fillWorker(value, index);
                }}
                placeholder="選択してください"
              >
                {listMeasurersFilter.map(item => {
                  return (
                    <Select.Option value={item?.id} key={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col flex="160px">
          <div>
            <div className="font-weight-medium font-12 text-mono-dark-60 mb-1">
              登録番号
            </div>
            <Form.Item
              initialValue={null}
              name={[index, "registration_number"]}
            >
              <Input readOnly disabled />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(SelectMeasurers);
