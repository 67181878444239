import { memo } from "react";

import elippse from "@app/assets/images/elippse.png";
import point1 from "@app/assets/images/point1.png";
import point2 from "@app/assets/images/point2.png";
import point3 from "@app/assets/images/point3.png";
import point4 from "@app/assets/images/point4.png";
import point5 from "@app/assets/images/point5.png";
import { ReportsDataDef } from "@app/features/reports/reports";

const PreviewReportsPage3 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  return (
    <div id="page-3" className="page-a4 mx-Auto mb-5 px-6">
      <div className="heading-m pt-3 mb-2 header-reports-number flex-space-between width-fit-content ml-Auto font-12">
        <span>記録表番号</span>
        <span>{dataReport?.record_sheet_number}</span>
      </div>
      <div className="heading-m">
        ５．単位作業場所の範囲、主な設備、発生源、測定点の配置等を示す図面
      </div>
      <div className="heading-s mt-3 text-end width250 width-fit-content ml-Auto font-12 flex-space-between">
        <span>作業場名</span>
        <span>{dataReport?.workplace?.name}</span>
      </div>
      <div className="mt-4">
        <img
          width="100%"
          crossOrigin={
            !localStorage.getItem("dataReport") ? "anonymous" : undefined
          }
          style={{ maxHeight: 400, objectFit: "contain" }}
          src={
            dataReport?.workplace?.urlImage ??
            dataReport?.workplace?.measurement_design?.rough_sketch_filename ??
            undefined
          }
          alt=""
        />
      </div>
      <div className="heading-s mt-4">凡例</div>
      <table>
        <colgroup>
          <col span={1} className="bg-mono-dark-10" />
        </colgroup>
        <tr>
          <td height={30} colSpan={2}>
            <div className="text-left pl-4 font-14">アイコン</div>
          </td>
        </tr>
        <tr>
          <td height={40} width={80}>
            <img width={30} src={point1} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              騒音の発生源
            </div>
          </td>
        </tr>
        <tr>
          <td height={40}>
            <img width={30} src={point2} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              A測定の配置箇所
            </div>
          </td>
        </tr>
        <tr>
          <td height={40}>
            <img width={30} src={point3} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              B測定の配置箇所
            </div>
          </td>
        </tr>
        <tr>
          <td height={40}>
            <img width={30} src={point4} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              個人ばく露測定を行った作業者の主な作業位置
            </div>
          </td>
        </tr>
        <tr>
          <td height={40}>
            <img width={30} src={point5} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              その他作業者の主な作業位置
            </div>
          </td>
        </tr>
        <tr>
          <td height={40}>
            <img width={50} src={elippse} alt="" />
          </td>
          <td>
            <div className="text-left font-weight-medium mt-2 ml-2 font-14">
              騒音の発生源や、作業者が移動する範囲を示す枠
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default memo(PreviewReportsPage3);
