import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import cx from "classnames";
import { get } from "lodash";
import { useParams } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import { getMeasurersDetails } from "@app/features/measurers/measurers";
import { getOfficesDetails } from "@app/features/offices/offices";
import { ICON_TYPE } from "@app/features/reports/reports";
import { getWorkplacesDetail } from "@app/features/workplaces/workplaces";
import { convertMinuteToHour, numberFormat } from "@app/helpers/util.helper";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  DATA_VALUE_SONKIE,
  REPORT_LINK_STATUS_TYPE,
  WORKSHOP_SOONKEI_INFORMATION,
} from "../../constants/measurement-results.constants";
import {
  getMeasurementResultsDetail,
  MeasurementResultsDetail,
  UpdateMeasurementResult,
} from "../../measurement-results";
import styles from "../BakuroMeasurementResultsDeatail/BakuroMeasurementResultsDetailScreen.module.scss";

const SoonkeiMeasurementResultsDetailScreen = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(getMeasurementResultsDetail(id))
        .then(unwrapResult)
        .then((data: MeasurementResultsDetail) => {
          Promise.all([
            data.measurement_result?.workplace_id &&
              dispatch(
                getWorkplacesDetail(
                  String(data.measurement_result.workplace_id)
                )
              ),
            data.measurement_result?.measurer_id &&
              dispatch(
                getMeasurersDetails(String(data.measurement_result.measurer_id))
              ),
            data.measurement_result?.office_id &&
              dispatch(
                getOfficesDetails(String(data.measurement_result.office_id))
              ),
          ]).finally(() => {
            setIsLoading(false);
          });
        }),
    ]).catch(() => {
      setIsLoading(false);
      openNotification({
        type: "warning",
        message: MessageError.ERRMSG_002,
      });
    });
  }, [dispatch, id]);

  const {
    offices: { listOfficesDetails },
    workplaces: { listWorkplacesDetails },
    measurers: { listMeasurersDetails },
    measurement_results: { measurementResultsDetail },
  } = useAppSelector(state => state);

  const [measurementResultMemo, setMeasurementResultMemo] =
    useState<string>("");

  useEffect(() => {
    setMeasurementResultMemo(
      `${measurementResultsDetail?.measurement_result.memo ?? ""}`
    );
  }, [measurementResultsDetail?.measurement_result.memo]);

  const handleUpdateMemo = () => {
    setIsLoading(true);
    dispatch(
      UpdateMeasurementResult({
        MeasurementResultId: String(
          measurementResultsDetail?.measurement_result.id
        ),
        data: {
          memo: measurementResultMemo,
        },
      })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={cx(styles.listTable, "px-6 py-6")}>
        <div className="bg-white px-4 py-4 flex-space-between">
          <div className="d-flex gap-24">
            <div>
              <div className="font-12 text-mono-dark-40 ">型式</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result.device_model ??
                  "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40 ">製造番号</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result
                  .device_serial_no ?? "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40 ">管理名称</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {measurementResultsDetail?.measurement_result
                  ?.device_management_name ?? "-"}
              </div>
            </div>
            <div>
              <div className="font-12 text-mono-dark-40 ">報告書ステータス</div>
              <div className="font-weight-medium font-18 width150 no-wrap-hidden">
                {REPORT_LINK_STATUS_TYPE[
                  Number(
                    measurementResultsDetail?.measurement_result
                      .report_link_status
                  )
                ] ?? "-"}
              </div>
            </div>
          </div>
        </div>
        <Row className="mt-6" gutter={26}>
          <Col flex={802}>
            <div className="heading-m mb-2 text-mono-dark-80">データ値</div>
            <div className="p-6 bg-white">
              <div className={cx(styles.tableHalf, "mt-2")}>
                {DATA_VALUE_SONKIE.map((item, index) => {
                  const valueDataKey = get(
                    measurementResultsDetail,
                    `measurement_result.${item.dataKey.name}`
                  );
                  const valueDataKey1 = get(
                    measurementResultsDetail,
                    `measurement_result.${item.dataKey1.name}`
                  );
                  return (
                    <>
                      <div className="tableHalf_row">
                        <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                          {item.title1}
                        </div>
                        <div className="text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden">
                          {index === 0 && (
                            <>
                              {`${valueDataKey || "-"}${
                                item.dataKey.format ?? ""
                              }`}
                            </>
                          )}
                          {index === 1 && (
                            <>
                              {numberFormat(valueDataKey) ?? "-"}
                              {item.dataKey.format}
                            </>
                          )}
                          {index === 2 && (
                            <>
                              {`${convertMinuteToHour(
                                valueDataKey,
                                " h",
                                " m"
                              )}`}
                            </>
                          )}
                        </div>
                        <>
                          <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                            {item.title2}
                          </div>
                          <div className="text-left body-m text-mono-dark-80 flex-1 px-4 text-left py-1 no-wrap-hidden">
                            {valueDataKey1 ?? "-"}
                          </div>
                        </>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col flex={492}>
            <div className="mb-2 heading-m text-mono-dark-80">作業場情報</div>
            <div className="p-6 bg-white">
              <div className={cx(styles.tableHalf)}>
                {WORKSHOP_SOONKEI_INFORMATION.map((item, index) => (
                  <>
                    <div className="tableHalf_row">
                      <div className="heading-s text-dark-60-alpha bg-mono-dark-10 flex-1 py-2">
                        {item.title}
                      </div>
                      <div className="text-left body-m text-mono-dark-80 flex-1 px-4 py-1 no-wrap-hidden">
                        {index === 0 && (
                          <>
                            {get(listMeasurersDetails, `${item.dataKey}`)
                              ? get(listMeasurersDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 1 && (
                          <>
                            {get(listOfficesDetails, `${item.dataKey}`)
                              ? get(listOfficesDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 2 && (
                          <>
                            {get(listWorkplacesDetails, `${item.dataKey}`)
                              ? get(listWorkplacesDetails, `${item.dataKey}`)
                              : "-"}
                          </>
                        )}
                        {index === 3 && "-"}
                        {index === 4 && (
                          <>
                            {!!measurementResultsDetail?.measurement_result
                              ?.design_plot_location_type &&
                            !!measurementResultsDetail?.measurement_result
                              ?.design_plot_location_order
                              ? `${
                                  ICON_TYPE[
                                    measurementResultsDetail?.measurement_result
                                      ?.design_plot_location_type
                                  ]
                                }-${
                                  measurementResultsDetail?.measurement_result
                                    ?.design_plot_location_order
                                }`
                              : "-"}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-6">
          メモ
          <TextArea
            onChange={e => setMeasurementResultMemo(e.target.value)}
            onBlur={handleUpdateMemo}
            value={measurementResultMemo}
            placeholder="入力してください。"
            maxLength={200}
            autoSize={{ minRows: 5, maxRows: 5 }}
            showCount
            className={cx(styles.textarea, "mt-2")}
          />
        </div>
      </div>
    </>
  );
};

export default memo(SoonkeiMeasurementResultsDetailScreen);
