import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { MeasuringDeviceEnum } from "../contants/measuring-device.endpoints";

// API-MDT-010
const getMeasuringDeviceTypeApi = (params: {
  type: number;
}): Promise<AxiosResponse> => {
  const url = MeasuringDeviceEnum.GET_MEASURING_DEVICE_TYPE;
  return api.get(url, { params });
};

const measuringDeviceApi = {
  getMeasuringDeviceTypeApi,
};

export default measuringDeviceApi;
