import { combineReducers } from "@reduxjs/toolkit";

import { authReducer, AUTH_FEATURE_KEY } from "@app/features/auth/auth";
import {
  filtersReducer,
  FILTERS_FEATURE_KEY,
} from "@app/features/filters/filters";
import { masterReducer, MASTER_FEATURE_KEY } from "@app/features/master/master";
import {
  measurementResultsReducer,
  MEASUREMENT_RESULTS_FEATURE_KEY,
} from "@app/features/measurement_results/measurement-results";
import {
  MEASURERS_FEATURE_KEY,
  measurersReducer,
} from "@app/features/measurers/measurers";
import {
  measuringDeviceReducer,
  MEASURING_DEVICE_KEY,
} from "@app/features/measuring_device_types/measuring-device";
import {
  officesReducer,
  OFFICES_FEATURE_KEY,
} from "@app/features/offices/offices";
import {
  reportsReducer,
  REPORTS_FEATURE_KEY,
} from "@app/features/reports/reports";
import {
  workplacesReducer,
  WORKPLACES_FEATURE_KEY,
} from "@app/features/workplaces/workplaces";

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [REPORTS_FEATURE_KEY]: reportsReducer,
  [MEASURERS_FEATURE_KEY]: measurersReducer,
  [OFFICES_FEATURE_KEY]: officesReducer,
  [WORKPLACES_FEATURE_KEY]: workplacesReducer,
  [MASTER_FEATURE_KEY]: masterReducer,
  [MEASUREMENT_RESULTS_FEATURE_KEY]: measurementResultsReducer,
  [FILTERS_FEATURE_KEY]: filtersReducer,
  [MEASURING_DEVICE_KEY]: measuringDeviceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
