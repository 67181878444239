export const listPath = [
  {
    name: "すべての報告書",
    path: "/reports",
  },
  {
    name: "報告書の作成・修正",
    path: "",
  },
];
