import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DesignPlotLocation } from "@app/features/measurers/measurers";

import reportsApi from "../api/reports.api";
import { REPORTS_FEATURE_KEY, STEP_REPORTS } from "../reports";
import {
  ListReportsData,
  PramsReport,
  Evaluations,
  ReportsDataDef,
} from "../types/reports.types";

interface initialStateType {
  isChangeForm: boolean;
  reportDetail: ReportsDataDef | null;
  isSortPlotLocations: boolean;
  listReports: ListReportsData | null;
  urlImageCanvasUpload: {
    imageCanvas: File;
    imageBackground: File;
  } | null;
  isEditCanvas: boolean;
  stepReport: number;
  evaluations: Evaluations | null;
}

const initialState: initialStateType = {
  isChangeForm: false,
  reportDetail: null,
  isSortPlotLocations: false,
  listReports: null,
  urlImageCanvasUpload: null,
  isEditCanvas: false,
  stepReport: STEP_REPORTS.BASIC_INFORMATIONS,
  evaluations: null,
};

// API-RP-010
export const createReport = createAsyncThunk(
  `${REPORTS_FEATURE_KEY}/createReport`,
  async (
    { data, params }: { data: ReportsDataDef; params: { type: number } },
    { rejectWithValue }
  ) => {
    try {
      const response = await reportsApi.createReportApi({ data, params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-RP-040
export const updateReport = createAsyncThunk(
  `${REPORTS_FEATURE_KEY}/updateReport`,
  async (
    {
      data,
      id,
      params,
    }: { data: ReportsDataDef; id: string; params: { type: number } },
    { rejectWithValue }
  ) => {
    try {
      if (!data.is_save_address_for_submission) {
        data.address_for_submission = null;
      }
      const response = await reportsApi.updateReportApi({ data, id, params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-RP-030
export const getReportDetail = createAsyncThunk(
  `${REPORTS_FEATURE_KEY}/getReportDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getReportDetailApi(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-CALC-030
export const postEvaluations = createAsyncThunk(
  `${REPORTS_FEATURE_KEY}/postEvaluations`,
  async (
    {
      data,
    }: {
      data: {
        design_plot_locations: DesignPlotLocation[];
        workplace_id: number;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await reportsApi.postEvaluationsApi({ data });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-RP-020
export const getReports = createAsyncThunk(
  `${REPORTS_FEATURE_KEY}/getReports`,
  async (params: PramsReport, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getReportsApi(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const reportsSlice = createSlice({
  name: REPORTS_FEATURE_KEY,
  initialState,
  reducers: {
    updateIsChangeForm: (state, actions) => {
      state.isChangeForm = actions.payload;
    },
    updateIsSortPlotLocations: (state, actions) => {
      state.isSortPlotLocations = actions.payload;
    },
    updateUrlImageCanvasUpload: (state, actions) => {
      state.urlImageCanvasUpload = actions.payload;
    },
    updateIsEditCanvas: (state, actions) => {
      state.isEditCanvas = actions.payload;
    },
    updateStepReport: (state, actions) => {
      state.stepReport = actions.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getReportDetail.pending, state => {
      state.reportDetail = null;
    });
    builder.addCase(getReportDetail.fulfilled, (state, action) => {
      const { measurers } = action.payload;
      const convertMeasurers = [...new Array(5)].map((_, index) => {
        const measurer = measurers?.find(
          (item: { order: number }) => item.order === index + 1
        );
        if (measurer) {
          return measurer;
        }
        return { id: null };
      });
      state.reportDetail = { ...action.payload, measurers: convertMeasurers };
    });
    builder.addCase(getReportDetail.rejected, state => {
      state.reportDetail = null;
    });

    builder.addCase(getReports.fulfilled, (state, action) => {
      state.listReports = action.payload;
    });
    builder.addCase(getReports.rejected, state => {
      state.listReports = null;
    });

    builder.addCase(postEvaluations.pending, state => {
      state.evaluations = null;
    });
    builder.addCase(postEvaluations.rejected, state => {
      state.evaluations = null;
    });
    builder.addCase(postEvaluations.fulfilled, (state, action) => {
      state.evaluations = action.payload;
    });
  },
});

export const {
  updateIsChangeForm,
  updateIsSortPlotLocations,
  updateUrlImageCanvasUpload,
  updateIsEditCanvas,
  updateStepReport,
} = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
