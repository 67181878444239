import { memo } from "react";

import { Typography } from "antd";
import cx from "classnames";

import styles from "./StepReport.module.scss";

interface StepReportsProps {
  title?: string;
}
const StepReports = ({ title }: StepReportsProps) => {
  return (
    <div className={cx(styles.list)}>
      <Typography className="font-weight-medium text-mono-dark-80">
        {title}
      </Typography>
    </div>
  );
};

export default memo(StepReports);
