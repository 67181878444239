// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import generatePicker from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;
