import { memo, ReactNode, useState, useEffect, useMemo } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Pagination, Select, Table, Typography } from "antd";
import cx from "classnames";
import { stringify } from "qs";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as LinkIcon } from "@app/assets/images/icons/Link.svg";
import NoData from "@app/assets/images/no-data.png";
import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Tag from "@app/components/atoms/Tag/Tag";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import { TAG_STYLE } from "@app/features/measurers/measurers";
import {
  getQueryParams,
  setQueryParams,
} from "@app/helpers/queryParams/queryParams";
import { numberCount } from "@app/helpers/util.helper";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "../../../reports/components/ListReports/ListReports.module.scss";
import { MeasurementResultPathsEnum } from "../../constants/measurement-results.paths";
import {
  WINDOWS_APP_STYLE,
  MEASUREMENT_RESULTS_TABLE_HEAD,
  MEASUREMENTS_RESULTS_SELECTED_TYPE,
  getListMeasurementResults,
  MEASUREMENTS_RESULTS_NUMBER,
  DEVICE_TYPE,
  updateListIdMeasurementSelected,
  FILTED_STATUS_TYPE,
  REPORT_LINK_STATUS_TYPE,
  MEASUREMENTS_RESULTS_SELECTED_VALUE,
} from "../../measurement-results";

interface DataMeasurementResults {
  key: React.Key;
  type?: ReactNode;
  device_model: number | string;
  device_serial_no: number | string;
  device_management_name: string;
  filted_status: string;
  report_link_status: string | null;
  measure_start_datetime: string;
  measurement_interval: string;
  workplace_name?: string;
  laeq: string | number;
  dose: number | string | undefined;
  over: ReactNode;
  device_type?: number;
}
const MeasurementsResultsScreen = () => {
  const { page, type } = getQueryParams(window.location.search);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { measurementResultsPaginate, listIdMeasurementSelected } =
    useAppSelector(state => state.measurement_results);

  const [isLoading, setIsLoading] = useState(false);
  const [valueSelected, setValueSelected] = useState(
    MEASUREMENTS_RESULTS_SELECTED_VALUE[Number(type)]
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getListMeasurementResults({
        page: page ? Number(page) : 1,
        type: Number(type) ? Number(type) : undefined,
      })
    )
      .then(unwrapResult)
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, page, type]);

  const dataListMeasurementResults = useMemo(() => {
    const data: DataMeasurementResults[] = [];
    measurementResultsPaginate?.measurementResults?.forEach(item => {
      data.push({
        device_type: item.type,
        key: `${item.id}`,
        type: item.type ? (
          <Tag
            color={TAG_STYLE[item.type].color}
            backgroundColor={TAG_STYLE[item.type].backgroundColor}
          >
            {TAG_STYLE[item.type].text}
          </Tag>
        ) : (
          "-"
        ),
        device_model: item.device_model ? `${item.device_model}` : "-",
        device_serial_no: `${
          item?.device_serial_no ? item?.device_serial_no : "-"
        }`,
        device_management_name: `${
          item.device_management_name ? item.device_management_name : "-"
        }`,
        filted_status: item?.filted_status
          ? FILTED_STATUS_TYPE[item.filted_status]
          : "-",
        report_link_status: item?.report_link_status
          ? REPORT_LINK_STATUS_TYPE[item.report_link_status]
          : "-",
        workplace_name: `${item?.workplace?.name ?? "-"}`,
        measure_start_datetime: `${item.start_time ? item.start_time : "-"}`,
        measurement_interval: item?.measurement_interval ?? "-",
        laeq: item?.laeq ? ` ${item?.laeq}dB` : "-",
        dose: item.type === DEVICE_TYPE.BAKURO ? item?.dose : "-",
        over: (
          <>
            <Tag
              color={WINDOWS_APP_STYLE[1].color}
              backgroundColor={WINDOWS_APP_STYLE[1].backgroundColor}
              className={cx(styles.over)}
              isDisable={!item.over}
              fontSize="14px"
            >
              {WINDOWS_APP_STYLE[1].text}
            </Tag>
            <Tag
              color={WINDOWS_APP_STYLE[2].color}
              backgroundColor={WINDOWS_APP_STYLE[2].backgroundColor}
              className={cx(styles.over, "ml-2 mr-2")}
              isDisable={!item.under}
              fontSize="14px"
            >
              {WINDOWS_APP_STYLE[2].text}
            </Tag>
            <Tag
              color={WINDOWS_APP_STYLE[3].color}
              backgroundColor={WINDOWS_APP_STYLE[3].backgroundColor}
              className={cx(styles.over)}
              isDisable={!item.impact}
              fontSize="14px"
            >
              {WINDOWS_APP_STYLE[3].text}
            </Tag>
          </>
        ),
      });
    });
    return data;
  }, [measurementResultsPaginate?.measurementResults]);

  const { Column } = Table;
  const { Option } = Select;

  const [arraySelect, setArraySelect] = useState<React.Key[]>(
    listIdMeasurementSelected
  );

  const handlePageMeasurementsResults = (
    pageNumber: number,
    typeValue?: string
  ) => {
    history.push({
      pathname: MeasurementResultPathsEnum.MEASUREMENT_RESULTS,
      search: setQueryParams({
        page: pageNumber,
        type: typeValue ? Number(typeValue) : undefined,
      }),
    });
  };

  const onSelectMeasurementsResults = (row: DataMeasurementResults) => {
    let listSelected = [...arraySelect];
    if (arraySelect.includes(row.key)) {
      listSelected = listSelected.filter(id => id !== row.key);
    } else {
      listSelected.push(row.key);
    }
    setArraySelect(listSelected);
    dispatch(updateListIdMeasurementSelected(listSelected));
  };

  const { numberCountStart, numberCountEnd } = useMemo(() => {
    if (!measurementResultsPaginate)
      return {
        numberCountStart: 0,
        numberCountEnd: 0,
      };
    const { limit, totalCount } = measurementResultsPaginate;
    return numberCount(measurementResultsPaginate.page, limit, totalCount);
  }, [measurementResultsPaginate]);

  const rowSelection = {
    onSelect: onSelectMeasurementsResults,
    getCheckboxProps: (selectedRowKeys: DataMeasurementResults) => {
      return {
        disabled:
          selectedRowKeys.device_type === DEVICE_TYPE.SOONKEI ||
          (!arraySelect.includes(selectedRowKeys.key) &&
            arraySelect.length >= MEASUREMENTS_RESULTS_NUMBER.MAX_SELECTED),
      };
    },
    selectedRowKeys: arraySelect,
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={cx(styles.listTable)}>
        <div className="px-6 py-4">
          <div className="flex-space-between">
            <div className="flex-align-center">
              <Typography className="mr-4 font-16 font-weight-medium text-mono-dark-80">
                測定データ
              </Typography>
              <Form.Item initialValue={null}>
                <Select
                  value={valueSelected}
                  defaultValue={MEASUREMENTS_RESULTS_SELECTED_TYPE.すべて}
                  onChange={value => {
                    handlePageMeasurementsResults(1, value);
                    setValueSelected(
                      value ?? MEASUREMENTS_RESULTS_SELECTED_TYPE.すべて
                    );
                  }}
                  allowClear
                  placeholder="ばく露計"
                >
                  <Option value={MEASUREMENTS_RESULTS_SELECTED_TYPE.すべて}>
                    すべて
                  </Option>
                  <Option value={MEASUREMENTS_RESULTS_SELECTED_TYPE.騒音}>
                    騒音
                  </Option>
                  <Option value={MEASUREMENTS_RESULTS_SELECTED_TYPE.ばく露}>
                    ばく露
                  </Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <div className="font-weight-bold">
              {numberCountStart || ""} - {numberCountEnd || ""} 件
            </div>
            <div> （全 {measurementResultsPaginate?.totalCount} 件）</div>
          </div>

          {measurementResultsPaginate?.measurementResults?.length ? (
            <>
              <Table
                onRow={row => {
                  return {
                    onClick: () => {
                      if (row.device_type === DEVICE_TYPE.BAKURO) {
                        history.push(
                          MeasurementResultPathsEnum.BAKURO_MEASUREMENT_RESULTS_DETAILS.replace(
                            ":id",
                            String(row.key)
                          )
                        );
                      }
                      if (row.device_type === DEVICE_TYPE.SOONKEI) {
                        history.push(
                          MeasurementResultPathsEnum.SOONKEI_MEASUREMENT_RESULTS_DETAILS.replace(
                            ":id",
                            String(row.key)
                          )
                        );
                      }
                    },
                  };
                }}
                rowSelection={rowSelection}
                dataSource={dataListMeasurementResults}
                className={cx(
                  styles.listTableMeasurement,
                  "mt-4 ant-table-cell center"
                )}
              >
                {MEASUREMENT_RESULTS_TABLE_HEAD.map(item => (
                  <Column
                    title={item.text}
                    dataIndex={item.dataIndex}
                    width={item.width ?? "100px"}
                    align="center"
                    render={value => {
                      if (typeof value === "string") {
                        return <div title={value}>{value}</div>;
                      }
                      return value;
                    }}
                  />
                ))}
              </Table>
              <div className="mt-2">
                {measurementResultsPaginate &&
                measurementResultsPaginate?.totalCount <=
                  MEASUREMENTS_RESULTS_NUMBER.COUNT ? (
                  ""
                ) : (
                  <Pagination
                    showLessItems
                    current={page ? Number(page) : 1}
                    total={measurementResultsPaginate?.totalCount}
                    onChange={pages => {
                      handlePageMeasurementsResults(pages, type as string);
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="px-4 py-6">
                <div className="position-center text-center">
                  <div>
                    <img className="img-reports" src={NoData} alt="#" />
                  </div>

                  <div className="mt-7">
                    <Typography className="font-16 font-weight-regular text-primary-main">
                      データがありません。
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {arraySelect.length > 0 && (
          <>
            <div
              className={cx(
                styles.countBottom,
                "bg-white",
                "flex-space-between"
              )}
            >
              <div className="flex-align-center">
                {arraySelect.length} 件 選択中
              </div>
              <div className="d-flex gap-16 ">
                <div>
                  <Link
                    to={{
                      pathname:
                        MeasurementResultPathsEnum.MEASUREMENT_RESULTS_T_GRAPH_SCREEN,
                      search: stringify(
                        { listId: arraySelect },
                        {
                          arrayFormat: "brackets",
                          encode: false,
                        }
                      ),
                    }}
                    target="_blank"
                  >
                    <Button type="primary">
                      <div className="flex-center">
                        <div className="font-12 font-weight-regular">
                          一括でT-Lグラフを表示
                        </div>
                        <LinkIcon className="ml-2" />
                      </div>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default memo(MeasurementsResultsScreen);
