import { memo } from "react";

import cx from "classnames";
import dayjs from "dayjs";
import { get } from "lodash";

import { DATE_TIME_FORMAT } from "@app/features/measurement_results/measurement-results";
import {
  CLASSIFY,
  COUNT_WORKPLACE_TYPE,
  EVALUATION_TOTAL_TYPE,
  ReportsDataDef,
} from "@app/features/reports/reports";
import { convertJapaneseCalendar } from "@app/helpers/file.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import styles from "./PreviewReportsPage1.module.scss";

const PreviewReportsPage1 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  const { evaluations } = useAppSelector((state: RootState) => state.reports);

  return (
    <div id="page-1" className="page-a4 mx-Auto mb-5 position-Relative px-6">
      <section>
        <p className="label-reports">保存３年</p>
        <div className="heading-l font-20 font-weight-bold text-center pt-8">
          作業環境測定結果報告書（証明書）
        </div>
        <div>
          <div className="heading-m text-end mt-2">
            {dayjs(new Date())
              .tz()
              .format(
                ` ${convertJapaneseCalendar(
                  dayjs().format(DATE_TIME_FORMAT),
                  true
                )} MM 月 DD 日`
              )}
          </div>
          <div className="heading-m ml-Auto bg-mono-dark-10 width-fit-content flex-space-between certificate-reports">
            <span>報告書（証明書）番号</span>
            <span>{dataReport?.report_number}</span>
          </div>
        </div>
      </section>

      <section>
        {dataReport?.address_for_submission && (
          <div
            style={{
              minWidth: 150,
              width: "fit-content",
              lineHeight: "10px",
              borderBottom: "1px solid",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="text-end heading-l"
          >
            <p className="mr-2">{dataReport?.address_for_submission}</p>
            <p>殿</p>
          </div>
        )}

        <div className="pl-4 mt-3">
          {dataReport?.address_for_submission && (
            <div className="label-s">
              貴事業場より委託を受けた作業環境測定の結果は、下記及び別紙作業環境測定結果記録表に記載したとおりであることを証明します。
            </div>
          )}

          <div className="label-s mt-2 font-weight-bold">
            測定を実施した作業環境測定機関
          </div>
        </div>

        <table className="table-content-lef">
          <tr>
            <td className="bg-mono-dark-10" colSpan={1}>
              名称
            </td>
            <td colSpan={3}>
              {dataReport?.measurement_designer?.measurement_company?.name ??
                ""}
            </td>
          </tr>
          <tr>
            <td className="bg-mono-dark-10" colSpan={1}>
              代表者職氏名
            </td>
            <td colSpan={3}>
              {
                dataReport?.measurement_designer?.measurement_company
                  ?.representative_job
              }
              &emsp;
              {
                dataReport?.measurement_designer?.measurement_company
                  ?.representative_name
              }
            </td>
          </tr>
          <tr style={{ height: 35, verticalAlign: "bottom" }}>
            <td className="bg-mono-dark-10" colSpan={1}>
              所在地 <br /> (TEL,FAX)
            </td>
            <td colSpan={3}>
              <div>
                {dataReport?.measurement_designer?.measurement_company?.address}
              </div>
              <div>
                <span>
                  TEL:{" "}
                  {
                    dataReport?.measurement_designer?.measurement_company
                      ?.telephone_number
                  }
                </span>
                <span className="ml-8">
                  FAX:{" "}
                  {
                    dataReport?.measurement_designer?.measurement_company
                      ?.fax_number
                  }
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="bg-mono-dark-10" width={120}>
              登録番号
            </td>
            <td width={90}>
              {
                dataReport?.measurement_designer?.measurement_company
                  ?.registration_number
              }
            </td>
            <td width={130} className="bg-mono-dark-10 pl-2">
              統一精度管理の参加
            </td>
            <td className="pl-1">
              {dataReport?.measurement_designer?.measurement_company
                ?.env_group_year && (
                <span className="mr-3">
                  {
                    dataReport?.measurement_designer?.measurement_company
                      ?.env_group_year
                  }{" "}
                  年度
                </span>
              )}
              {dataReport?.measurement_designer?.measurement_company
                ?.env_group_number &&
                `No. ${dataReport?.measurement_designer?.measurement_company?.env_group_number}`}
            </td>
          </tr>
          <tr>
            <td className="bg-mono-dark-10" colSpan={2}>
              連絡担当作業環境測定士名
            </td>
            <td colSpan={2}>{dataReport?.measurement_designer?.name}</td>
          </tr>
          <tr>
            <td className="bg-mono-dark-10" colSpan={2}>
              登録に係る指定作業場の種類
            </td>
            <td className="font-weight-medium" colSpan={2}>
              <div className={cx(styles.typeBox, "flex-space-evenly")}>
                {[...Array(COUNT_WORKPLACE_TYPE)].map((_, index) => {
                  const val = get(
                    dataReport,
                    `measurement_designer.measurement_company.workplace_type_${
                      index + 1
                    }`
                  );
                  return (
                    <span
                      className={cx({
                        [styles.round]: val,
                      })}
                    >
                      第{index + 1}号
                    </span>
                  );
                })}
              </div>
            </td>
          </tr>
        </table>

        <div className="label-s mt-2 font-weight-bold ml-4">
          {dataReport?.address_for_submission
            ? "測定を委託した事業場等"
            : "測定を実施した事業場等"}
        </div>

        <table className="table-content-lef">
          <tr>
            <td className="bg-mono-dark-10" colSpan={1}>
              名称
            </td>
            <td colSpan={3}>{dataReport?.office.name}</td>
          </tr>
          <tr style={{ height: 35, verticalAlign: "bottom" }}>
            <td className="bg-mono-dark-10" width={150} colSpan={1}>
              所在地 <br /> (TEL,FAX)
            </td>
            <td colSpan={3}>
              <div>{dataReport?.office.address}</div>
              <span>TEL: {dataReport?.office?.telephone_number}</span>
              <span className="ml-5">
                ,FAX: {dataReport?.office?.fax_number}
              </span>
            </td>
          </tr>
        </table>

        <div className="text-center py-3">記</div>
        <ul style={{ listStyleType: "decimal", paddingLeft: 15, fontSize: 12 }}>
          <li className="heading-s">
            測定を実施した単位作業場所の名称：
            {dataReport?.workplace?.name}
          </li>
          <li className="heading-s">測定項目： 騒音</li>
          <li className="heading-s">
            測定年月日 :
            {dataReport?.measurement_evaluation?.measurement_date &&
              dayjs(
                dataReport?.measurement_evaluation?.measurement_date
              )?.format(
                ` ${convertJapaneseCalendar(
                  dataReport?.measurement_evaluation?.measurement_date,
                  true
                )} MM 月 DD 日`
              )}
          </li>
          <li className="heading-s">
            <div>
              測定結果 &nbsp;&nbsp;&nbsp;
              {evaluations?.evaluation_total
                ? `第${CLASSIFY[evaluations?.evaluation_total]}管理区分`
                : "-"}
            </div>
            <div style={{ marginLeft: -16 }}>
              <table>
                <colgroup>
                  <col span={1} className="bg-mono-dark-10" />
                </colgroup>
                <tr>
                  <td width={160}>A測定結果[算術平均値]</td>
                  <td>
                    <div className="width-fit-content flex-space-between mx-Auto">
                      <span className="width35">
                        {evaluations?.measure_A_value ?? "-"}
                      </span>

                      <span>dB（A）</span>
                    </div>
                  </td>
                  <td width={80}>
                    {dataReport?.measurement_evaluation?.evaluation_A
                      ? CLASSIFY[
                          Number(
                            dataReport?.measurement_evaluation?.evaluation_A
                          )
                        ]
                      : "- "}
                  </td>
                </tr>
                <tr>
                  <td width={160}>B測定値</td>
                  <td>
                    <div className="width-fit-content flex-space-between mx-Auto">
                      <span className="width35">
                        {evaluations?.measure_B_value ?? "-"}
                      </span>
                      <span>dB（B）</span>
                    </div>
                  </td>
                  <td width={80}>
                    {dataReport?.measurement_evaluation?.evaluation_B
                      ? CLASSIFY[
                          Number(
                            dataReport?.measurement_evaluation?.evaluation_B
                          )
                        ]
                      : "- "}
                  </td>
                </tr>
                <tr>
                  <td width={160}>個人ばく露測定値</td>
                  <td>
                    <div className="width-fit-content flex-space-between mx-Auto">
                      <span className="width35">
                        {evaluations?.measure_C_value ?? "- "}
                      </span>
                      <span>dB（ばく露）</span>
                    </div>
                  </td>
                  <td width={80}>
                    {dataReport?.measurement_evaluation?.evaluation_C
                      ? CLASSIFY[
                          Number(
                            dataReport?.measurement_evaluation?.evaluation_C
                          )
                        ]
                      : "-"}
                  </td>
                </tr>
              </table>
              <table className="mt-2">
                <colgroup>
                  <col span={1} className="bg-mono-dark-10" />
                </colgroup>
                <tr style={{ height: 60 }}>
                  <td width={160}>
                    <div>
                      管理区分 <br /> （作業環境管理の状態）
                    </div>
                  </td>
                  <td>
                    <div
                      style={{ justifyContent: "space-around" }}
                      className="flex-align-center font-weight-medium"
                    >
                      <>
                        <div
                          className={
                            Number(evaluations?.evaluation_total) ===
                            EVALUATION_TOTAL_TYPE.TYPE_1
                              ? "border-solid"
                              : ""
                          }
                        >
                          第Ⅰ管理区分 <br /> (適 切)
                        </div>

                        <div
                          className={
                            Number(evaluations?.evaluation_total) ===
                            EVALUATION_TOTAL_TYPE.TYPE_2
                              ? "border-solid"
                              : ""
                          }
                        >
                          第Ⅱ管理区分 <br /> (なお改善の余地)
                        </div>

                        <div
                          className={
                            Number(evaluations?.evaluation_total) ===
                            EVALUATION_TOTAL_TYPE.TYPE_3
                              ? "border-solid"
                              : ""
                          }
                        >
                          第Ⅲ管理区分 <br /> (適切でない)
                        </div>
                      </>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </li>
          <li>
            <div className="mt-2 heading-s">
              当該単位作業場所における管理区分等の推移（過去４回）
            </div>
            <div style={{ marginLeft: -16 }}>
              <table>
                {[
                  { title: "測定年月日", dataKey: "measurement_date" },
                  { title: "A測定結果", dataKey: "measure_A_value" },
                  { title: "B測定結果", dataKey: "measure_B_value" },
                  { title: "個人ばく露測定結果", dataKey: "measure_C_value" },
                  { title: "管理区分", dataKey: "evaluation_total" },
                ].map((item, index) => (
                  <tr>
                    <td className="text-center bg-mono-dark-10" width={120}>
                      {item.title}
                    </td>
                    {new Array(4).fill(1).map((_, val) => {
                      const evaluationTotal = get(
                        evaluations,
                        `history_evaluations.${val}.${item.dataKey}`
                      );
                      return (
                        <td className="text-center" width={90}>
                          {index === 0 &&
                            `${
                              (evaluationTotal &&
                                convertJapaneseCalendar(evaluationTotal)) ??
                              "-"
                            }`}
                          {index !== 0 && index !== 4 && (
                            <>
                              <div className="width-fit-content flex-space-between mx-Auto">
                                <span className="width30">
                                  {evaluationTotal ?? "-"}
                                </span>
                                <span>dB</span>
                              </div>
                            </>
                          )}

                          {(index === 4 &&
                            evaluationTotal &&
                            `第${CLASSIFY[evaluationTotal]}管理区分`) ??
                            "-"}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </table>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default memo(PreviewReportsPage1);
