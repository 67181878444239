import { memo, useCallback, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { isEqual, omit } from "lodash";
import { compile } from "path-to-regexp";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as LinkIcon } from "@app/assets/images/icons/Link.svg";
import BreadCrumb from "@app/components/atoms/BreadCrumb/BreadCrumb";
import Button from "@app/components/atoms/Button/Button";
import Form, { useForm } from "@app/components/atoms/Form/Form";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import {
  MessageError,
  MessageSuccess,
} from "@app/constants/messages.constants";
import { getPresignedUrl, uploadImageToS3 } from "@app/features/master/master";
import {
  STEP_VALUE,
  createReport,
  updateIsChangeForm,
  ReportsPathsEnum,
  getReportDetail,
  updateReport,
  REPORTS_TYPE,
  getPathUrl,
  REPORTS_MODE,
  updateIsEditCanvas,
  updateStepReport,
  postEvaluations,
} from "@app/features/reports/reports";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { RootState } from "@app/redux/root-reducer";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import BasicInformations from "../../components/BasicInformations/BasicInformations";
import BusinessOfficeInformations from "../../components/BusinessOfficeInformations/BusinessOfficeInformations";
import Comment from "../../components/Comment/Comment";
import LinkingData from "../../components/LinkingData/LinkingData";
import MeasuringPersonInformations from "../../components/MeasuringPersonInformations/MeasuringPersonInformations";
import StepReports from "../../components/StepReports/StepReports";
import WorkplacesInfomations from "../../components/WorkplacesInfomations/WorkplacesInfomations";
import { listPath } from "../../constants/breadcrumb";
import styles from "./ReportDetailScreen.module.scss";

const ReportDetailScreen = () => {
  const { TabPane } = Tabs;
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);

  const { step } = getQueryParams(window.location.search);

  const dispatch = useAppDispatch();
  const { mode, id } = useParams<{ mode: string; id: string }>();

  const history = useHistory();

  const { reportDetail, urlImageCanvasUpload, isEditCanvas } = useAppSelector(
    (state: RootState) => state.reports
  );

  const handleUploadImage = async (callback: () => void) => {
    if (!urlImageCanvasUpload) return callback();

    const bgUrl = form.getFieldValue([
      "workplace",
      "measurement_design",
      "background_sketch_filename",
    ]);
    dispatch(getPresignedUrl())
      .then(unwrapResult)
      .then(res => {
        Promise.all([
          dispatch(
            uploadImageToS3({
              url: `${res.pre_signed_canvas}`,
              imageUpload: urlImageCanvasUpload.imageCanvas,
            })
          ),
          !bgUrl &&
            dispatch(
              uploadImageToS3({
                url: res.pre_signed_background,
                imageUpload: urlImageCanvasUpload.imageBackground,
              })
            ),
        ]).then(result => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (result.some((item: any) => !!item.error)) {
            openNotification({
              type: "warning",
              message: MessageError.ERRMSG_007,
            });
            setIsSubmitting(false);
          } else {
            form.setFieldsValue({
              workplace: {
                measurement_design: {
                  rough_sketch_filename: res.pre_signed_canvas,
                  background_sketch_filename:
                    bgUrl ?? res.pre_signed_background,
                },
              },
            });
            callback();
          }
        });
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_004,
        });
      });
    return 0;
  };

  const listStep = [
    {
      name: "基礎情報入力",
      component: <BasicInformations form={form} />,
    },
    {
      name: "測定者情報入力",
      component: <MeasuringPersonInformations form={form} />,
    },
    {
      name: "事業所情報入力",
      component: <BusinessOfficeInformations form={form} />,
    },
    {
      name: "作業場情報入力",
      component: (
        <WorkplacesInfomations
          form={form}
          handleUploadImage={callback => handleUploadImage(callback)}
        />
      ),
    },
    {
      name: "測定データの紐づけ/入力",
      component: <LinkingData form={form} />,
    },
    {
      name: "所見コメント登録",
      component: <Comment form={form} />,
    },
  ];

  const onGetReportDetail = useCallback(() => {
    if (mode === REPORTS_MODE.EDIT) {
      setIsLoading(true);
      dispatch(getReportDetail(id)).finally(() => setIsLoading(false));
    }
  }, [dispatch, id, mode]);

  const {
    offices: { listOffices },
    workplaces: { listWorkplaces },
  } = useAppSelector(state => state);

  const resetData = () => {
    const valueOffice = form.getFieldValue("office");
    const valueWorkplace = form.getFieldValue("workplace");
    const officesSelected = [...listOffices]?.find(
      item => item.id === valueOffice.id
    );
    const workplacesSelected = [...listWorkplaces]?.find(
      item => item.id === valueWorkplace.id
    );
    form.setFieldsValue({
      office: {
        id: isEqual(
          omit(valueOffice, "updated_at"),
          omit(officesSelected, "updated_at")
        )
          ? officesSelected?.id
          : null,
      },
      workplace: {
        id:
          isEqual(
            omit(workplacesSelected, "measurement_design", "office_id"),
            omit(valueWorkplace, [
              "measurement_design",
              "measurement_item",
              "listPlot",
              "bgUrl",
              "office_id",
            ])
          ) && !isEditCanvas
            ? workplacesSelected?.id
            : null,
      },
    });
  };

  const handleSubmit = async (type: number) => {
    setIsSubmitting(true);

    const onSubmit = async () => {
      const evaluations = await dispatch(
        postEvaluations({
          data: {
            design_plot_locations: form.getFieldValue([
              "workplace",
              "measurement_design",
              "design_plot_locations",
            ]),
            workplace_id: form.getFieldValue(["workplace", "id"]),
          },
        })
      );
      form.setFieldsValue({ measurement_evaluation: evaluations.payload });
      const data = form.getFieldsValue();
      data.measurers = data.measurers
        ? data.measurers.filter((item: { id: number }) => item.id)
        : [];
      if (data?.workplace?.measurement_design) {
        data.workplace.measurement_design = {
          ...data.workplace.measurement_design,
          workplace_id: data.workplace.id,
          rough_sketch_filename: getPathUrl(
            data.workplace.measurement_design.rough_sketch_filename
          ),
          background_sketch_filename: getPathUrl(
            data.workplace.measurement_design.background_sketch_filename
          ),
        };
      }

      if (mode === REPORTS_MODE.EDIT) {
        dispatch(
          updateReport({
            data,
            id,
            params: {
              type,
            },
          })
        )
          .then(unwrapResult)
          .then(() => {
            if (type === REPORTS_TYPE.SAVE) {
              openNotification({
                type: "success",
                message: MessageSuccess.MSG_004,
              });
            } else {
              openNotification({
                type: "success",
                message: MessageSuccess.MSG_003,
              });
            }
            onGetReportDetail();
          })
          .catch(() => {
            openNotification({
              type: "warning",
              message: MessageError.ERRMSG_007,
            });
          })
          .finally(() => setIsSubmitting(false));
      } else {
        dispatch(
          createReport({
            data,
            params: { type },
          })
        )
          .then(unwrapResult)
          .then(res => {
            history.push({
              pathname: compile(ReportsPathsEnum.REPORTS_DETAILS)({
                mode: "edit",
                id: res.id,
              }),
              search: window.location.search,
            });
            openNotification({
              type: "success",
              message: MessageSuccess.MSG_004,
            });
          })
          .catch(() => {
            openNotification({
              type: "warning",
              message: MessageError.ERRMSG_007,
            });
            form.setFieldsValue({
              workplace: {
                measurement_design: {
                  background_sketch_filename: null,
                },
              },
            });
          })
          .finally(() => setIsSubmitting(false));
      }
    };

    if (
      (mode === REPORTS_MODE.CREATE ||
        (mode === REPORTS_MODE.EDIT && isEditCanvas)) &&
      urlImageCanvasUpload
    ) {
      handleUploadImage(() => onSubmit());
    } else {
      onSubmit();
    }
    dispatch(updateIsEditCanvas(false));
  };

  useEffect(() => {
    onGetReportDetail();
  }, [onGetReportDetail]);

  useEffect(() => {
    if (reportDetail && mode === REPORTS_MODE.EDIT) {
      form.setFieldsValue(reportDetail);
    }
  }, [form, mode, reportDetail]);

  useEffect(() => {
    dispatch(updateStepReport(Number(step)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => {
    setIsValid(form.getFieldsError().some(({ errors }) => errors.length));
  };

  if (isLoading && mode === REPORTS_MODE.EDIT) return <LoadingSpinner />;

  return (
    <div className="p-6">
      <Form
        form={form}
        scrollToFirstError
        onFieldsChange={() => {
          handleChange();
          dispatch(updateIsChangeForm(form.isFieldsTouched()));
        }}
      >
        <div className="flex-space-between mb-4">
          <BreadCrumb listPath={listPath} />
          <div className="flex-align-center gap-16">
            {mode === REPORTS_MODE.EDIT && (
              <div className="flex-align-center gap-8 text-dark-60-alpha font-12 ">
                <div>
                  最終更新：
                  {dayjs(reportDetail?.updated_at)
                    .tz()
                    .format("YYYY/MM/DD HH:mm")}
                </div>
                <div>{reportDetail?.updated_by}</div>
              </div>
            )}
            <Button
              onClick={() => {
                resetData();
                handleSubmit(REPORTS_TYPE.DRAFT);
              }}
              disabled={isSubmitting}
            >
              一時保存
            </Button>
            <Button
              disabled={isSubmitting || isValid}
              onClick={() => {
                resetData();
                handleSubmit(REPORTS_TYPE.SAVE);
              }}
              type="primary"
            >
              作成を完了
            </Button>
          </div>
        </div>
        <Tabs
          tabPosition="left"
          defaultActiveKey={STEP_VALUE[step as string]}
          className={cx(styles.tabsReport)}
          onChange={activeKey => {
            const path = Object.keys(STEP_VALUE).find(
              (item: string) => STEP_VALUE[item] === activeKey
            );
            dispatch(updateStepReport(Number(path)));
            window.history.pushState(
              "",
              "",
              `${compile(ReportsPathsEnum.REPORTS_DETAILS)({
                mode,
                id,
              })}?step=${path}`
            );
          }}
        >
          {listStep.map(item => (
            <TabPane
              forceRender
              tab={<StepReports title={item.name} />}
              key={item.name}
            >
              {item.component}
            </TabPane>
          ))}
        </Tabs>

        <Button
          onClick={() => {
            history.push(
              compile(ReportsPathsEnum.PREVIEW_REPORTS)({
                id: mode === REPORTS_MODE.EDIT ? id : "new-report",
              })
            );
            localStorage.setItem(
              "dataReport",
              JSON.stringify({ ...reportDetail, ...form.getFieldsValue() })
            );
          }}
          type="primary"
          className={cx(styles.buttonLink)}
        >
          <div className="flex-center">
            <div className="font-12 font-weight-regular">
              報告書のプレビュー
            </div>
            <LinkIcon className="ml-2" />
          </div>
        </Button>
      </Form>
    </div>
  );
};

export default memo(ReportDetailScreen);
