import cx from "classnames";

import styles from "./Tag.module.scss";

interface TagProps {
  children?: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  isDisable?: boolean;
  className?: string;
  fontSize?: string;
}

const Tag = ({
  children,
  color,
  backgroundColor,
  isDisable,
  className,
  fontSize,
}: TagProps) => {
  return (
    <div
      className={cx(styles.customTag, className, {
        [styles.isDisable]: isDisable,
      })}
      style={{
        color,
        backgroundColor,
        fontSize,
      }}
    >
      {children}
    </div>
  );
};

export default Tag;
