import dayjs from "dayjs";

import { FilterPeriod } from "@app/features/filters/filters";

import { TIME_FORMAT } from "../measurement-results";

export const getScalevalueTgraph = (timeDiff: number) => {
  let scale = {
    key: "",
    value: 0,
  };
  // > 5 minutes
  if (timeDiff >= 5 * 60 + 30) {
    scale = {
      key: "second",
      value: 180,
    };
    // 1p30s -> 5p
  } else if (timeDiff >= 60 + 30 && timeDiff < 5 * 60 + 30) {
    scale = {
      key: "minute",
      value: 1,
    };
  } else {
    scale = {
      key: "second",
      value: 30,
    };
  }
  return scale;
};

export const getTimeFrame = (filter_periods?: FilterPeriod[]) => {
  if (!filter_periods) {
    return {
      start_time: null,
      end_time: null,
    };
  }
  return {
    start_time: dayjs
      .min(
        filter_periods.map(item =>
          dayjs(dayjs(item.start_time).format(TIME_FORMAT))
        )
      )
      ?.format("HH:mm"),
    end_time: dayjs
      .max(
        filter_periods.map(item =>
          dayjs(dayjs(item.end_time).format(TIME_FORMAT))
        )
      )
      ?.format("HH:mm"),
  };
};

export const getTimeDuration = ({
  startTime,
  endTime,
}: {
  startTime?: string;
  endTime?: string;
}) => {
  if (!startTime || !endTime) return "-";
  const t = dayjs.duration(dayjs(endTime).diff(startTime));
  return dayjs.utc(t.asMilliseconds()).format("HH:mm:ss");
};
