import { useState } from "react";

import { Breadcrumb } from "antd";
import { useHistory } from "react-router-dom";

import { ReactComponent as ArrowRight } from "@app/assets/images/icons/ArrowRight.svg";
import ModalConfirmRedirect from "@app/components/molecules/Navigation/components/ModalConfirmRedirect/ModalConfirmRedirect";
import { useAppSelector } from "@app/redux/store";

interface BreadCrumbProps {
  listPath: {
    name: string;
    path?: string;
  }[];
}

const BreadCrumb = ({ listPath }: BreadCrumbProps) => {
  const history = useHistory();
  const { isChangeForm } = useAppSelector(state => state.reports);
  const [isOpenModalLeave, setIsOpenModalLeave] = useState(false);
  const [pathRedirect, setPathRedirect] = useState("");

  const handleRedirect = (path: string) => {
    setPathRedirect(path);
    if (isChangeForm) {
      setIsOpenModalLeave(true);
    } else {
      history.push(path);
    }
  };

  return (
    <>
      <Breadcrumb separator={<ArrowRight />}>
        {listPath.map(item => (
          <Breadcrumb.Item onClick={() => handleRedirect(item.path ?? "")}>
            {item.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <ModalConfirmRedirect
        isOpenModalLeave={isOpenModalLeave}
        onCloseDialog={() => setIsOpenModalLeave(false)}
        pathRedirect={pathRedirect}
      />
    </>
  );
};

export default BreadCrumb;
