import { memo } from "react";

import cx from "classnames";
import dayjs from "dayjs";

import ModalCustom from "@app/components/atoms/ModalCustom/ModalCustom";

import { getTimeDuration } from "../../helpers/measurement-results.helpers";
import {
  MeasurementResultsDetail,
  DATE_TIME_FORMAT,
  TIME_FORMAT_HOUR,
} from "../../measurement-results";
import styles from "./ModalDisplayDataValue.module.scss";

interface ModalDisplayDataValueProps {
  isOpenModalDisplayDataValue: boolean;
  measurementResultsDetail: MeasurementResultsDetail | null;
}

const ModalDisplayDataValue = ({
  isOpenModalDisplayDataValue,
  measurementResultsDetail,
}: ModalDisplayDataValueProps) => {
  return (
    <>
      <ModalCustom
        className={cx(styles.diaLog)}
        isOpen={isOpenModalDisplayDataValue}
      >
        <div className="measurement_data">
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40 mr-2">測定開始日時</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.start_time
                ? dayjs(
                    measurementResultsDetail?.measurement_result?.start_time
                  )?.format(DATE_TIME_FORMAT)
                : "-"}
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">測定時間</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result
                ?.measurement_interval ?? "-"}
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">LAeq(全体)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.laeq ?? "-"} dB
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">DOSE(全体)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.dose ?? "-"} %
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">ばく露許容時間</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result
                ?.time_allowed_for_exposure
                ? dayjs(
                    measurementResultsDetail?.measurement_result
                      ?.time_allowed_for_exposure
                  ).format(TIME_FORMAT_HOUR)
                : "-"}
            </p>
          </div>

          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">測定開始日時(除外後)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.filter
                ?.valid_start_time
                ? dayjs(
                    measurementResultsDetail?.measurement_result?.filter
                      ?.valid_start_time
                  ).format(DATE_TIME_FORMAT)
                : "-"}
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">測定時間(除外後)</p>
            <p className="font-12 text-mono-dark-60">
              {getTimeDuration({
                startTime:
                  measurementResultsDetail?.measurement_result?.filter
                    ?.valid_start_time,
                endTime:
                  measurementResultsDetail?.measurement_result?.filter
                    ?.valid_end_time,
              })}
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">LAeq(除外後)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.edited_laeq ?? "-"}{" "}
              dB
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">DOSE(除外後)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result?.edited_dose ?? "-"}{" "}
              %
            </p>
          </div>
          <div className="measurement_data_item">
            <p className="font-12 text-mono-dark-40">ばく露許容時間(除外後)</p>
            <p className="font-12 text-mono-dark-60">
              {measurementResultsDetail?.measurement_result
                ?.edited_time_allowed_for_exposure
                ? dayjs(
                    measurementResultsDetail?.measurement_result
                      ?.edited_time_allowed_for_exposure
                  )?.format(TIME_FORMAT_HOUR)
                : "-"}
            </p>
          </div>
        </div>
      </ModalCustom>
    </>
  );
};

export default memo(ModalDisplayDataValue);
