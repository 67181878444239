import { memo, useEffect, useState } from "react";

import { DatePicker, FormInstance, TimePicker } from "antd";
import { useWatch } from "antd/lib/form/Form";
import cx from "classnames";
import dayjs from "dayjs";
import moment from "moment";

import styles from "./InputLocationData.module.scss";

type LocationProps = {
  form: FormInstance;
};

type InputLocationsDataProps = {
  key: "Date" | "Hour" | "Minute" | "Second";
  textAfter?: string;
  format?: "YYYY-MM-DD" | "HH" | "mm" | "ss";
};

type TimeRangeProps = {
  type: "start" | "end";
  data: InputLocationsDataProps[];
};

const InputLocationData = ({ form }: LocationProps) => {
  const LOCATIONS = ["A", "B"];
  const TIME_RANGE: TimeRangeProps[] = [
    {
      type: "start",
      data: [
        { key: "Date", format: "YYYY-MM-DD" },
        { key: "Hour", format: "HH", textAfter: "時" },
        { key: "Minute", format: "mm", textAfter: "分" },
        { key: "Second", format: "ss", textAfter: "秒〜" },
      ],
    },
    {
      type: "end",
      data: [
        { key: "Date", format: "YYYY-MM-DD" },
        { key: "Hour", format: "HH", textAfter: "時" },
        { key: "Minute", format: "mm", textAfter: "分" },
        { key: "Second", format: "ss", textAfter: "秒" },
      ],
    },
  ];
  const [timeSelected, setTimeSelected] = useState<Record<string, string>>({
    startADate: "",
    startAHour: "",
    startAMinute: "",
    startASecond: "",
    startBDate: "",
    startBHour: "",
    startBMinute: "",
    startBSecond: "",
    endADate: "",
    endAHour: "",
    endAMinute: "",
    endASecond: "",
    endBDate: "",
    endBHour: "",
    endBMinute: "",
    endBSecond: "",
  });

  const startTimeA = `${timeSelected.startADate} ${
    timeSelected.startAHour || "00"
  }:${timeSelected.startAMinute || "00"}:${timeSelected.startASecond || "00"}`;

  const startTimeB = `${timeSelected.startBDate} ${
    timeSelected.startBHour || "00"
  }:${timeSelected.startBMinute || "00"}:${timeSelected.startBSecond || "00"}`;

  const endTimeA = `${timeSelected.endADate} ${timeSelected.endAHour || "00"}:${
    timeSelected.endAMinute || "00"
  }:${timeSelected.endASecond || "00"}`;

  const endTimeB = `${timeSelected.endBDate} ${timeSelected.endBHour || "00"}:${
    timeSelected.endBMinute || "00"
  }:${timeSelected.endBSecond || "00"}`;

  const timeSimplyLocations = useWatch("time");

  useEffect(() => {
    const timeSelectedClone = { ...timeSelected };
    LOCATIONS.forEach(location => {
      TIME_RANGE.forEach(range => {
        range.data.forEach(item => {
          const name = `${range.type}${location}${item.key}`;
          const time = form.getFieldValue([
            "time",
            `${range.type}Time${location}`,
          ]);
          if (time) {
            timeSelectedClone[name] = dayjs(time).format(item.format);
          }
        });
      });
    });
    setTimeSelected(timeSelectedClone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSimplyLocations]);

  useEffect(() => {
    form.setFieldsValue({
      time: {
        startTimeA: timeSelected.startADate === "" ? null : startTimeA,
        startTimeB: timeSelected.startBDate === "" ? null : startTimeB,
        endTimeA: timeSelected.endADate === "" ? null : endTimeA,
        endTimeB: timeSelected.endBDate === "" ? null : endTimeB,
      },
    });
  }, [endTimeA, endTimeB, form, startTimeA, startTimeB, timeSelected]);

  const onSelectTime = (
    location: string,
    type: "start" | "end",
    timeType: string,
    timeData: moment.Moment
  ) => {
    const data = moment(timeData);
    let res = "";
    switch (timeType) {
      case "Hour":
        res = `${data.hour()}`;
        break;
      case "Minute":
        res = `${data.minute()}`;
        break;
      case "Second":
        res = `${data.second()}`;
        break;
      default:
        break;
    }
    setTimeSelected({
      ...timeSelected,
      [`${type}${location}${timeType}`]: res,
    });
  };

  return (
    <>
      {LOCATIONS.map((location: string) => (
        <>
          <p className="font-12 text-mono-gray-80 font-weight-regular mt-5 mb-2">{`${location}測定`}</p>
          <div className={cx(styles.inputItem)}>
            {TIME_RANGE.map((range: TimeRangeProps) => {
              const timeValue = form.getFieldValue([
                "time",
                `${range.type}Time${location}`,
              ]);
              return range.data.map((data: InputLocationsDataProps) => (
                <>
                  {data.key === "Date" ? (
                    <DatePicker
                      onChange={(_, timeString) => {
                        if (!timeSelected[`end${location}Date`]) {
                          setTimeSelected({
                            ...timeSelected,
                            [`${range.type}${location}Date`]: timeString,
                            [`end${location}Date`]: timeString,
                          });
                        } else {
                          setTimeSelected({
                            ...timeSelected,
                            [`${range.type}${location}Date`]: timeString,
                          });
                        }
                      }}
                      allowClear={false}
                      placeholder="日付を選択"
                      value={timeValue ? moment(timeValue) : undefined}
                    />
                  ) : (
                    <TimePicker
                      format={data.format}
                      placeholder="00"
                      showNow={false}
                      onSelect={timeString => {
                        onSelectTime(
                          location,
                          range.type,
                          data.key,
                          timeString
                        );
                      }}
                      allowClear={false}
                      defaultValue={
                        timeValue ? moment(timeValue, data.format) : undefined
                      }
                      value={
                        timeSelected[`${range.type}${location}${data.key}`] ===
                        ""
                          ? null
                          : moment(
                              moment()
                                .toDate()
                                [`set${data.key}s`](
                                  +timeSelected[
                                    `${range.type}${location}${data.key}`
                                  ]
                                )
                            )
                      }
                      className={cx(styles.hmcPicker)}
                    />
                  )}
                  {data.textAfter && <span>{data.textAfter}</span>}
                </>
              ));
            })}
          </div>
          <div className="width-fit-content bg-mono-gray-10 font-12 text-mono-dark-60 mt-2 py-2 px-4">
            秒を入力しない場合、00秒として登録します。
          </div>
        </>
      ))}
    </>
  );
};

export default memo(InputLocationData);
