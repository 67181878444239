// key to prevent source code leaking
// x1iPIdsY07wmZcNmrpIr912NQYhERxaf

import { lazy, Suspense } from "react";

import { useMount } from "ahooks";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";

import "@app/helpers/dayjs/dayjs.helper";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import { getUserLogin } from "./features/auth/auth";
import { useAppDispatch, useAppSelector } from "./redux/store";

// Routes are lazy loaded so they will access to correct permissions
const Routes = lazy(() => import("./routes/Routes"));

const App = () => {
  const dispatch = useAppDispatch();
  const { loadingUser } = useAppSelector(state => ({
    loadingUser: state.auth.loading,
  }));

  useMount(() => {
    dispatch(getUserLogin());
  });

  const loading = <LoadingSpinner />;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <Suspense fallback={loading}>
        <Router>{loadingUser ? loading : <Routes />}</Router>
      </Suspense>
    </>
  );
};

export default App;
