import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoginRequestDef, AUTH_FEATURE_KEY } from "@app/features/auth/auth";

import {
  getUserLoginApi,
  authLogin,
  confirmAccountApi,
  createAccountApi,
  getListAccountsApi,
  refreshTokenApi,
  registerAccountApi,
  resendVerificationCodeApi,
  updateAccountsApi,
} from "../api/auth.api";
import {
  saveTokens,
  clearTokens,
  authErrorHelper,
} from "../helpers/auth.helpers";
import {
  CreateAccountDataDef,
  DataUpdateAccounts,
  InitialStateDef,
  UserDetails,
} from "../types/auth.types";

const initialState: InitialStateDef = {
  user: null,
  isAuthenticated: false,
  error: false,
  loading: false,
  listAccounts: [],
};

export const login = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/login`,
  async (values: LoginRequestDef, { rejectWithValue }) => {
    try {
      const response = await authLogin(values);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserLogin = createAsyncThunk<UserDetails>(
  `${AUTH_FEATURE_KEY}/getUserLogin`,
  async (_, { rejectWithValue }) => {
    try {
      const userDetails = await getUserLoginApi();
      return userDetails.data.user;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getListAccounts = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/getListAccounts`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getListAccountsApi();
      return response.data.accounts;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAccounts = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/updateAccounts`,
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: DataUpdateAccounts;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateAccountsApi({
        id,
        data,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerAccount = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/registerAccount`,
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await registerAccountApi({
        email,
        password,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const confirmAccount = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/confirmAccount`,
  async (
    {
      email,
      code,
    }: {
      email: string;
      code: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await confirmAccountApi({
        email,
        code,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/resendVerificationCode`,
  async (
    {
      email,
    }: {
      email: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await resendVerificationCodeApi({
        email,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postRefreshToken = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/postRefreshToken`,
  async (
    {
      userName,
      refreshToken,
    }: {
      userName: string;
      refreshToken: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await refreshTokenApi({
        refreshToken,
        userName,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAccount = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/createAccount`,
  async (data: CreateAccountDataDef, { rejectWithValue }) => {
    try {
      const response = await createAccountApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    clearUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      clearTokens();
    },
  },
  extraReducers: builder => {
    /**
     * LOGIN
     */
    builder.addCase(login.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = action.payload as any;
      const {
        jwtToken,
        payload: { exp, username },
      } = response.accessToken;
      const { token } = response.refreshToken;

      if (jwtToken) {
        saveTokens({
          token: jwtToken,
          refreshToken: token,
          expiresIn: exp,
          userName: username,
        });
      }
    });
    builder.addCase(login.rejected, state => {
      authErrorHelper(state);
      clearTokens();
    });
    /**
     * GET AUTHENTICATED USER
     */
    builder.addCase(getUserLogin.pending, state => {
      state.error = false;
      state.loading = true;
      state.user = null;
    });
    builder.addCase(getUserLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(getUserLogin.rejected, state => {
      state.user = null;
      authErrorHelper(state);
      clearTokens();
    });

    builder.addCase(getListAccounts.pending, state => {
      state.listAccounts = null;
    });
    builder.addCase(getListAccounts.fulfilled, (state, action) => {
      state.listAccounts = action.payload;
    });
    builder.addCase(getListAccounts.rejected, state => {
      state.listAccounts = null;
    });
  },
});

export const { clearUser } = authSlice.actions;

export const authReducer = authSlice.reducer;
