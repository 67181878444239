import { memo, ReactNode, useMemo } from "react";

import { Pagination, Table, Typography } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { get } from "lodash";
import { compile } from "path-to-regexp";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as LogoAddition } from "@app/assets/images/icons/Addition.svg";
import Button from "@app/components/atoms/Button/Button";
import {
  getQueryParams,
  setQueryParams,
} from "@app/helpers/queryParams/queryParams";
import { numberCount } from "@app/helpers/util.helper";
import { useAppSelector } from "@app/redux/store";

import {
  CLASSIFY,
  FIELD_SORT,
  getSortDirection,
  ReportsPathsEnum,
  REPORTS_TABLE_HEAD,
  REPORTS_TYPE,
  SORT_DIRECTION,
  SORT_DIRECTION_TABLE,
} from "../../reports";
import styles from "./ListReports.module.scss";

interface DataType {
  key: React.Key;
  update_at: string;
  office_name: string;
  workplace_name: string;
  measurement_date: string;
  noiseControl?: ReactNode;
  measurement: string | ReactNode;
  status: string;
}
const ListReports = () => {
  const { Column } = Table;
  const { listReports } = useAppSelector(state => state.reports);

  const { page, type, sort, direction } = getQueryParams(
    window.location.search
  );

  const dataListReports = useMemo(() => {
    const data: DataType[] = [];

    listReports?.reports?.forEach(item => {
      const listMeasurement = [
        item.is_messure_A ? "A" : "",
        item.is_messure_B ? "B" : "",
        item.is_messure_C ? "ばく露" : "",
      ].filter(x => !!x);

      data.push({
        key: `${item.id}`,
        update_at: `${`${
          dayjs(item?.update_at?.replace(".000000Z", "")).format(
            "YYYY/MM/DD HH:mm"
          ) ?? ""
        }
          ${item?.update_by ?? ""}`}`,
        office_name: `${item?.office_name ?? ""}`,
        workplace_name: `${item?.workplace_name ?? ""}`,
        measurement_date: `${
          item?.measurement_date
            ? dayjs(item.measurement_date.replace(".000000Z", "")).format(
                "YYYY/MM/DD"
              )
            : ""
        }`,
        noiseControl: item.type && (
          <div className={cx(styles.classify)}>{CLASSIFY[item.type]}</div>
        ),
        measurement: listMeasurement.map((val, index) => {
          return (
            <>{`${val}${index === listMeasurement.length - 1 ? "" : "、"}`}</>
          );
        }),
        status: `${item.type === REPORTS_TYPE.DRAFT ? "一時保存" : "完了"}`,
      });
    });
    return data;
  }, [listReports?.reports]);

  const history = useHistory();

  const { numberCountStart, numberCountEnd } = useMemo(() => {
    if (!listReports)
      return {
        numberCountStart: 0,
        numberCountEnd: 0,
      };
    const { limit, totalCount } = listReports;
    return numberCount(listReports.page, limit, totalCount);
  }, [listReports]);

  const handleChangePage = (pageNumber: number) => {
    if (pageNumber) {
      history.push({
        pathname: ReportsPathsEnum.REPORTS,
        search: setQueryParams({
          page: pageNumber,
          type,
          sort,
          direction,
        }),
      });
    }
  };

  const handleFilterReport = (reportType?: number) => {
    history.push({
      pathname: ReportsPathsEnum.REPORTS,
      search: setQueryParams({
        page: 1,
        type: reportType,
      }),
    });
  };

  return (
    <>
      <div className={cx(styles.listTable)}>
        <div className="px-6 py-6">
          <div className="flex-space-between">
            <div className="flex-align-center">
              <Typography className="mr-6 font-16 font-weight-medium text-mono-dark-80">
                報告書
              </Typography>
              <Button type="primary">
                <Link
                  to={compile(ReportsPathsEnum.REPORTS_DETAILS)({
                    mode: "create",
                  })}
                  className="flex-align-center"
                >
                  <LogoAddition className="mr-2" />
                  新規作成
                </Link>
              </Button>
            </div>
            <div>
              {listReports?.draft_reports && (
                <>
                  {Number(type) !== REPORTS_TYPE.DRAFT ? (
                    <div className="pl-4 font-12 bg-red text-white line-height-22">
                      作業途中の報告書が<b>{listReports.draft_reports}件</b>
                      あります。
                      <Button
                        onClick={() => handleFilterReport(REPORTS_TYPE.DRAFT)}
                        className="text-white"
                        type="text"
                      >
                        <span className="text font-weight-regular font-12 text-white">
                          絞り込む
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <div className="pl-4 font-12 bg-red text-white line-height-22">
                      すべてのレポート。
                      <Button
                        onClick={() => handleFilterReport()}
                        className="text-white"
                        type="text"
                      >
                        <span className="text font-weight-regular font-12 text-white">
                          拡張された
                        </span>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="mt-4 d-flex">
            <div className="font-weight-bold">
              {numberCountStart || ""} - {numberCountEnd || ""} 件
            </div>
            <div>
              （全
              {listReports?.totalCount} 件）
            </div>
          </div>

          <>
            <Table
              dataSource={dataListReports}
              className="mt-4 ant-table-cell center"
              locale={{
                triggerDesc: "クリックして降順に並べ替えます",
                triggerAsc: "クリックして昇順で並べ替えます",
                cancelSort: "クリックすると並べ替えがキャンセルされます",
              }}
              onRow={row => {
                return {
                  onClick: () => {
                    localStorage.removeItem("dataReport");
                    history.push(
                      compile(ReportsPathsEnum.PREVIEW_REPORTS)({
                        id: row.key,
                      })
                    );
                  },
                };
              }}
            >
              {REPORTS_TABLE_HEAD.map(item => (
                <Column
                  dataIndex={item.dataIndex}
                  width={item.width ?? "200px"}
                  align="center"
                  title={item.text}
                  onHeaderCell={row => {
                    return {
                      onClick: () => {
                        const fieldSort =
                          FIELD_SORT[get(row, "dataIndex") as string];
                        if (!fieldSort || item.dataIndex === "status") return;
                        history.push({
                          pathname: ReportsPathsEnum.REPORTS,
                          search: setQueryParams({
                            page,
                            type,
                            sort:
                              direction === SORT_DIRECTION.DESC &&
                              sort === fieldSort
                                ? undefined
                                : fieldSort,
                            direction: getSortDirection(
                              fieldSort === sort
                                ? (direction as string)
                                : undefined
                            ),
                          }),
                        });
                      },
                    };
                  }}
                  sortOrder={
                    FIELD_SORT[item.dataIndex] === sort
                      ? SORT_DIRECTION_TABLE[direction as string]
                      : undefined
                  }
                  render={value => {
                    if (typeof value === "string") {
                      return (
                        <div className="no-wrap-hidden" title={value}>
                          {value}
                        </div>
                      );
                    }
                    return value;
                  }}
                  sorter={!["measurement", "status"].includes(item.dataIndex)}
                />
              ))}
            </Table>
            <div className="mt-2">
              <Pagination
                showLessItems
                current={page ? Number(page) : 1}
                total={listReports?.totalCount}
                onChange={pages => {
                  handleChangePage(pages);
                }}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default memo(ListReports);
