import { memo, useEffect, useState } from "react";

import cx from "classnames";
import dayjs from "dayjs";

import { MeasurementResult } from "@app/features/measurers/measurers";
import {
  ICON_TYPE_A,
  ICON_TYPE_B,
  ICON_TYPE_C,
  MAX_POINT_CANVAS,
  ReportsDataDef,
} from "@app/features/reports/reports";
import { convertMinuteToHour } from "@app/helpers/util.helper";

import { MIN_LEQ } from "../../../constants/reports.constants";
import styles from "./PreviewReportsPage4.module.scss";

type TableType = {
  no: number;
  leq: number | undefined | null;
};

const PreviewReportsPage4 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  const [tableDataSoonkei, setTableDataSoonkei] = useState<
    Record<string, TableType[]>
  >({
    A: [],
    B: [],
  });
  const LOCATIONS = [
    {
      type: "A",
      rowCount: 10,
    },
    {
      type: "B",
      rowCount: 2,
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableDataBakuro, setTableDataBakuro] = useState<MeasurementResult[]>(
    []
  );

  useEffect(() => {
    const tableDataSoonkeiA = [];
    const tableDataSoonkeiB = [];
    const newTableDataBakuro = [];

    const listPlotLocations =
      dataReport?.workplace?.measurement_design?.design_plot_locations;

    for (let i = 1; i <= MAX_POINT_CANVAS.A; i += 1) {
      const plotLocation = listPlotLocations?.find(
        item => item.icon_type === ICON_TYPE_A && item.order === i
      );
      const data = {
        no: i,
        leq: plotLocation?.measurement_result?.[0]?.laeq,
      };
      tableDataSoonkeiA.push(data);
    }

    for (let i = 1; i <= MAX_POINT_CANVAS.B; i += 1) {
      const plotLocation = listPlotLocations?.find(
        item => item.icon_type === ICON_TYPE_B && item.order === i
      );
      const data = {
        no: i,
        leq: plotLocation?.measurement_result?.[0]?.laeq,
      };
      tableDataSoonkeiB.push(data);
    }

    for (let i = 1; i <= MAX_POINT_CANVAS.ば; i += 1) {
      const plotLocation = listPlotLocations?.find(
        item => item.icon_type === ICON_TYPE_C && item.order === i
      );
      const data = {
        no: i,
        ...plotLocation?.measurement_result?.[0],
      };
      newTableDataBakuro.push(data);
    }

    setTableDataBakuro(newTableDataBakuro);
    setTableDataSoonkei({
      A: tableDataSoonkeiA,
      B: tableDataSoonkeiB,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReport]);

  return (
    <div id="page-4" className={cx(styles.root, "page-a4 mx-Auto mb-5 px-6")}>
      <div className="heading-m pt-3 mb-2 header-reports-number flex-space-between width-fit-content ml-Auto font-12">
        <span>記録表番号</span>
        <span>{dataReport?.record_sheet_number}</span>
      </div>
      <div className="heading-m">６. 測定データーの記録</div>
      <div className={cx(styles.historyTable, "mt-2 d-flex")}>
        <table style={{ width: 260 }}>
          <tr style={{ height: 38 }}>
            <td className="bg-mono-dark-10" />
          </tr>
          <tr style={{ height: 199 }}>
            <td
              className="bg-mono-dark-10"
              style={{ writingMode: "vertical-rl", textOrientation: "upright" }}
            >
              A 測 定
            </td>
          </tr>
          <tr>
            <td className="bg-mono-dark-10">B測定</td>
          </tr>
        </table>
        {[1, 2, 3].map((_, index) => (
          <table>
            <tr className="bg-mono-dark-10" style={{ height: 30 }}>
              <td width={60}>測定点No.</td>
              <td>
                等価騒音レベル <br /> <i>L</i>
                <sub>eq </sub>(dB)
              </td>
            </tr>
            {LOCATIONS.map(location => (
              <>
                {[...tableDataSoonkei[location.type]]
                  .splice(index * location.rowCount, location.rowCount)
                  .map(data => (
                    <tr>
                      <td className="text-align-right">{data.no}</td>
                      <td>
                        {!!data.leq && data.leq < MIN_LEQ && "☆"}
                        {data.leq === 0 && "*"}
                        {data.leq ?? "-"}
                      </td>
                    </tr>
                  ))}
              </>
            ))}
          </table>
        ))}
      </div>
      <table className="mt-6">
        <tr className="bg-mono-dark-10">
          <td width={50} />
          <td width={60}>測定点No.</td>
          <td width={70}>開始時間</td>
          <td width={70}>終了時間</td>
          <td width={70}>測定時間</td>
          <td width={60}>
            <i>L</i> <sub>Aeq</sub> (dB)
          </td>
          <td width={50}>DOSE</td>
          <td width={90}>ばく露許容時間</td>
        </tr>
        <tr>
          <td
            className="bg-mono-dark-10"
            style={{ writingMode: "vertical-rl", textOrientation: "upright" }}
            rowSpan={11}
          >
            個 人 ば く 露 測 定
          </td>
        </tr>
        {tableDataBakuro?.map(item => (
          <tr>
            <td className="text-align-right">{item.no}</td>
            <td>
              {item?.start_time
                ? dayjs(item.start_time)?.format("HH時mm分")
                : "-"}
            </td>
            <td>
              {item?.end_time ? dayjs(item.end_time)?.format("HH時mm分") : "-"}
            </td>
            <td>
              {item?.measurement_interval
                ? dayjs(`2022/01/01 ${item?.measurement_interval}`)?.format(
                    "HH時間mm分"
                  )
                : "-"}
            </td>
            <td>{Number.isNaN(Number(item.laeq)) ? "-" : `${item.laeq} dB`}</td>
            <td>{Number.isNaN(Number(item.dose)) ? "-" : `${item.dose} %`}</td>
            <td>
              {item.time_allowed_for_exposure
                ? convertMinuteToHour(item.time_allowed_for_exposure)
                : "-"}
            </td>
          </tr>
        ))}
      </table>
      <div className="heading-m mt-4">
        注）☆印は８０dB未満であることを示す。
      </div>
    </div>
  );
};

export default memo(PreviewReportsPage4);
