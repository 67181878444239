import axios, { AxiosError, AxiosRequestConfig } from "axios";
import dayjs from "dayjs";

import { ENV } from "@app/constants/env";
import {
  AuthEndpointsEnum,
  clearTokens,
  clearUser,
  getTokens,
  postRefreshToken,
  saveTokens,
} from "@app/features/auth/auth";
import store from "@app/redux/store";

/**
 * All the endpoint that do not require an access token
 */
const anonymousEndpoints = [AuthEndpointsEnum.LOGIN.toString()];

/**
 * "Wrapper" around getTokens
 * can be changed to have refresh functionality if api supports it
 */
export const getRefreshedToken = () => {
  const { accessToken, expiresAt, refreshToken, userName } = getTokens();
  const isTokenExpired = expiresAt ? dayjs().isAfter(expiresAt) : true;
  return { accessToken, isTokenExpired, refreshToken, userName };
};

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const isAnonymous = anonymousEndpoints.some(endpoint =>
    request.url?.startsWith(endpoint)
  );
  const { accessToken, isTokenExpired, refreshToken, userName } =
    getRefreshedToken();

  if (isTokenExpired && refreshToken && userName) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = (
      await store.dispatch(
        postRefreshToken({
          userName,
          refreshToken,
        })
      )
    ).payload;

    if (!data) {
      store.dispatch(clearUser());
      return request;
    }

    const {
      accessToken: {
        jwtToken,
        payload: { exp, username },
      },
      refreshToken: { token },
    } = data;

    if (jwtToken) {
      saveTokens({
        token: jwtToken,
        refreshToken: token,
        expiresIn: exp,
        userName: username,
      });
      request.headers.AuthorizationApi = `${jwtToken}`;
    }
    return request;
  }

  if (accessToken) {
    request.headers.AuthorizationApi = `${accessToken}`;
    return request;
  }

  if (!accessToken && !isAnonymous) {
    store.dispatch(clearUser());
    return Promise.reject(request);
  }

  return request;
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */

const errorInterceptor = async (axiosError: AxiosError) => {
  if (axiosError?.response) {
    const statusCode = axiosError.response.status;
    switch (statusCode) {
      case 401:
        clearTokens();
        break;
      default:
        break;
    }
  }
  return Promise.reject(axiosError);
};

/**
 * Axios success response interceptor
 * @param {AxiosResponse} response
 */

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(undefined, errorInterceptor);
