import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import measuringDeviceApi from "../api/measuring-device-types.api";
import { MeasuringDevice, MEASURING_DEVICE_KEY } from "../measuring-device";

interface initialStateType {
  listMeasuringDeviceType: MeasuringDevice[];
}

const initialState: initialStateType = {
  listMeasuringDeviceType: [],
};

// API-MDT-010
export const getMeasuringDeviceType = createAsyncThunk(
  `${MEASURING_DEVICE_KEY}/getMeasuringDeviceType`,
  async (params: { type: number }, { rejectWithValue }) => {
    try {
      const response = await measuringDeviceApi.getMeasuringDeviceTypeApi(
        params
      );
      return response.data.measuring_device_types;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const measuringDeviceSlice = createSlice({
  name: MEASURING_DEVICE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMeasuringDeviceType.pending, state => {
      state.listMeasuringDeviceType = [];
    });
    builder.addCase(getMeasuringDeviceType.fulfilled, (state, actions) => {
      state.listMeasuringDeviceType = actions.payload;
    });
    builder.addCase(getMeasuringDeviceType.rejected, state => {
      state.listMeasuringDeviceType = [];
    });
  },
});

export const measuringDeviceReducer = measuringDeviceSlice.reducer;
