import { memo } from "react";

import { Modal, ModalFuncProps } from "antd";
import cx from "classnames";

import styles from "./ModalConfirm.module.scss";

type ModalConfirmProps = {
  isOpen: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  okText?: string;
  cancelText?: string;
  title: string;
  children?: React.ReactNode;
  withCloseIcon?: boolean;
  withFooterBorder?: boolean;
  isDisableSubmit?: boolean;
  className?: string;
  isHiddenFooter?: boolean;
  isHiddenButtonSubmit?: boolean;
} & ModalFuncProps;

const ModalConfirm = ({
  isOpen,
  cancelText = "キャンセル",
  okText = "新規登録",
  onSubmit,
  onCancel,
  onClose,
  withCloseIcon = true,
  title,
  children,
  withFooterBorder = false,
  isDisableSubmit,
  className,
  isHiddenFooter,
  isHiddenButtonSubmit,
  ...rest
}: ModalConfirmProps) => {
  return (
    <Modal
      okText={okText}
      onOk={onSubmit}
      okButtonProps={{
        disabled: isDisableSubmit,
        hidden: isHiddenButtonSubmit,
      }}
      onCancel={onCancel}
      visible={isOpen}
      cancelText={cancelText}
      maskClosable={false}
      title={title}
      closable={false}
      className={cx(styles.modal, className, {
        [styles.withBoder]: withFooterBorder,
        [styles.isHiddenFooter]: isHiddenFooter,
      })}
      {...rest}
    >
      <div>
        <div
          aria-hidden="true"
          className={cx(styles.closeIcon, {
            [styles.showIcon]: withCloseIcon,
          })}
          onClick={onClose}
        >
          ×
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default memo(ModalConfirm);
