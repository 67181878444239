/* eslint-disable filenames/match-regex */
export enum HintText {
  "HINT_001" = "報告書を出力される形式で確認できます。",
  "HINT_002" = "任意で作業環境測定結果報告書（証明書）に記載される番号を付与できます。",
  "HINT_003" = "任意で作業環境測定結果記録表に記載される番号を付与できます。",
  "HINT_004" = "必要に応じて委託元等の宛名を記載できます。チェックを入れない場合、報告書には宛名欄が表示されません。",
  "HINT_005" = "測定デザイン者あるいは測定責任者を選択してください。",
  "HINT_006" = "測定を実施した担当者を選択してください。測定デザイン担当者が測定を実施した場合も選択が必須です。",
  "HINT_007" = "測定の対象となった事業所を選択してください。リストに無い場合は新規登録ができます。",
  "HINT_012" = "測定の対象となった作業場を選択してください。リストに無い場合は新規登録ができます。",
  "HINT_013" = "任意で作業場に番号を付与できます。",
  "HINT_014" = "「騒音障害防止のためのガイドライン」記載の別表１または別表２から該当する業務を選択してください。主なものを3つまで選択できます。それ以上の内容は騒音作業の概要に入力してください。",
  "HINT_015" = "作業場で行われる騒音作業を具体的に入力してください。",
  "HINT_016" = "騒音の発生状況について選択してください。複数選択ができます。",
  "HINT_018" = "単位作業場を定めた理由について各項目を入力してください。",
  "HINT_022" = "騒音計の設置位置やばく露計装着者の位置あるいは移動範囲を入力してください。配置したアイコンの数から測定点数が自動で入力されます。",
  "HINT_024" = "A測定を行い、かつ騒音計の設置距離が6mを超える場合のみ、この項目を入力してください。",
  "HINT_025" = "A測定を行い、かつ騒音計の測定点数が5未満の場合のみ、この項目を入力してください。",
  "HINT_026" = "個人ばく露測定を行った場合のみ、この項目を入力してください。",
  "HINT_027" = "個人ばく露測定を行った場合のみ、この項目を入力してください。",
  "HINT_028" = "報告書を作成すると自動的に結果が表示されます。過去の測定結果は作業場の詳細画面で入力することができます。",
  "HINT_029" = "複数の測定データで共通する条件がある場合に使用します。詳細な除外設定はグラフ画面で行います。",
  "HINT_030" = "配置済みの測定箇所アイコンは右クリックで削除できます。",
}
