import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { OfficesEndpointsEnum } from "../constants/offices.endpoints";
import { Office } from "../offices";

// API-OFC-020
const getOfficesApi = (): Promise<AxiosResponse> => {
  const url = OfficesEndpointsEnum.GET_OFFICES;
  return api.get(url);
};

// API-OFC-010
const postOfficesApi = (data: Office): Promise<AxiosResponse> => {
  const url = OfficesEndpointsEnum.POST_OFFICES;
  return api.post(url, data);
};

// API-OFC-040
const putOfficesApi = ({
  officeId,
  data,
}: {
  officeId: string;
  data: Office;
}) => {
  const url = OfficesEndpointsEnum.PUT_OFFICES.replace(":id", officeId);
  return api.put(url, data);
};

const getOfficesDeatilsApi = (id: string): Promise<AxiosResponse> => {
  const url = OfficesEndpointsEnum.GET_OFFICES_DETAILS.replace(":id", id);
  return api.get(url);
};

const officesApi = {
  getOfficesApi,
  postOfficesApi,
  putOfficesApi,
  getOfficesDeatilsApi,
};

export default officesApi;
