import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { ExclusionTemplateSettingsEndpointsEnum } from "../constants/filters.endpoints";
import { ExclusionTemplate } from "../filters";

// API-ETS-020
const getExclusionTemplateSettingsApi = (): Promise<AxiosResponse> => {
  const url = ExclusionTemplateSettingsEndpointsEnum.GET_FILTERS;
  return api.get(url);
};

// API-ETS-010
const postExclusionTemplateSettingsApi = (
  data: ExclusionTemplate
): Promise<AxiosResponse> => {
  const url = ExclusionTemplateSettingsEndpointsEnum.POST_FILTERS;
  return api.post(url, data);
};

// API-ETS-040
const updateExclusionTemplateSettingsApi = ({
  data,
  id,
}: {
  data: ExclusionTemplate;
  id: number;
}): Promise<AxiosResponse> => {
  const url = ExclusionTemplateSettingsEndpointsEnum.UPDATE_FILTERS.replace(
    ":id",
    String(id)
  );
  return api.put(url, data);
};

const exclusionTemplateSettingsApi = {
  getExclusionTemplateSettingsApi,
  postExclusionTemplateSettingsApi,
  updateExclusionTemplateSettingsApi,
};

export default exclusionTemplateSettingsApi;
