import { memo, useState } from "react";

import { Radio, RadioChangeEvent, Select } from "antd";
import { FormInstance } from "antd/es/form";
import cx from "classnames";

import { Item } from "@app/components/atoms/Form/Form";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import { getTimeFrame } from "../../helpers/measurement-results.helpers";
import styles from "./ExclusionSetByTemplate.module.scss";

interface ExclusionSetByTemplateProps {
  form: FormInstance;
}

const ExclusionSetByTemplate = ({ form }: ExclusionSetByTemplateProps) => {
  const [isOpenSelectBox, setIsOpenSelectBox] = useState(false);
  const [valueSelect, setValueSelect] = useState<string | undefined>(undefined);

  const { listExclusionTemplateSettings } = useAppSelector(
    (state: RootState) => state.filters
  );

  const handleChangeExclusionTemplateSettings = (e: RadioChangeEvent) => {
    const exclusionTemplate = listExclusionTemplateSettings?.find(
      item => item.id === e.target.value
    );
    setValueSelect(exclusionTemplate?.name);
    form.setFieldsValue(exclusionTemplate);
    setIsOpenSelectBox(false);
  };

  return (
    <>
      <div className="heading-s mb-1">除外設定テンプレート</div>
      <Item valuePropName="name" name="id">
        <Select
          dropdownMatchSelectWidth={512}
          style={{ width: 400 }}
          open={isOpenSelectBox}
          value={valueSelect}
          onFocus={() => setIsOpenSelectBox(true)}
          allowClear
          onClear={() => {
            setValueSelect(undefined);
            form.resetFields();
          }}
          onDropdownVisibleChange={() => setIsOpenSelectBox(false)}
          placeholder="テンプレートを選択してください"
          notFoundContent={<></>}
          dropdownRender={() => (
            <div className="p-4">
              <div className={cx(styles.contentHeader)}>
                <div>テンプレート名</div>
                <div>時間帯</div>
              </div>
              <Radio.Group
                onChange={handleChangeExclusionTemplateSettings}
                className={cx(styles.radioGroup)}
              >
                {listExclusionTemplateSettings?.map(item => {
                  const { start_time, end_time } = getTimeFrame(
                    item?.filter_periods
                  );
                  return (
                    <Radio
                      className={cx(styles.radioBox)}
                      style={{ width: "100%" }}
                      value={item.id}
                    >
                      <div className="d-flex">
                        <div className={cx(styles.radioTextBox)}>
                          {item.name}
                        </div>
                        <div className={cx(styles.radioTime)}>
                          {start_time && end_time
                            ? `${
                                getTimeFrame(item?.filter_periods).start_time ??
                                "-"
                              } 
                          〜 
                          ${getTimeFrame(item?.filter_periods).end_time ?? "-"}`
                            : "-"}
                        </div>
                      </div>
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          )}
        />
      </Item>
    </>
  );
};

export default memo(ExclusionSetByTemplate);
