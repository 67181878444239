import { memo, useMemo } from "react";

import dayjs from "dayjs";

import { DATE_TIME_FORMAT } from "@app/features/measurement_results/measurement-results";
import {
  ReportsDataDef,
  CLASSIFY,
  SOUND_LEVEL_METER_PROPERTY_TYPE,
  getEvaluationType,
  TYPE_POINT,
} from "@app/features/reports/reports";
import { convertJapaneseCalendar } from "@app/helpers/file.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

const PreviewReportsPage5 = ({
  dataReport,
}: {
  dataReport: ReportsDataDef | null;
}) => {
  const { evaluations } = useAppSelector((state: RootState) => state.reports);

  const { sound_level_meters_name, exposure_meters_model } = useMemo(() => {
    const design_plot_locations =
      dataReport?.workplace?.measurement_design?.design_plot_locations;
    if (!design_plot_locations)
      return {
        sound_level_meters_name: null,
        exposure_meters_model: null,
      };
    const getMeter = (type: number) => {
      const listMeasure = design_plot_locations
        .filter(
          item =>
            item.type === type &&
            !!item.measurement_result?.length &&
            !!item.measurement_result[0]?.device_model
        )
        .map(item => item.measurement_result[0]?.device_model);

      if (!listMeasure.length) return null;
      return (
        <>
          リオン{" "}
          {listMeasure.map(
            (item, index) =>
              `${item} ${index === listMeasure.length - 1 ? "" : "、"}`
          )}
        </>
      );
    };
    return {
      sound_level_meters_name: getMeter(TYPE_POINT.SOONKEI),
      exposure_meters_model: getMeter(TYPE_POINT.BAKURO),
    };
  }, [dataReport]);

  return (
    <div id="page-5" className="page-a4 mx-Auto mb-5 px-6">
      <div className="heading-m pt-4 mb-2 header-reports-number flex-space-between width-fit-content ml-Auto font-12">
        <span>記録表番号</span>
        <span>{dataReport?.record_sheet_number}</span>
      </div>
      <div className="heading-m mt-2">７. 測定方法</div>
      <table>
        <tr>
          <td rowSpan={4} width={50}>
            騒音計
          </td>
          <td className="bg-mono-dark-10" width={70}>
            積分型普通騒音計
          </td>
          <td width={170}>{sound_level_meters_name ?? "-"}</td>
        </tr>
        <tr>
          <td className="bg-mono-dark-10">動特性</td>
          <td>
            {!!sound_level_meters_name &&
              dataReport?.sound_level_meter_property ===
                SOUND_LEVEL_METER_PROPERTY_TYPE.FAST &&
              "★"}{" "}
            FAST &nbsp; &nbsp;
            {!!sound_level_meters_name &&
              dataReport?.sound_level_meter_property ===
                SOUND_LEVEL_METER_PROPERTY_TYPE.SLOW &&
              "★"}{" "}
            SLOW
          </td>
        </tr>
        <tr>
          <td className="bg-mono-dark-10">ウインドスクリーン</td>
          <td>
            {!!sound_level_meters_name && dataReport?.window_screen_flag && "★"}
            有 &nbsp; &nbsp; 無
          </td>
        </tr>
        <tr>
          <td className="bg-mono-dark-10">積分機能の有無</td>
          <td>
            {!!sound_level_meters_name &&
              dataReport?.integral_function_flag &&
              "★"}
            有 &nbsp; &nbsp; 無
          </td>
        </tr>
        <tr>
          <td rowSpan={2}>レベルレコーダ</td>
          <td className="bg-mono-dark-10">メーカ、型式</td>
          <td>{dataReport?.recorder_level_manufacturer_format ?? "-"}</td>
        </tr>
        <tr>
          <td className="bg-mono-dark-10">動特性</td>
          <td>{dataReport?.recorder_level_dynamic_characteristics ?? "-"}</td>
        </tr>
        <tr>
          <td rowSpan={1}>個人ばく露計</td>
          <td className="bg-mono-dark-10">メーカ、型式</td>
          <td>{exposure_meters_model ?? "-"}</td>
        </tr>
      </table>
      <div className="font-14">
        注）★印のついて測定機器及び測定条件で測定を行った。
      </div>
      <div className="heading-m mt-2">８. 測定結果</div>
      <div className="font-12 font-weight-medium">
        算術平均値および標準偏差の算出にあたっては、８０dB未満のデータは除く。
      </div>
      <table>
        <tr>
          <td rowSpan={2} width={70}>
            A測定
          </td>
          <td width={70} className="bg-mono-dark-10">
            算術平均値
          </td>
          <td width={70}>
            <div className="width-fit-content flex-space-between mx-Auto">
              <span>
                <i>L</i>
                (A) =
              </span>
              <span className="flex-space-between mx-Auto">
                <span className="width35">
                  {evaluations?.measure_A_value ?? "-"}
                </span>
                <span>(dB)</span>
              </span>
            </div>
          </td>
          <td width={70} className="bg-mono-dark-10">
            標準偏差
          </td>
          <td width={70}>
            <div className="width-fit-content flex-space-between mx-Auto">
              <span> σa ＝ </span>
              <span className="flex-space-between mx-Auto">
                <span className="width30">
                  {evaluations?.measure_A_value_deviation ?? "-"}
                </span>
                <span>(dB)</span>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="bg-mono-dark-10">
            平均値の計算に用いたデータの数
          </td>
          <td>
            <div className="width-fit-content flex-space-between mx-Auto">
              <span> m = </span>
              <span className="width30">
                {evaluations?.measure_A_data_count ?? "-"}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td rowSpan={1}>B測定</td>
          <td colSpan={4}>
            <div className="width-fit-content flex-space-between mx-Auto">
              <span>
                <i>L</i> (B) =
              </span>
              <span className="flex-space-between mx-Auto">
                <span className="width35">
                  {evaluations?.measure_B_value ?? "-"}{" "}
                </span>
                <span>(dB)</span>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td rowSpan={1}>個人ばく露測定</td>
          <td colSpan={4}>
            <div className="width-fit-content flex-space-between mx-Auto">
              <span>
                <i>L</i> (ばく露) =
              </span>
              <span className="flex-space-between mx-Auto">
                <span className="width35">
                  {evaluations?.measure_C_value ?? "-"}
                </span>
                <span>(dB)</span>
              </span>
            </div>
          </td>
        </tr>
      </table>
      <div className="heading-m mt-2">９. 評価</div>
      <div className="font-12 font-weight-medium">
        算術平均値および標準偏差の算出にあたっては、８０dB未満のデータは除く。
      </div>
      <table>
        <tr>
          <td className="bg-mono-dark-10 " width={120} colSpan={2}>
            評 価 日 時
          </td>
          <td width={300}>
            {dayjs()
              .tz()
              .format(
                ` ${convertJapaneseCalendar(
                  dayjs().format(DATE_TIME_FORMAT),
                  true
                )} MM 月 DD 日`
              )}
          </td>
        </tr>
        <tr>
          <td className="bg-mono-dark-10" colSpan={2}>
            評 価 箇 所
          </td>
          <td>{dataReport?.workplace?.name}</td>
        </tr>
        <tr>
          <td width={40} rowSpan={4} className="pl-4 pr-4">
            評 価 結 果
          </td>
          <td rowSpan={1} className="bg-mono-dark-10">
            A測定の結果
          </td>
          <td rowSpan={1}>
            {getEvaluationType(
              dataReport?.measurement_evaluation?.evaluation_A,
              "A"
            )}
          </td>
        </tr>
        <tr>
          <td rowSpan={1} className="bg-mono-dark-10">
            B測定の結果
          </td>
          <td colSpan={4}>
            {getEvaluationType(
              dataReport?.measurement_evaluation?.evaluation_B,
              "B"
            )}
          </td>
        </tr>
        <tr>
          <td rowSpan={1} className="bg-mono-dark-10">
            個人ばく露測定の結果
          </td>
          <td colSpan={4}>
            {getEvaluationType(
              dataReport?.measurement_evaluation?.evaluation_C,
              "C"
            )}
          </td>
        </tr>
        <tr>
          <td rowSpan={1} className="bg-mono-dark-10">
            管 理 区 分
          </td>
          <td colSpan={4}>
            {(dataReport?.measurement_evaluation?.evaluation_total &&
              `第${
                CLASSIFY[
                  Number(dataReport?.measurement_evaluation?.evaluation_total)
                ]
              }管理区分`) ??
              "-"}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="bg-mono-dark-10">
            評価を実施した者の氏名
          </td>
          <td>
            {dataReport?.measurement_designer?.name
              ? dataReport?.measurement_designer?.name
              : "-"}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default memo(PreviewReportsPage5);
