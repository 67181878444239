export enum MessageError {
  "ERRMSG_001" = "測定データの測定開始時間が基準の測定データから離れすぎていめためグラフ表示できません。測定データ一覧に戻って個別に測定データを選択してください。",
  "ERRMSG_002" = "エラーが発生しました。",
  "ERRMSG_003" = "事業所を上書きできません。新規登録してください。",
  "ERRMSG_004" = "事業所上書きでエラーが発生しました。",
  "ERRMSG_005" = "事業所新規登録でエラーが発生しました。",
  "ERRMSG_007" = "報告書の作成に失敗しました。",
  "ERRMSG_008" = "測定データが紐づいている測定アイコンは削除できません",
  "ERRMSG_009" = "作業場上書きでエラーが発生しました。",
  "ERRMSG_010" = "作業場新規登録でエラーが発生しました。",
}

export enum MessageSuccess {
  "MSG_001" = "事業所を登録しました",
  "MSG_002" = "事業所を上書きしました",
  "MSG_003" = "報告書を一時保存しました。",
  "MSG_004" = "報告書を作成しました。",
  "MSG_006" = "測定データをアップロードしました。",
  "MSG_005" = "除外設定反映成功",
  "MSG_007" = "作業場を上書きしました。",
  "MSG_008" = "作業場を登録しました。",
}

export enum ValidationMessage {
  "VALIDATION_MSG_001" = "半角英数ハイフンで入力してください",
  "VALIDATION_MSG_002" = "半角英数で入力してください",
  "VALIDATION_MSG_003" = "少数第一位までの数値を入力してください",
  "VALIDATION_MSG_004" = "半角英数記号で入力してください",
  "VALIDATION_MSG_005" = "A測定データの開始時間また終了時間を入力してください",
  "VALIDATION_MSG_006" = "B測定データの開始時間また終了時間を入力してください",
  "VALIDATION_MSG_007" = "必須入力項目を入力してください",
}
