import { memo, useEffect, useMemo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Button } from "antd";
import cx from "classnames";
import dayjs from "dayjs";
import { get } from "lodash";

import Rectangle from "@app/assets/images/Rectangle.png";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MessageError } from "@app/constants/messages.constants";
import { getExclusionTemplateSettings } from "@app/features/filters/filters";
import { useAppDispatch } from "@app/redux/store";

import { getQueryParams } from "../../../../helpers/queryParams/queryParams";
import ModalDisplaySettings from "../../components/ModalDisplaySettings/ModalDisplaySettings";
import TgraphMeasurers from "../../components/TgraphMeasurers/TgraphMeasurers";
import {
  DATE_TIME_FORMAT,
  getMeasurementResultsDetail,
  MeasurementResultsDetail,
  TGRAPH_DATA_DEFAULT,
  TIME_FORMAT,
  TIME_FORMAT_NEXT_DAY,
} from "../../measurement-results";
import styles from "./TgraphMeasurersScreen.module.scss";

export interface dataModalDisplaySettingsType {
  yAxisRangeMin?: number;
  yAxisRangeMax?: number;
  timeStart?: string;
  timeEnd?: string;
  XAxisScale: 1 | 2 | 3;
  isScale: boolean;
}

const TgraphMeasurersScreen = () => {
  const { listId } = getQueryParams(window.location.search);

  const [isOpenModalDisplaySettings, setIsOpenModalDisplaySettings] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [dataModalDisplaySettings, setDataModalDisplaySettings] =
    useState<dataModalDisplaySettingsType>({
      yAxisRangeMin: TGRAPH_DATA_DEFAULT.Y_AXIS_RANGE_MIN,
      yAxisRangeMax: TGRAPH_DATA_DEFAULT.Y_AXIS_RANGE_MAX,
      XAxisScale: TGRAPH_DATA_DEFAULT.X_AXIS_SCALE,
      isScale: true,
    });

  const dispatch = useAppDispatch();

  const [listMeasurementResultsDetail, setListMeasurementResultsDetail] =
    useState<MeasurementResultsDetail[]>([]);

  useEffect(() => {
    Promise.all(
      listId?.map(item => dispatch(getMeasurementResultsDetail(item)))
    )
      .then(data => {
        const convertData: MeasurementResultsDetail[] = data.map(
          item => item.payload
        );
        setListMeasurementResultsDetail(convertData);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getExclusionTemplateSettings())
      .then(unwrapResult)
      .catch(() => {
        openNotification({ type: "warning", message: MessageError.ERRMSG_002 });
      });
  }, [dispatch]);

  const { listMeasurementResultsDetailSort, timeSelectedLimit, timeAxis } =
    useMemo(() => {
      const standardTime = listMeasurementResultsDetail?.find(item =>
        dayjs(item.measurement_result?.end_time).isAfter(
          item.measurement_result?.start_time,
          "day"
        )
      );
      const listMeasurementSort = (field: string) => {
        if (listMeasurementResultsDetail?.length < 2) {
          return listMeasurementResultsDetail;
        }
        return listMeasurementResultsDetail
          ?.map(item => {
            return {
              ...item,
              start_time: dayjs(item.measurement_result?.start_time).format(
                dayjs(standardTime?.measurement_result?.start_time).diff(
                  item.measurement_result?.start_time,
                  "hour"
                ) < 23
                  ? TIME_FORMAT
                  : TIME_FORMAT_NEXT_DAY
              ),
              end_time: dayjs(item.measurement_result?.end_time).format(
                dayjs(standardTime?.measurement_result?.end_time).diff(
                  item.measurement_result?.end_time,
                  "hour"
                ) < 23
                  ? TIME_FORMAT
                  : TIME_FORMAT_NEXT_DAY
              ),
            };
          })
          ?.sort((x, y) =>
            dayjs(dayjs(get(x, `${field}`))?.format(TIME_FORMAT)).isAfter(
              dayjs(get(y, `${field}`))?.format(TIME_FORMAT)
            )
              ? 1
              : -1
          );
      };

      return {
        listMeasurementResultsDetailSort: listMeasurementSort("start_time"),
        timeAxis: {
          startTimeLimit: dayjs(
            listMeasurementSort("start_time")?.[0]?.measurement_result
              ?.start_time
          ).format(DATE_TIME_FORMAT),
          endTimeLimit: dayjs(
            listMeasurementSort("end_time")?.[
              listMeasurementResultsDetail?.length - 1
            ]?.measurement_result?.end_time
          ).format(DATE_TIME_FORMAT),
        },
        timeSelectedLimit: {
          startTimeLimit: dayjs
            .min(
              listMeasurementResultsDetail?.map(item =>
                dayjs(
                  dayjs(item.measurement_result?.start_time).format(TIME_FORMAT)
                )
              )
            )
            ?.format(DATE_TIME_FORMAT),
          endTimeLimit: dayjs
            .max(
              listMeasurementResultsDetail?.map(item =>
                dayjs(
                  dayjs(item.measurement_result?.end_time).format(
                    standardTime ? TIME_FORMAT_NEXT_DAY : TIME_FORMAT
                  )
                )
              )
            )
            ?.format(DATE_TIME_FORMAT),
        },
      };
    }, [listMeasurementResultsDetail]);

  const is1HourDifference = useMemo(() => {
    let check = false;
    if (listMeasurementResultsDetailSort?.length < 2) return false;
    const rootTime =
      listMeasurementResultsDetailSort?.[0]?.measurement_result?.start_time;
    listMeasurementResultsDetailSort.forEach((item, index) => {
      if (
        index !== 0 &&
        dayjs(
          dayjs(item?.measurement_result?.start_time)?.format(TIME_FORMAT)
        ).diff(dayjs(rootTime).format(TIME_FORMAT), "hour") >
          Number(process.env.REACT_APP_TIME_DIFFERENT)
      ) {
        check = true;
      }
    });
    return check;
  }, [listMeasurementResultsDetailSort]);

  return (
    <div style={{ padding: "50px" }}>
      {isLoading && <LoadingSpinner />}
      <div className={cx(styles.root)}>
        <div className="font-14 font-weight-medium">{listId?.length} 件</div>
        <div className="t-graph">
          <div className="t-graph-data body-s">
            <span>
              <hr className="line line-laeq" />
              LAeq
            </span>
            <span>
              <hr className="line line-lcpeak" />
              LCpeak
            </span>
            <span>
              <hr className="line line-lceq" />
              LCeq
            </span>
            <span>
              <div className="box-impact" />
              IMPACT
            </span>
            <span>
              <div className="box-over" />
              OVER
            </span>
            <span>
              <div className="box-under" />
              UNDER
            </span>
            <span>
              <Image src={Rectangle} className="box-img" />
              除外設定
            </span>
          </div>
          <Button
            className={cx(styles.btnShowDialog)}
            onClick={() => setIsOpenModalDisplaySettings(true)}
          >
            グラフ表示設定
          </Button>
        </div>
      </div>

      {listMeasurementResultsDetailSort?.map(measurementResultsDetail => (
        <TgraphMeasurers
          key={measurementResultsDetail?.measurement_result?.id}
          dataModalDisplaySettings={dataModalDisplaySettings}
          measurementResultsDetail={measurementResultsDetail}
          timeAxis={timeAxis}
          is1HourDifference={is1HourDifference}
        />
      ))}

      <ModalDisplaySettings
        isOpenModalDisplaySettings={isOpenModalDisplaySettings}
        timeSelectedLimit={timeSelectedLimit}
        onCloseModalDisplaySettings={() => setIsOpenModalDisplaySettings(false)}
        setDataModalDisplaySettings={(data: dataModalDisplaySettingsType) =>
          setDataModalDisplaySettings(data)
        }
      />
    </div>
  );
};

export default memo(TgraphMeasurersScreen);
