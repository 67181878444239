import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { useWatch } from "antd/lib/form/Form";
import { omit } from "lodash";

import Button from "@app/components/atoms/Button/Button";
import HintTooltip from "@app/components/atoms/HintTooltip/HintTooltip";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { HintText } from "@app/constants/hintText.constants";
import {
  MessageError,
  MessageSuccess,
} from "@app/constants/messages.constants";
import {
  getOffices,
  Office,
  postOffices,
  putOffices,
} from "@app/features/offices/offices";
import { STEP_REPORTS } from "@app/features/reports/reports";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector, useAppDispatch } from "@app/redux/store";

const BusinessOfficeInfomations = ({ form }: { form: FormInstance }) => {
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    offices: { listOffices },
    reports: { stepReport },
  } = useAppSelector((state: RootState) => state);
  useEffect(() => {
    if (stepReport !== STEP_REPORTS.OFFICES) return;
    dispatch(getOffices())
      .then(unwrapResult)
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_002,
        });
      });
  }, [dispatch, stepReport]);

  const handleReplaceOffices = () => {
    setIsSubmitting(true);
    const data = form.getFieldValue("office");

    dispatch(
      putOffices({
        officeId: data.id,
        data,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getOffices());
        openNotification({
          type: "success",
          message: MessageSuccess.MSG_002,
        });
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_004,
        });
      })
      .finally(() => setIsSubmitting(false));
  };
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const officeId = useWatch(["office", "id"]);

  const handleCreateOffices = () => {
    setIsSubmitting(true);
    const data: Office = form.getFieldValue("office");

    const dataOmitId: Office = omit(data, "id");

    dispatch(postOffices(dataOmitId))
      .then(unwrapResult)
      .then(res => {
        dispatch(getOffices()).then(() => {
          form.setFieldsValue({ office: { id: res.id } });
        });
        openNotification({
          type: "success",
          message: MessageSuccess.MSG_001,
        });
      })
      .catch(() => {
        openNotification({
          type: "warning",
          message: MessageError.ERRMSG_005,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="bg-white border-radius-1">
      <div className="pr-4 flex-space-between border-bottom-solid-gray200">
        <Typography className="p-6 font-weight-medium text-mono-dark-80">
          事業所情報入力
        </Typography>
        <div className="flex-align-center gap-16">
          <Typography className="font-12 font-weight-regular text-mono-dark-80 ">
            入力した事業所情報を：
          </Typography>
          <Button
            onClick={handleReplaceOffices}
            disabled={!officeId || isSubmitting}
          >
            上書き
          </Button>
          <Button
            onClick={handleCreateOffices}
            disabled={!isChecked || isSubmitting}
          >
            新規登録
          </Button>
        </div>
      </div>

      <Form.List name="office">
        {() => {
          return (
            <div className="py-8">
              <Row gutter={16}>
                <Col flex="160px" offset={0}>
                  <HintTooltip
                    content="事業所名"
                    hintText={HintText.HINT_007}
                  />
                </Col>
                <Col flex="400px">
                  <Form.Item initialValue={null} name="id">
                    <Select
                      disabled={isChecked}
                      onClear={() => {
                        form.resetFields(["office"]);
                        setIsChecked(false);
                      }}
                      onChange={value => {
                        const selectedOffice = [...listOffices].find(
                          item => item.id === value
                        );
                        form.setFieldsValue({
                          office: {
                            ...selectedOffice,
                          },
                          workplace: {
                            office_id: value,
                          },
                        });
                      }}
                      allowClear
                      placeholder="テンプレートから選択"
                    >
                      {listOffices?.map(item => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-8" gutter={16}>
                <Col flex="160px" offset={0} />
                <Col flex="400px">
                  <Checkbox
                    onChange={e => {
                      setIsChecked(e.target.checked);
                    }}
                    className="ml-1"
                  >
                    <Typography className="font-weight-regular text-mono-dark-80">
                      新しい事業所名を入力する
                    </Typography>
                  </Checkbox>
                </Col>
              </Row>

              <Row className="mt-2" gutter={16}>
                <Col flex="160px" offset={0} />
                <Col flex="400px">
                  <Form.Item initialValue={null} name="name">
                    <Input disabled={!officeId && !isChecked} maxLength={30} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-8" gutter={16}>
                <Col flex="160px" offset={0}>
                  <Typography className="text-end font-12 text-dark-60-alpha font-weight-bold">
                    企業名
                  </Typography>
                </Col>
                <Col flex="400px">
                  <Form.Item initialValue={null} name="enterprise_name">
                    <Input disabled={!officeId && !isChecked} maxLength={30} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-8" gutter={16}>
                <Col flex="160px" offset={0}>
                  <Typography className="text-end font-12 text-dark-60-alpha font-weight-bold">
                    事業所所在地
                  </Typography>
                </Col>
                <Col flex="400px">
                  <Form.Item initialValue={null} name="address">
                    <Input disabled={!officeId && !isChecked} maxLength={50} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-8" gutter={16}>
                <Col flex="160px" offset={0}>
                  <Typography className="text-end font-12 text-dark-60-alpha font-weight-bold">
                    電話番号
                  </Typography>
                </Col>
                <Col flex="240px">
                  <Form.Item initialValue={null} name="telephone_number">
                    <Input disabled={!officeId && !isChecked} maxLength={18} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-2" gutter={16}>
                <Col flex="160px" offset={0} />
                <Col flex="223px">
                  <div className="p-2 pl-4 font-12 bg-mono-gray-10 text-mono-dark-60">
                    ハイフンなしで入力してください。
                  </div>
                </Col>
              </Row>

              <Row className="mt-8" gutter={16}>
                <Col flex="160px" offset={0}>
                  <Typography className="text-end font-12 text-dark-60-alpha font-weight-bold">
                    FAX
                  </Typography>
                </Col>
                <Col flex="240px">
                  <Form.Item initialValue={null} name="fax_number">
                    <Input disabled={!officeId && !isChecked} maxLength={18} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-2" gutter={16}>
                <Col flex="160px" offset={0} />
                <Col flex="223px">
                  <div className="p-2 pl-4 font-12 bg-mono-gray-10 text-mono-dark-60">
                    ハイフンなしで入力してください。
                  </div>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};
export default memo(BusinessOfficeInfomations);
