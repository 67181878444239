import { memo, useEffect, useMemo, useState } from "react";

import { Input, InputNumber, Select, Table } from "antd";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import cx from "classnames";
import { cloneDeep, remove } from "lodash";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import ModalConfirm from "@app/components/atoms/ModalConfirm/ModalConfirm";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { ValidationMessage } from "@app/constants/messages.constants";
import {
  DesignPlotLocation,
  MeasurementResult,
} from "@app/features/measurers/measurers";
import {
  getListPlotLocationsByType,
  ICON_TYPE,
  ICON_TYPE_A,
  ICON_TYPE_B,
  TYPE_POINT,
} from "@app/features/reports/reports";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import InputLocationData from "./InputLocationData/InputLocationData";
import styles from "./ModalInputData.module.scss";

type ModalConfirmProps = {
  isOpen: boolean;
  onCancel: (isCancel?: boolean) => void;
  handleSelectPlotLocation: (id: number, record: MeasurementResult) => void;
  reportForm: FormInstance;
};

interface DateType {
  id: number;
  plotId: number | null;
  laeq?: number;
  iconType?: number;
  isNew?: boolean;
}

const ModalInputData = ({
  isOpen,
  onCancel,
  handleSelectPlotLocation,
  reportForm,
}: ModalConfirmProps) => {
  const [form] = useForm();

  const { simplyMeasurementResults } = useAppSelector(
    (state: RootState) => state.measurers
  );

  const [dataList, setDataList] = useState<DateType[]>([]);

  const [modalKey, setModalKey] = useState(Date.now());

  const listPlotLocations: DesignPlotLocation[] = useWatch([
    "workplace",
    "measurement_design",
    "design_plot_locations",
  ]);

  const handleRemoveRow = (id: number) => {
    setDataList([...dataList].filter(data => data.id !== id));
  };

  const onCheckValid = () => {
    let hasIconA = false;
    let hasIconB = false;
    let errorCount = 0;

    const isNotFill = dataList?.some(
      item => item.laeq === null || !item.plotId
    );

    if (isNotFill) {
      openNotification({
        type: "warning",
        message: ValidationMessage.VALIDATION_MSG_007,
      });
      errorCount += 1;
      return 1;
    }

    dataList?.forEach(item => {
      const iconType = listPlotLocations?.find(
        plot => +plot.id === Number(item.plotId)
      )?.icon_type;
      if (iconType === ICON_TYPE_A) {
        hasIconA = true;
      }
      if (iconType === ICON_TYPE_B) {
        hasIconB = true;
      }
    });
    if (hasIconA) {
      if (
        !form.getFieldValue(["time", "startTimeA"]) ||
        !form.getFieldValue(["time", "endTimeA"])
      ) {
        errorCount += 1;
        openNotification({
          type: "warning",
          message: ValidationMessage.VALIDATION_MSG_005,
        });
      }
    }
    if (hasIconB) {
      if (
        !form.getFieldValue(["time", "startTimeB"]) ||
        !form.getFieldValue(["time", "endTimeB"])
      ) {
        errorCount += 1;
        openNotification({
          type: "warning",
          message: ValidationMessage.VALIDATION_MSG_006,
        });
      }
    }
    return errorCount;
  };

  const handleSubmit = () => {
    if (onCheckValid()) return;
    const newListPlotLocations = cloneDeep(listPlotLocations)?.map(
      (item: DesignPlotLocation) => {
        remove(item.measurement_result, {
          is_simply: true,
        });
        return item;
      }
    );
    reportForm.setFieldsValue({
      workplace: {
        measurement_design: {
          design_plot_locations: newListPlotLocations,
        },
      },
    });
    dataList?.forEach(item => {
      if (item.plotId && item.iconType) {
        const record = {
          is_simply: true,
          isNew: item.isNew,
          id: item.id,
          start_time: form.getFieldValue([
            "time",
            `startTime${ICON_TYPE[item.iconType]}`,
          ]),
          end_time: form.getFieldValue([
            "time",
            `endTime${ICON_TYPE[item.iconType]}`,
          ]),
          laeq: Number(item.laeq),
          device_name: form.getFieldValue("device_name"),
        };
        handleSelectPlotLocation(item.plotId, record as MeasurementResult);
      }
    });
    onCancel();
    openNotification({ type: "success", message: "データを登録しました。" });
  };

  useEffect(() => {
    if (simplyMeasurementResults?.length) {
      const convertSimplyMeasure: DateType[] = simplyMeasurementResults?.map(
        item => {
          const defaultValue = listPlotLocations?.find(plotLocation =>
            plotLocation.measurement_result?.some(
              x => x.measurement_result_id === item.id
            )
          );
          const iconType = listPlotLocations?.find(plot =>
            plot.measurement_result?.some(
              p => p.measurement_result_id === item.id
            )
          )?.icon_type;

          if (iconType === ICON_TYPE_A) {
            form.setFieldsValue({
              time: {
                startTimeA: item.start_time,
                endTimeA: item.end_time,
              },
            });
          }
          if (iconType === ICON_TYPE_B) {
            form.setFieldsValue({
              time: {
                startTimeB: item.start_time,
                endTimeB: item.end_time,
              },
            });
          }

          return {
            id: item.id ?? 0,
            plotId: Number(defaultValue?.id),
            laeq: item.laeq,
            iconType,
            isNew: false,
          };
        }
      );
      setDataList(convertSimplyMeasure);
      form.setFieldsValue({
        device_name: simplyMeasurementResults[0].device_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simplyMeasurementResults]);

  const maxLengthTable = useMemo(() => {
    if (!listPlotLocations) return 0;
    const maxLength = [...listPlotLocations].filter(
      item => item.type === TYPE_POINT.SOONKEI
    ).length;

    if (!dataList.length) {
      const newArrayList: DateType[] = [];
      [...Array(maxLength)].forEach((_, index) => {
        newArrayList.push({
          id: Date.now() + index,
          plotId: null,
          laeq: undefined,
          isNew: true,
        });
      });
      setDataList(newArrayList);
    }
    return maxLength;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = () => {
    reportForm.setFieldsValue({
      workplace: {
        measurement_design: {
          design_plot_locations: listPlotLocations.map(item => ({
            ...item,
            measurement_result: item.measurement_result?.filter(
              measure => !measure.is_simply
            ),
          })),
        },
      },
      recorder_level_dynamic_characteristics: null,
      recorder_level_manufacturer_format: null,
    });
    setTimeout(() => {
      form.resetFields();
      setModalKey(Date.now());
      setDataList([]);
      onCancel(true);
    }, 200);
  };

  return (
    <>
      <ModalConfirm
        key={modalKey}
        isOpen={isOpen}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okText="登録する"
        title="測定データ入力"
        withCloseIcon={false}
        centered
        width={937}
        className={cx(styles.modalConfirm)}
      >
        <Form form={form}>
          <p className="font-12 text-mono-dark-80 m-0" aria-hidden="true">
            騒音計の測定データを入力してください。
          </p>
          <Item initialValue={null} name="time">
            <p className="font-12 text-mono-dark-60 mt-7 mb-1">騒音計型式</p>
          </Item>
          <Item
            name="device_name"
            initialValue=""
            rules={[
              {
                pattern: /^[a-zA-Z0-9-\\ ]+$/,
                message: "",
              },
            ]}
          >
            <Input className={cx(styles.input)} />
          </Item>

          <div className="width-fit-content bg-mono-gray-10 font-12 text-mono-dark-60 mt-4 py-2 px-4">
            レベルレコーダ。
          </div>
          <p className="heading-s text-left text-mono-dark-60 mt-2">
            メーカ、型式
          </p>
          <Item
            name="recorder_level_manufacturer_format"
            initialValue={reportForm.getFieldValue(
              "recorder_level_manufacturer_format"
            )}
          >
            <Input
              onChange={e =>
                reportForm.setFieldsValue({
                  recorder_level_manufacturer_format: e.target.value,
                })
              }
              placeholder="入力してください"
              className={cx(styles.input)}
              maxLength={255}
            />
          </Item>
          <p className="heading-s text-left text-mono-dark-60 mt-6">動特性</p>
          <Item
            name="recorder_level_dynamic_characteristics"
            initialValue={reportForm.getFieldValue(
              "recorder_level_dynamic_characteristics"
            )}
          >
            <Input
              onChange={e =>
                reportForm.setFieldsValue({
                  recorder_level_dynamic_characteristics: e.target.value,
                })
              }
              className={cx(styles.input)}
              placeholder="入力してください"
              maxLength={255}
            />
          </Item>
          <InputLocationData form={form} />
          <p className="font-12 text-mono-gray-80 font-weight-regular mt-5 mb-2">
            データ値
          </p>
          <Table
            dataSource={[...dataList]}
            className={cx(styles.dataList)}
            pagination={false}
            scroll={{ y: 130 }}
          >
            <Table.Column
              title="測定箇所"
              dataIndex="plotId"
              key="plotId"
              width={`${100 / 3}%`}
              align="center"
              render={(_, record: DateType) => (
                <Select
                  onChange={plotLocationId => {
                    const iconType = listPlotLocations?.find(
                      plot => +plot.id === +plotLocationId
                    )?.icon_type;
                    setDataList(oldValue =>
                      oldValue.map(data =>
                        data.id === record.id
                          ? {
                              ...data,
                              iconType,
                              plotId: Number(plotLocationId),
                            }
                          : data
                      )
                    );
                  }}
                  value={record.plotId ? String(record.plotId) : ""}
                >
                  {getListPlotLocationsByType(
                    listPlotLocations,
                    TYPE_POINT.SOONKEI
                  ).map(
                    (location: {
                      id: number;
                      icon_type: number;
                      order: number;
                    }) => (
                      <Select.Option
                        key={location.id}
                        value={String(location.id)}
                      >
                        {ICON_TYPE[location.icon_type]}-{location.order}
                      </Select.Option>
                    )
                  )}
                </Select>
              )}
            />
            <Table.Column
              title="lAeq"
              dataIndex="laeq"
              align="center"
              key="laeq"
              width={`${100 / 3}%`}
              render={(val, record: DateType) => (
                <>
                  <InputNumber
                    min={0}
                    onChange={e => {
                      setDataList(oldValue =>
                        oldValue.map(data =>
                          data.id === record.id ? { ...data, laeq: e } : data
                        )
                      );
                    }}
                    value={val}
                    placeholder="無し"
                  />
                  <span className="ml-1 text-mono-dark-80">dB</span>
                </>
              )}
            />
            <Table.Column
              width={`${100 / 3}%`}
              align="center"
              render={(_, record: DateType) => (
                <span
                  className="icon-trash cursor-pointer font-20 text-primary-main"
                  onClick={() => {
                    handleRemoveRow(record.id);
                  }}
                  aria-hidden
                />
              )}
            />
          </Table>

          <Button
            className="flex-center width-fit-content cursor-pointer mx-Auto my-3"
            onClick={() => {
              setDataList([
                ...dataList,
                {
                  id: Date.now(),
                  plotId: null,
                  laeq: undefined,
                  isNew: true,
                },
              ]);
            }}
            disabled={dataList.length === maxLengthTable}
            type="text"
          >
            <span className="icon-plus-circle font-20 text-primary-secondary" />
            <span className="ml-1 font-12 text-primary-secondary">
              行を追加
            </span>
          </Button>
        </Form>
      </ModalConfirm>
    </>
  );
};

export default memo(ModalInputData);
