import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import filtersApi from "../api/filters.api";
import { ExclusionTemplate, FILTERS_FEATURE_KEY } from "../filters";

interface initialStateType {
  listExclusionTemplateSettings: ExclusionTemplate[] | null;
}

const initialState: initialStateType = {
  listExclusionTemplateSettings: null,
};

// API-ETS-020
export const getExclusionTemplateSettings = createAsyncThunk(
  `${FILTERS_FEATURE_KEY}/getExclusionTemplateSettings`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await filtersApi.getExclusionTemplateSettingsApi();
      return response.data.exclusion_template_settings;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-ETS-010
export const postExclusionTemplateSettings = createAsyncThunk(
  `${FILTERS_FEATURE_KEY}/postExclusionTemplateSettings`,
  async (data: ExclusionTemplate, { rejectWithValue }) => {
    try {
      const response = await filtersApi.postExclusionTemplateSettingsApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// API-ETS-040
export const updateExclusionTemplateSettings = createAsyncThunk(
  `${FILTERS_FEATURE_KEY}/updateExclusionTemplateSettings`,
  async (
    {
      data,
      id,
    }: {
      data: ExclusionTemplate;
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await filtersApi.updateExclusionTemplateSettingsApi({
        data,
        id,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const filtersSlice = createSlice({
  name: FILTERS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getExclusionTemplateSettings.pending, state => {
      state.listExclusionTemplateSettings = null;
    });
    builder.addCase(
      getExclusionTemplateSettings.fulfilled,
      (state, actions) => {
        state.listExclusionTemplateSettings = actions.payload;
      }
    );
    builder.addCase(getExclusionTemplateSettings.rejected, state => {
      state.listExclusionTemplateSettings = null;
    });
  },
});

export const filtersReducer = filtersSlice.reducer;
