import { useState, memo } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import cx from "classnames";

import { MessageError } from "@app/constants/messages.constants";

import { useAppDispatch } from "../../../../redux/store";
import { dataModalDisplaySettingsType } from "../../Screens/TgraphMeasurersScreen/TgraphMeasurersScreen";
import {
  getMeasurementResultsDetail,
  MeasurementResultsDetail,
  TimeSelectedLimit,
} from "../../measurement-results";
import ModalDisplayDataValue from "../ModalDisplayDataValue/ModalDisplayDataValue";
import ModalExclusionSettings from "../ModalExclusionSettings/ModalExclusionSettings";
import TgraphContainer from "../TgraphContainer/TgraphContainer";
import styles from "./TgraphMeasurers.module.scss";

interface TgraphMeasurersProps {
  measurementResultsDetail: MeasurementResultsDetail;
  dataModalDisplaySettings: dataModalDisplaySettingsType;
  timeAxis: TimeSelectedLimit;
  is1HourDifference: boolean;
}

const TgraphMeasurers = ({
  measurementResultsDetail,
  dataModalDisplaySettings,
  timeAxis,
  is1HourDifference,
}: TgraphMeasurersProps) => {
  const dispatch = useAppDispatch();
  const [isOpenModalExclusionSettings, setIsOpenModalExclusionSettings] =
    useState<boolean>(false);

  const [isOpenModalDisplayDataValue, setIsOpenModalDisplayDataValue] =
    useState<boolean>(false);

  const [measurementDetail, setMeasurementDetail] =
    useState<MeasurementResultsDetail | null>(measurementResultsDetail);

  const [triggerDownload, setTriggerDownload] = useState<number>(0);
  const [modalExclusionSettingsKey, setModalExclusionSettingsKey] = useState(
    Date.now()
  );

  const onGetMeasurementDetail = () => {
    setMeasurementDetail(null);
    dispatch(
      getMeasurementResultsDetail(
        String(measurementResultsDetail?.measurement_result?.id)
      )
    )
      .then(unwrapResult)
      .then(data => {
        setMeasurementDetail(data);
        setModalExclusionSettingsKey(Date.now());
      })
      .catch(() => setMeasurementDetail(measurementResultsDetail));
  };
  const handleShowDialogDataValue = () => {
    setIsOpenModalDisplayDataValue(true);
  };
  const handleCloseDialogDataValue = () => {
    setIsOpenModalDisplayDataValue(false);
  };
  return (
    <>
      {measurementResultsDetail && (
        <div className="mb-4">
          <div
            className="p-4 px-5 flex-space-between bg-mono-gray-10 text-mono-dark-80"
            id={cx(styles.tGraph)}
          >
            <div>
              <span className="mr-4">
                型式：
                {measurementResultsDetail?.measurement_result?.device_model}
              </span>
              <span className="mr-4">
                製造番号：
                {measurementResultsDetail?.measurement_result?.device_serial_no}
              </span>
              <span className="mr-4">
                管理名：
                {
                  measurementResultsDetail?.measurement_result
                    ?.device_management_name
                }
              </span>
              <span className="mr-4">
                開始日時：
                {measurementResultsDetail?.measurement_result?.start_time}
              </span>
            </div>
            <div
              className={cx("position-Relative flex-center", {
                [styles.disable]: is1HourDifference,
              })}
            >
              <span className="icon-zoom-out font-20 mr-7 text-mono-dark-40" />
              <span
                onClick={() => setTriggerDownload(triggerDownload + 1)}
                className="icon-download font-20 mr-7 cursor-pointer"
                aria-hidden="true"
              />
              <span
                className="icon-Group-converted font-20 mr-7 cursor-pointer"
                aria-hidden="true"
                onClick={() => setIsOpenModalExclusionSettings(true)}
              />
              {isOpenModalDisplayDataValue ? (
                <span
                  className="icon-close"
                  aria-hidden="true"
                  onClick={handleCloseDialogDataValue}
                >
                  <span className="icon-close_icon" />
                </span>
              ) : (
                <span
                  className="icon-details-more font-20 cursor-pointer"
                  aria-hidden="true"
                  onClick={handleShowDialogDataValue}
                />
              )}

              <ModalDisplayDataValue
                isOpenModalDisplayDataValue={isOpenModalDisplayDataValue}
                measurementResultsDetail={measurementDetail}
              />
              <ModalExclusionSettings
                isOpenModalExclusionSettings={isOpenModalExclusionSettings}
                onCloseModalExclusionSettings={() =>
                  setIsOpenModalExclusionSettings(false)
                }
                key={modalExclusionSettingsKey}
                timeSelectedLimit={{
                  startTimeLimit:
                    measurementDetail?.measurement_result?.start_time,
                  endTimeLimit: measurementDetail?.measurement_result?.end_time,
                }}
                setModalExclusionSettingsKey={() =>
                  setModalExclusionSettingsKey(Date.now())
                }
                filterExclusionSettings={{
                  ...measurementDetail?.measurement_result?.filter,
                  measurement_result_id:
                    measurementDetail?.measurement_result?.id,
                }}
                onGetMeasurementDetail={onGetMeasurementDetail}
              />
            </div>
          </div>

          <div className={cx(styles.tgraphContainer)}>
            {is1HourDifference ? (
              <div className="text-red font-16">{MessageError.ERRMSG_001}</div>
            ) : (
              <TgraphContainer
                measurementResultsDetail={measurementDetail}
                dataModalDisplaySettings={dataModalDisplaySettings}
                dataModalExclusionSettings={
                  measurementDetail?.measurement_result?.filter
                }
                timeAxis={timeAxis}
                triggerDownload={triggerDownload}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(TgraphMeasurers);
